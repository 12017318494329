<template>
  <div class="jpage-card col-12 m-0 py-1 px-0">
    <b-row class="m-1 mt-1 d-flex justify-content-between">
      <div class="row col-lg-6 col-sm-12 justify-content-between">
        <div>
          <h4 v-if="mode === 'new'" class="p-0">
            {{ $t("adminPage.exhibitors.add-exhibitor.new-title") }}
          </h4>
          <h4 v-if="mode === 'edit'" class="p-0">
            {{ $t("adminPage.exhibitors.add-exhibitor.edit-title") }}
          </h4>
        </div>
        <div class="row">
          <div class="m-1 p-0" style="background-color: white; border: none">
            {{ $i18n.locale }}
          </div>
          <b-dropdown size="sm" tag="li" variant="link" no-caret>
            <b-dropdown-toggle tag="a" class="m-0 p-0" navLink waves-fixed
              ><strong>{{ $i18n.locale }}</strong></b-dropdown-toggle
            >
            <template #button-content>
              <span class="material-icons"> language </span>
            </template>
            <b-dropdown-item
              @click.prevent="$setLocale(lang)"
              v-for="(lang, i) in $i18n.availableLocales"
              :key="`Lang${i}`"
              :value="lang"
              >{{ $i18n.locale }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </b-row>
    <b-row class="row g-0 m-0 p-0">
      <b-row class="col-lg-6 col-sm-12 m-0 p-0">
        <b-col class="mx-auto">
          <div>
            <label>
              {{ $t("adminPage.exhibitors.add-exhibitor.exhibitor-name") }}
            </label>
            <b-input type="text" v-model="exhibitor.title[$i18n.locale]" />
          </div>
          <label>
            {{ $t("adminPage.exhibitors.add-exhibitor.exhibitor-categories") }}
          </label>
          <b-row class="mb-1">
            <b-col lg="12">
              <b-form-select
                v-model="exhibitor.shopclass"
                size="sm"
                class="mt-0"
              >
                <option :value="null" disabled>
                  -- Please select an option --
                </option>
                <option
                  v-for="option in shopclasses"
                  :value="option"
                  :key="option.id"
                >
                  {{ option.title[$i18n.locale] }}
                </option>
              </b-form-select>
            </b-col>
          </b-row>
          <label class="mt-1">
            {{ $t("adminPage.events.title") }}
          </label>
          <b-row class="mb-1">
            <b-col lg="12">
              <b-form-select
                v-model="exhibitor.events"
                multiple
                :select-size="4"
                size="sm"
                class="mt-0"
              >
                <option :value="null" disabled>
                  -- Please select an option --
                </option>
                <option
                  v-for="option in events"
                  :value="option"
                  :key="option.id"
                >
                  {{ option.title[$i18n.locale] }}
                </option>
              </b-form-select>
            </b-col>
          </b-row>
          <label class="mt-1">
            {{ $t("adminPage.exhibitors.add-exhibitor.exhibitor-details") }}
          </label>
          <vue-editor
            style="width: 100%"
            class="text-normal"
            v-model="exhibitor.info[$i18n.locale]"
            :editor-toolbar="customToolbar"
          ></vue-editor>
        </b-col>
      </b-row>
      <b-row class="col-lg-6 col-sm-12 m-0 p-0">
        <b-col class="mx-auto">
          <div class="form-group">
            <b-row align-v="center">
              <b-col>
                <label class="mx-auto mt-1 mb-0">{{
                  $t("adminPage.exhibitors.add-exhibitor.video-item")
                }}</label>
              </b-col>
              <b-col>
                <template v-slot:append>
                  <b-button
                    size="sm"
                    @click="exhibitor.video.push('')"
                    class="pt-1 pb-1 pr-2 pl-2"
                    ><i class="fas fa-plus"></i>
                  </b-button>
                </template>
              </b-col>
            </b-row>

            <div>
              <div
                class="input-group mb-3"
                v-for="(vid, ivx) in exhibitor.video"
                :key="ivx"
              >
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    {{ $t("adminPage.exhibitors.add-exhibitor.video-item") }}
                  </div>
                </div>
                <input
                  type="text"
                  class="form-control"
                  style="text-transform: none !important"
                  v-model="exhibitor.video[ivx]"
                />
                <div
                  @click="exhibitor.video.slice(ivx, 1)"
                  class="input-group-append"
                >
                  <div class="input-group-text">
                    <i class="fas fa-trash"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="input-group">
            <b-form-file
              ref="fileupload"
              @change="uploadImage"
              class="mb-3"
              accept="image/*"
              multiple
              :placeholder="$t('adminPage.exhibitors.add-exhibitor.choose')"
              drop-placeholder="Drop file here..."
            >
              <template v-slot:file-name="{ names }">
                <b-badge variant="dark">{{ names[0] }}</b-badge>
                <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
                  + {{ names.length - 1 }} More files
                </b-badge>
              </template>
            </b-form-file>
          </div>
          <div
            class="form-group d-flex flex-wrap align-content-end align-content-center align-content-start p-1 m-2"
          >
            <div
              class="p-1"
              v-for="(image, inx) in exhibitor.images"
              :key="inx"
            >
              <div class="img-small-wrap">
                <img
                  v-if="image"
                  :src="image"
                  :alt="exhibitor.title[$i18n.locale]"
                  width="80px"
                />
                <span class="delete-img" @click="deleteImage(image, inx)"
                  >X</span
                >
                <span class="move-right-img" @click="seqProductImage(inx, 1)"
                  ><i class="fas fa-arrow-right"></i
                ></span>

                <span class="move-left-img" @click="seqProductImage(inx, -1)"
                  ><i class="fas fa-arrow-left"></i
                ></span>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-row>
    <b-row class="row g-0 m-0 p-0" align-h="end">
      <b-button
        pill
        size="sm"
        variant="success"
        @click="addExhibitor"
        class="mx-2"
        v-if="mode == 'new'"
        >{{ $t("adminPage.exhibitors.add-exhibitor.create") }}</b-button
      >
      <b-button
        pill
        size="sm"
        variant="success"
        @click="saveExhibitor()"
        v-if="mode == 'edit'"
        class="mx-2"
        >{{ $t("adminPage.exhibitors.add-exhibitor.save") }}</b-button
      >
      <b-button
        pill
        size="sm"
        variant="danger"
        class="mx-2"
        @click="changeMode(null)"
        >{{ $t("adminPage.exhibitors.add-exhibitor.cancel") }}</b-button
      >
    </b-row>
  </div>
</template>
<script>
//let intervalTimer;
import { VueEditor } from "vue2-editor";
import { fbAuth, db } from "@/configs/firebase";
import firebase from "firebase/compat/app";
import moment from "moment";

export default {
  props: {
    exhibitorX: {
      type: Object,
      required: true,
    },
    exhibitors: {
      type: Array,
    },
    events: {
      type: Array,
    },
    shopclasses: {
      type: Array,
    },
    changeMode: {
      type: Function,
    },
    updatePX: {
      type: Function,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  components: {
    VueEditor,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months prior
    const minDate = new Date(today);
    minDate.setMonth(minDate.getMonth() - 2);
    minDate.setDate(15);
    // 15th in two months
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth() + 24);
    maxDate.setDate(15);
    return {
      isProductModule: true,
      selectRegisterOptionId: 0,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
      payTypeOption: [
        {
          en: "Free",
          th: "ไม่เสียค่าใช้จ่าย",
          price: 0,
          term: { en: "", th: "" },
        },
        {
          en: "Register Fee",
          th: "มีค่าใช้จ่าย",
          price: 0,
          term: { en: "", th: "" },
        },
      ],
      productTypeOption: [
        {
          en: "student",
          th: "นักเรียน",
          disable: [],
          enable: [],
        },
        {
          en: "teacher",
          th: "ครู",
          disable: [],
          enable: [],
        },
        {
          en: "staff",
          th: "ทีมงาน",
          disable: [],
          enable: [],
        },
        {
          en: "officer",
          th: "พนักงาน",
          disable: [],
          enable: [],
        },
        {
          en: "member",
          th: "ระบบสมาชิก",
          disable: [],
          enable: [],
        },
      ],
      qTypeOption: [
        { en: "test", th: "ฝึกทำโจทย์", limitedTime: false },
        { en: "vote", th: "โหวต", limitedTime: true },
        { en: "quiz", th: "คำถาม", limitedTime: true },
        { en: "competition quiz", th: "คำถาม แข่งขัน", limitedTime: true },
      ],
      min: minDate,
      max: maxDate,
      startProductDate: moment(new Date()).format("YYYY-MM-DD"),
      startProductTime: moment(new Date()).format("HH:mm:ss"),
      endProductDate: moment(new Date()).format("YYYY-MM-DD"),
      endProductTime: moment(new Date()).format("HH:mm:ss"),
      exhibitor: this.exhibitorX,
    };
  },
  created() {
    if (this.mode == "edit") {
      let sDate = new Date(this.exhibitor.starttime.seconds * 1000);
      let stimeoffset = sDate.getTimezoneOffset();
      sDate = new Date(sDate.getTime() - stimeoffset * 60 * 1000);
      this.startProductDate = sDate.toISOString().split("T")[0];
      this.startProductTime = sDate.toISOString().split("T")[1].slice(0, 8);
      let eDate = new Date(this.exhibitor.endtime.seconds * 1000);
      let etimeoffset = eDate.getTimezoneOffset();
      eDate = new Date(eDate.getTime() - etimeoffset * 60 * 1000);
      this.endProductDate = eDate.toISOString().split("T")[0];
      this.endProductTime = eDate.toISOString().split("T")[1].slice(0, 8);
    } else {
      this.startProductDate = moment(new Date()).format("YYYY-MM-DD");
      this.startProductTime = moment(new Date()).format("HH:mm:ss");
      this.endProductDate = moment(new Date()).format("YYYY-MM-DD");
      this.endProductTime = moment(new Date()).format("HH:mm:ss");
    }
    //console.log(this.exhibitor.starttime);
  },
  methods: {
    rndStr(len) {
      let text = "";
      let chars =
        "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      let ondate = moment(new Date()).format("YYYYMMDD");
      return ondate + text;
    },
    
    
    convertToEndDate(qindex) {
      // start time
      var sDate = new Date(
        this.exhibitor.questions[qindex].sdate +
          "T" +
          this.exhibitor.questions[qindex].stime
      );
      //let stimeoffset = sDate.getTimezoneOffset();
      //sDate = new Date(sDate.getTime() + stimeoffset * 60 * 1000);

      var seconds =
        Math.floor(sDate.getTime() / 1000) +
        this.exhibitor.questions[qindex].minduration * 60;
      var eDate = new Date(seconds * 1000);

      this.exhibitor.questions[qindex].starttime =
        firebase.firestore.Timestamp.fromDate(sDate);
      this.exhibitor.questions[qindex].endtime =
        firebase.firestore.Timestamp.fromDate(eDate);
      //console.log("s date", sDate, " end date", eDate, "current", new Date());
    },
    gotoEditQuestion(exhibitorid, qId) {
      this.$router.push(
        `/${this.$i18n.locale}/${this.$route.params.oid}/admin/exhibitors/${exhibitorid}/questions/${qId}`
      );
    },
    setQuestionType(type, index) {
      this.exhibitor.questions[index].type = type;
      this.updatePX(this.exhibitor);
    },
    setProductType(type) {
      this.exhibitor.type = type;
      this.updatePX(this.exhibitor);
    },
    addTag() {
      // Checker for null array
      this.exhibitor.tags = this.exhibitor.tags || [];
      // pushing each tagword
      if (this.exhibitor.tags.length <= 5)
        this.exhibitor.tags.push(this.tag.slice(0, -1));

      this.tag = "";
      //console.log(this.exhibitor.tags);
      this.updatePX(this.exhibitor);
    },
    addItem() {
      this.exhibitor.subs = this.exhibitor.subs || [];
      if (this.exhibitor.subs.length <= 5) {
        this.exhibitor.subs.push({
          title: { en: "", th: "" },
          varPrice: 0.0,
          varPage: 0,
          review: true,
        });
      }
      this.updatePX(this.exhibitor);
    },
    removesubItems(index) {
      this.exhibitor.subs.splice(index, 1);
      this.updatePX(this.exhibitor);
    },
    removeVideo(index) {
      this.exhibitor.video.splice(index, 1);
      this.updatePX(this.exhibitor);
    },

    remove(item) {
      this.exhibitor.tags.splice(this.exhibitor.tags.indexOf(item), 1);
      this.updatePX(this.exhibitor);
    },
    removeMenu(index) {
      this.exhibitor.menus.splice(index, 1);
      this.updatePX(this.exhibitor);
    },
    addExhibitor() {
      this.exhibitor.flowNo = this.exhibitors.length;
      db.collection("organizations")
.doc(this.$route.params.oid)
.collection("exhibitors").add(this.exhibitor);
      this.updatePX(this.exhibitor);
      this.changeMode(null);
    },
    saveExhibitor() {
      this.exhibitor.timestamp =
        firebase.firestore.FieldValue.serverTimestamp();
      // start time
      var sDate = new Date(this.startProductDate + "T" + this.startProductTime);
      this.exhibitor.starttime = firebase.firestore.Timestamp.fromDate(sDate);
      /// end time
      var eDate = new Date(this.endProductDate + "T" + this.endProductTime);
      this.exhibitor.endtime = firebase.firestore.Timestamp.fromDate(eDate);

      db.collection("organizations")
.doc(this.$route.params.oid)
.collection("exhibitors")
        .doc(this.exhibitor.id)
        .update(this.exhibitor)
        .then(() => {
          //this.$refs.edit.hide();
          this.changeMode(null);
          this.updatePX(this.exhibitor);
          toast.fire({
            title: "Updated successfully",
          });
        });
    },
    handleChange(v) {
      this.files = v;
    },
    uploadImage(e) {
      let that = this;
      let oid = this.$route.params.oid;

      let exhibitorid = "new-exhibitor";
      if (this.mode === "edit") exhibitorid = this.exhibitor.id;

      if (e.target.files) {
        for (let i = 0; i < e.target.files.length; i++) {
          let file = e.target.files[i];
          this.multiUpload(file, oid, exhibitorid);
        }
      }
    },

    multiUpload(file, oid, exhibitorid) {
      let filename = this.rndStr(4);

      var storageRef = fbAuth
        .storage()
        .ref(`organizations/${oid}/exhibitors/${exhibitorid}/${filename}`);
      var uploadTask = storageRef.put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            let images = this.exhibitor.images || [];
            images.push(downloadURL); // Push the new URL to the array
            this.exhibitor.images = images; // Update the exhibitor's images
          });
        }
      );
    },
    deleteImage(img, index) {
      let image = fbAuth.storage().refFromURL(img);
      // console.log(image);
      this.exhibitor.images.splice(index, 1);
      this.updatePX(this.exhibitor);
      image
        .delete()
        .then(() => {
          console.log("Image deleted");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
