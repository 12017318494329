<template>
  <div class="Shopclasses">
    <div>
      <div class="row mx-0">
        <div class="row col-12 align-items-center">
          <div class="col-9 col-sm-9 col-md-9 col-lg-9">
            <h2>{{ $t("adminPage.categories.title") }}</h2>
            <p>{{ $t("adminPage.categories.text") }}</p>
          </div>
          <div class="col-3 col-sm-3 col-md-3 col-lg-3">
            <img
              src="@/assets/img/categories.svg"
              class="img-fluid"
              alt="overview image"
            />
          </div>
        </div>

        <!-- body -->
        <div class="row col-12 justify-content-end">
          <b-form-group
            :label="$t('search.label')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0 col-12 col-lg-10 col-md-10"
          >
            <b-input-group size="sm">
              <b-form-input
                @input="searchNow()"
                v-model="search"
                class="col-12"
                type="search"
                id="filterInput"
                :placeholder="$t('search.input')"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button :disabled="!search" @click="search = ''">{{
                  $t("search.clear")
                }}</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
        <div class="row mx-auto col-12 mt-2 justify-content-between">
          <div class="m-2">
            <div class="row justify-content-between" style="max-width: 400px">
              <div>
                <b-pagination
                  class="customPagination col-9"
                  pills
                  v-model="currentPage"
                  :total-rows="shopclasses.length"
                  :per-page="perPage"
                ></b-pagination>
              </div>
              <div></div>
              <div class="mx-2">
                <a class="fas fa-undo fa-2x"> </a>
              </div>
            </div>
          </div>
          <div class="ml-1">
            <b-button variant="success" @click="createNew">{{
              $t("adminPage.categories.list-button")
            }}</b-button>
          </div>
        </div>

        <!-- TABLE  -->
        <div class="col-lg-12 col-md-12 col-12 p-0 m-0 g-0">
          <div class="row mx-auto m-0 p-0 g-0">
            <div
              v-for="(classify, bi) in !search ? shopclasses : searchResults"
              :key="bi"
              class="jpage-card col-lg-5 col-md-5 col-12 p-0 m-2 g-0"
            >
              <div class="row p-2 m-2">
                <div class="row col-3 col-lg-3 mx-auto">
                  <img
                    v-for="image in classify.images"
                    :key="image.id"
                    :src="image"
                    alt="classify images"
                    class="img-fluid mx-auto"
                    style="width: 80px"
                  />
                  <div class="row mx-auto">
                    {{
                      classify.timestamp
                        ? new Date(classify.timestamp.seconds * 1000)
                            .toISOString()
                            .split("T")[0]
                        : ""
                    }}
                  </div>
                </div>
                <div class="col-3 col-lg-3">
                  <span class="row m-0">
                    {{ classify.title[$i18n.locale] }}</span
                  >
                </div>
                <div class="col-4 col-lg-2 m-0 p-1">
                  <div class="row mx-auto m-1">
                    <b-button
                      pill
                      size="sm"
                      class="m-1"
                      variant="primary"
                      @click="editClassify(classify)"
                      ><i class="fas p-1 fa-edit"></i
                    ></b-button>
                    <b-button
                      pill
                      size="sm"
                      class="m-1"
                      variant="danger"
                      @click="deleteClassify(classify)"
                      ><i class="fas p-1 fa-trash"></i
                    ></b-button>
                  </div>
                </div>
                <div class="col-2 col-lg-2 m-0">
                  <div class="row m-1 justify-content-center">
                    <b-form-checkbox
                      v-model="classify.status"
                      @change="updateStatus(classify)"
                      name="checkbox-1"
                      value="1"
                      class="mx-1"
                      unchecked-value="0"
                    >
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- modal -->
        <b-modal ref="edit" centered id="classify" size="xl">
          <template #modal-title>
            <div class="d-flex flex-row justify-content-between">
              <div v-if="modal === 'new'" class="pt-2">
                {{ $t("adminPage.categories.add-category.new-title") }}
              </div>
              <div v-if="modal === 'edit'" class="pt-2">
                {{ $t("adminPage.categories.add-category.edit-title") }}
              </div>
            </div>

            <b-row>
              <b-col lg="8">
                <b-input
                  :label="$t('adminPage.categories.add-category.category-name')"
                  type="text"
                  v-model="classify.title[$i18n.locale]"
                />
                <vue-editor
                  class="w-100 h-75 text-normal"
                  v-model="classify.info[$i18n.locale]"
                  :editor-toolbar="customToolbar"
                ></vue-editor>
              </b-col>

              <b-col>
                <div class="form-group"></div>
                <div class="input-group">
                  <b-form-file
                    @change="uploadImage"
                    class="mb-3"
                    accept="image/*"
                    multiple
                    :placeholder="
                      $t('adminPage.categories.add-category.choose')
                    "
                    drop-placeholder="Drop file here..."
                  >
                    <template v-slot:file-name="{ names }">
                      <b-badge variant="dark">{{ names[0] }}</b-badge>
                      <b-badge
                        v-if="names.length > 1"
                        variant="dark"
                        class="ml-1"
                      >
                        + {{ names.length - 1 }} More files
                      </b-badge>
                    </template>
                  </b-form-file>
                </div>
                <div class="form-group d-flex pt-2">
                  <div
                    class="p-1"
                    v-for="(image, index) in classify.images"
                    :key="index"
                  >
                    <div class="img-small-wrap">
                      <img
                        v-if="image"
                        :src="image"
                        :alt="classify.title[$i18n.locale]"
                        width="80px"
                      />
                      <span
                        class="delete-img"
                        @click="deleteImage(image, index)"
                        >X</span
                      >
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-slot:modal-footer="{ cancel }">
            <b-button
              pill
              size="sm"
              variant="success"
              @click="addClassify"
              v-if="modal == 'new'"
              >{{ $t("adminPage.categories.add-category.create") }}</b-button
            >
            <b-button
              pill
              size="sm"
              variant="success"
              @click="updateClassify()"
              v-if="modal == 'edit'"
              >{{ $t("adminPage.categories.add-category.save") }}</b-button
            >
            <b-button pill size="sm" variant="danger" @click="cancel()">{{
              $t("adminPage.categories.add-category.cancel")
            }}</b-button>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
// Advanced Use - Hook into Quill's API for Custom Functionality

import { VueEditor } from "vue2-editor";
import { fbAuth, db } from "@/configs/firebase";
import firebase from "firebase/compat/app";

export default {
  name: "shop-classes",
  props: {},
  components: {
    VueEditor,
  },
  data() {
    return {
      page: 1,
      perPage: 9,
      currentPage: 1,
      shopclasses: [], //shopclasses
      classify: {
        //single classify
        name: "",
        title: { en: "", th: "" },
        info: { en: "", th: "" },
        images: [],
        flowNo: 0,
        status: 0,
      },
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
      activeItem: null, //active selected item
      files: [],
      modal: null,
      loading: false,
      search: "",
      searchResults: [],
    };
  },
  computed: {
    // ..
  },
  methods: {
    enableRole() {
      let moduleindex = this.$store.state.currentRole.enableModules.findIndex(
        (x) => x.name == "exhibitors"
      );
      let isActive =
        moduleindex > -1
          ? this.$store.state.currentRole.enableModules[moduleindex].isActive
          : false;
      if (
        this.$store.state.currentRole != null &&
        moduleindex > -1 &&
        isActive
      ) {
        return true;
      }
      return false;
    },
    
    
    uploadImage(e) {
      const oid = this.$route.params.oid;

      if (e.target.files) {
        for (let i = 0; i < e.target.files.length; i++) {
          let file = e.target.files[i];
          this.multiUpload(file, oid);
        }
      }
    },

    multiUpload(file, oid) {
      const storageRef = fbAuth
        .storage()
        .ref(`organizations/${oid}/shopclasses/${file.name}`);

      const uploadTask = storageRef.put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            let images = this.classify.images || [];
            images.push(downloadURL); // Push the new image URL
            this.classify.images = images; // Update the images array
          });
        }
      );
    },
    deleteImage(img, index) {
      let image = fbAuth.storage().refFromURL(img);
      // console.log(image);
      this.classify.images.splice(index, 1);
      image
        .delete()
        .then(() => {
          console.log("Image deleted");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleChange(v) {
      this.files = v;
    },
    reset() {
      this.classify = {
        name: "",
        title: { en: "", th: "" },
        info: { en: "", th: "" },
        images: [],
        flowNo: 0,
        status: 0,
      };
    },
    createNew() {
      this.modal = "new";
      this.reset();
      this.$refs.edit.show();
    },
    addClassify() {
      this.classify.timestamp = firebase.firestore.FieldValue.serverTimestamp();
      this.classify.flowNo = this.shopclasses.length;
       db.collection("organizations")
.doc(this.$route.params.oid)
.collection("shopclasses").add(this.classify);
      this.$refs.edit.hide();
      // .catch(function(error) {
      //     console.error("Error adding document: ", error);
      // });
    },
    editClassify(classify) {
      this.modal = "edit";
      this.classify = classify;
      this.activeItem = JSON.stringify(classify);
      this.$refs.edit.show();
    },
    updateStatus(classify) {
      classify.timestamp = firebase.firestore.FieldValue.serverTimestamp();
       db.collection("organizations")
.doc(this.$route.params.oid)
.collection("shopclasses")
        .doc(classify.id)
        .update(classify)
        .then(() => {
          this.$refs.edit.hide();
          toast.fire({
            title: "Update status successfully",
          });
        });
    },
    updateSeq(classify, index, move) {
      if (index + move < this.shopclasses.length && index + move > -1) {
        classify.flowNo = index + move;
        if (move > 0) {
          if (index + 1 < this.shopclasses.length) {
            this.shopclasses.find((p) => p.id == classify.id).flowNo =
              index + 1;
            this.shopclasses[index + 1].flowNo = index;
             db.collection("organizations")
.doc(this.$route.params.oid)
.collection("shopclasses")
              .doc(classify.id)
              .update(classify);
          }
        } else {
          if (index - 1 > -1) {
            this.shopclasses[index - 1].flowNo = index;
            this.shopclasses.find((p) => p.id == classify.id).flowNo =
              index - 1;
             db.collection("organizations")
.doc(this.$route.params.oid)
.collection("shopclasses")
              .doc(classify.id)
              .update(classify);
          }
        }
        this.refreshTable();
      }
      //console.log(this.shopclasses);
    },
    refreshTable() {
      this.$binding(
        "shopclasses",
         db.collection("organizations")
.doc(this.$route.params.oid)
.collection("shopclasses")
      ).then((shopclasses) => {
        this.shopclasses = shopclasses.sort((a, b) => a.flowNo - b.flowNo); // => __ob__: Observer
        //console.log(this.shopclasses);
      });
    },
    updateClassify() {
      // Update function has issues so I have to apply this work-around
      // this.$firestore.shopclasses.doc(this.classify['.key']).set(this.classify).then(() => {
      //   this.$firestore.shopclasses.doc(this.activeItem).delete()}).then(()=>{
      // this.$refs.edit.hide()
      // toast.fire({
      //     type: 'success',
      //     title: 'Updated successfully'
      // });
      //   });

       db.collection("organizations")
.doc(this.$route.params.oid)
.collection("shopclasses")
        .doc(this.classify.id)
        .update(this.classify)
        .then(() => {
          this.$refs.edit.hide();
          toast.fire({
            title: "Updated successfully",
          });
        });
    },
    deleteClassify(doc) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
           db.collection("organizations")
.doc(this.$route.params.oid)
.collection("shopclasses")
            .doc(doc.id)
            .delete();

          for (var img in doc.images) {
            fbAuth
              .storage()
              .refFromURL(doc.images[img])
              .delete()
              .then(() => {
                console.log("image deleted");
              })
              .catch((err) => {
                console.log(err);
              });
            // console.log(doc.images[img])
          }

          toast.fire({
            title: "Deleted successfully",
          });
        }
      });
    },
    searchNow() {
      this.searchResults = this.shopclasses.filter((classify) => {
        if (
          classify.name.toLowerCase().indexOf(this.search.toLowerCase()) != "-1"
        ) {
          return classify;
        }
      });
    },
  }, // -- end methods -- //
  created() {
    // Binding Collections
    this.$binding(
      "shopclasses",
       db.collection("organizations")
.doc(this.$route.params.oid)
.collection("shopclasses")
    ).then((shopclasses) => {
      this.shopclasses = shopclasses.sort((a, b) => a.flowNo - b.flowNo); // => __ob__: Observer
      console.log(this.shopclasses);
    });
  },
};
</script>
<style scoped>
.img-small-wrap {
  position: relative;
}
.img-small-wrap .delete-img {
  position: absolute;
  top: -14px;
  right: -2px;
  cursor: pointer;
}
</style>
