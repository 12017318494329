// src/i18n/index.js
import { createI18n, useI18n } from "vue-i18n";

function loadLocaleMessages() {
  const locales = require.context(
    "../locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

// Create the i18n instance with Composition API support
const i18n = createI18n({
  legacy: false, // Use Composition API mode
  globalInjection: true, // Inject $t globally if needed
  locale: "th", // Default locale
  fallbackLocale: "th", // Fallback locale
  messages: loadLocaleMessages(),
});

export default i18n;
