<template>
  <div class="orders">
    <div class="container h-100">
      <div class="intro h-100">
        <div class="row h-100 align-items-center">
          <div class="col-md-8">
            <h1>Orders Page</h1>
            <p>{{ $t("adminPage.purchase.title") }}</p>
          </div>
          <div class="col-md-4">
            <img
              src="@/assets/img/orders.svg"
              class="img-fluid"
              alt="overview image"
            />
          </div>
        </div>
        <div class="table-responsive table-bordered text-center mt-4">
          <table class="table p-0">
            <thead class="thead-dark">
              <tr>
                <th scope="col">{{ $t("adminPage.purchases.table.id") }}</th>
                <th scope="col">{{ $t("adminPage.purchases.table.name") }}</th>
                <th scope="col">
                  {{ $t("adminPage.purchases.table.address") }}
                </th>
                <th scope="col">{{ $t("adminPage.purchases.table.price") }}</th>
                <th scope="col">
                  {{ $t("adminPage.purchases.table.modify.title") }}
                </th>
                <th scope="col">
                  {{ $t("adminPage.purchases.table.modify.status") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(purchase, index) in purchases" :key="index">
                <td class="align-middle">{{ purchase.id }}</td>
                <td class="align-middle">{{ purchase.deliveryName }}</td>
                <td class="align-middle">{{ purchase.deliveryAddress }}</td>
                <td class="align-middle">{{ purchase.totalPrice }}</td>
                <td class="align-middle">
                  <button
                    class="btn btn-primary btn-sm"
                    @click="editPurchase(purchase)"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                </td>
                <td class="align-middle">
                  <input
                    type="checkbox"
                    v-model="purchase.status"
                    @change="updateStatus(purchase)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Modal -->
        <div
          class="modal fade"
          id="purchase"
          tabindex="-1"
          role="dialog"
          aria-labelledby="purchaseLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="purchaseLabel">
                  {{
                    modal === "new"
                      ? $t("adminPage.purchases.table.add-purchase.new-title")
                      : $t("adminPage.purchases.table.add-purchase.edit-title")
                  }}
                </h5>
                <button type="button" class="close" @click="closeModal">
                  <span>&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-lg-8">
                    <div class="form-group">
                      <label>{{
                        $t(
                          "adminPage.purchases.table.add-purchase.purchase-name"
                        )
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="purchase.deliveryName"
                      />
                    </div>
                    <div class="form-group">
                      <label>{{
                        $t("adminPage.purchases.delivery-mobile")
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="purchase.deliveryMobile"
                      />
                    </div>
                    <div class="form-group">
                      <label>{{
                        $t("adminPage.purchases.delivery-address")
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="purchase.deliveryAddress"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>{{
                        $t("adminPage.purchases.table.add-purchase.choose")
                      }}</label>
                      <input
                        type="file"
                        @change="uploadImage"
                        class="form-control-file"
                        multiple
                      />
                    </div>
                    <div class="d-flex pt-2">
                      <div
                        v-for="(image, index) in purchase.images"
                        :key="index"
                        class="p-1"
                      >
                        <div class="img-small-wrap">
                          <img
                            v-if="image"
                            :src="image"
                            alt="Image"
                            width="80px"
                          />
                          <span
                            class="delete-img"
                            @click="deleteImage(image, index)"
                            >X</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  class="btn btn-success"
                  @click="updatePurchase()"
                  v-if="modal === 'edit'"
                >
                  {{ $t("adminPage.purchases.table.add-purchase.save") }}
                </button>
                <button class="btn btn-danger" @click="closeModal">
                  {{ $t("adminPage.purchases.table.add-purchase.cancel") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { fbAuth, db } from "@/configs/firebase";
export default {
  name: "AdminPurchases",
  props: {},
  components: {},
  data() {
    return {
      purchases: [], //purchases
      purchase: {
        //single purchase
        name: null,
        info: null,
        price: null,
        tags: [],
        images: [],
      },
      activeItem: null, //active selected item
      files: [],
      modal: null,
      tag: "", // single tag
    };
  },
  methods: {
    enableRole() {
      let moduleindex = this.$store.state.currentRole.enableModules.findIndex(
        (x) => x.name.toLowerCase() == "orders"
      );
      let isActive =
        moduleindex > -1
          ? this.$store.state.currentRole.enableModules[moduleindex].isActive
          : false;
      if (
        this.$store.state.currentRole != null &&
        moduleindex > -1 &&
        isActive
      ) {
        return true;
      }
      return false;
    },
    
    
    handleChange(v) {
      this.files = v;
    },

    editPurchase(purchase) {
      this.modal = "edit";
      this.purchase = purchase;
      this.activeItem = JSON.stringify(purchase);
      this.$refs.edit.show();
    },
    updatePurchase() {
      this.$firestore.purchases
        .doc(this.purchase.id)
        .update(this.purchase)
        .then(() => {
          this.$refs.edit.hide();
          toast.fire({
            title: "Updated successfully",
          });
        });
    },
    deletePurchase(doc) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$firestore.purchases.doc(doc[".key"]).delete();
          toast.fire({
            title: "Deleted successfully",
          });
        }
      });
    },
  }, // -- end methods -- //

  created() {
    // this.$binding("purchases", this.$dborgfn("project",this.$route.params.oid).collection("purchases")).then(
    //   (purchases) => {
    //     console.log(purchases)
    //     this.purchases = purchases; // => __ob__: Observer
    //   }
    // );
    this.purchases = [];
    db.collection("organizations")
      .doc(this.$route.params.oid)
      .collection("project")
      .collection("purchases")
      .onSnapshot((snapShot) => {
        snapShot.forEach((doc) => {
          db.collection("organizations")
            .doc(this.$route.params.oid)
            .collection("project")
            .collection("purchases")
            .doc(doc.id)
            .collection("orders")
            .onSnapshot((purchases) => {
              purchases.forEach((pur) => {
                var order = pur.data();
                order.mid = doc.id;
                order.user = doc.data();
                console.log(order);
                this.purchases.push(order); // => __ob__: Observer
              });
            });

          console.log(this.purchases);
        });
      });
  },
};
</script>
<style scoped>
.img-small-wrap {
  position: relative;
}
.img-small-wrap .delete-img {
  position: absolute;
  top: -14px;
  right: -2px;
  cursor: pointer;
}
</style>
