<template>
  <div
    class="main-nav d-flex flex-column p-0 mx-1"
    v-if="!$route.meta.hideNavigation && showNavigation"
    @mousemove="resetNavigationTimer"
    @touchstart="resetNavigationTimer"
  >
    <nav class="navbar navbar-expand-lg navbar-light bg-light sticky-top">
      <!-- Navbar Toggle for Mobile -->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Navbar Links -->
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav me-auto">
          <!-- Home Link -->
          <li v-if="$route.params.oid" class="nav-item">
            <a
              class="nav-link"
              :href="`/${$i18n.locale}/${$route.params.oid}/home`"
            >
              <i class="fa fa-home"></i> Home
            </a>
          </li>

          <!-- User Dropdown for Logged-in Session -->
          <li v-if="session" class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                :src="displayPicture()"
                width="30"
                height="30"
                class="rounded-circle"
                @error="replaceByDefault"
                alt="User"
              />
            </a>
            <ul class="dropdown-menu" aria-labelledby="userDropdown">
              <li>
                <a class="dropdown-item" @click.prevent="goActivity()"
                  >Activity</a
                >
              </li>
              <li v-if="isAdmin">
                <a class="dropdown-item" @click.prevent="goAdmin()">Admin</a>
              </li>
              <li>
                <a class="dropdown-item" @click.prevent="logoutFirebase()"
                  >Logout</a
                >
              </li>
              <li>
                <span class="dropdown-item-text"
                  >Version: {{ $jpversion }}</span
                >
              </li>
            </ul>
          </li>

          <!-- Login Option for Non-Logged-in Users -->
          <li class="nav-item" v-else>
            <a
              class="nav-link"
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#modal-login"
            >
              <span><i class="fa fa-user"></i></span> Login
            </a>
          </li>
        </ul>

        <!-- Locale Dropdown -->
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="languageDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <strong class="me-1">{{ $i18n.locale }}</strong>
              <span><i class="fas fa-globe"></i></span>
            </a>
            <ul
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="languageDropdown"
            >
              <li v-for="(lang, i) in $i18n.availableLocales" :key="`Lang${i}`">
                <a class="dropdown-item" @click.prevent="$setLocale(lang)">{{
                  lang
                }}</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>
<script>
import {
  onAuthStateChanged,
  signInWithCustomToken,
  signOut,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import axios from "axios";
import moment from "moment";
import { db, fbAuth } from "@/configs/firebase.js"; // Adjust if needed for your config

export default {
  name: "NavMain",
  data() {
    return {
      packageVersion: this.$jpversion,
      showNavigation: true,
      navigationTimer: null,
      currentuser: {
        id: null,
        displayName: "",
        name: "",
        photoURL: "",
        displayUrl: "",
        email: "",
      },
      submitted: false,
      isAdmin: false,
      customer: null,
      sessionId: "",
      verified: true,
      logged: true,
      session: false,
      statequery: null,
    };
  },
  computed: {
    routeMode() {
      return this.$route.name;
    },
  },
  created() {
    console.log("Nav created");
    this.createLineUserDirect();
    this.checkFirebaseLoginState();
  },
  methods: {
    resetNavigationTimer() {
      this.showNavigation = true;
      if (this.$route.path.includes("school_summary")) {
        clearTimeout(this.navigationTimer);
        this.navigationTimer = setTimeout(() => {
          this.showNavigation = false;
        }, 5000);
      }
    },
    goActivity() {
      const path = this.$route.params.oid
        ? `/${this.$i18n.locale}/${this.$route.params.oid}/activity`
        : `/${this.$i18n.locale}/activity`;
      this.$router.push({ path });
    },
    goAdmin() {
      if (this.$route.params.oid) {
        this.$router.push({
          path: `/${this.$i18n.locale}/${this.$route.params.oid}/admin/overview`,
        });
      }
    },
    async createLineUserDirect() {
      this.statequery = this.$route.query;
      if (
        this.statequery.state?.startsWith("line_login") &&
        this.statequery.code
      ) {
        if (window.localStorage.getItem("line_code") !== this.statequery.code) {
          window.localStorage.setItem("line_code", this.statequery.code);
          try {
            const host = window.location.host;
            const response = await axios.post(
              "https://asia-northeast1-jinnsolution.cloudfunctions.net/createLineUser",
              {
                oid: this.$route.params.oid,
                host,
                code: this.statequery.code,
              },
              { headers: { "Content-Type": "application/json;charset=UTF-8" } }
            );
            await signInWithCustomToken(fbAuth, response.data.firebase_token);
            if (this.$store.state.loginState?.path) {
              this.$router.push({ path: this.$store.state.loginState.path });
            }
          } catch (error) {
            console.error("Error during sign-in:", error);
          }
        }
      }
    },
    checkFirebaseLoginState() {
      onAuthStateChanged(fbAuth, async (user) => {
        if (user) {
          this.logged = true;
          this.verified = user.emailVerified;
          this.customer = user;
          this.session = true;
          this.currentuser = {
            id: user.uid,
            displayName: user.displayName,
            name: user.displayName,
            photoURL: user.photoURL,
            displayUrl: user.photoURL,
          };
          const line_id = this.currentuser.id.replace("line:", "");
          this.currentuser.line_id = line_id;
          await this.checkAndUpdatePhoto(user);
          if (this.$route.params.oid) {
            const docRef = doc(
              db,
              "organizations",
              this.$route.params.oid,
              "roles",
              this.currentuser.id
            );
            const docSnap = await getDoc(docRef);
            if (
              docSnap.exists() &&
              [
                "PtP6Hu3tsChqIZeGh9ClY22jcDw2",
                "U6fe8a56f782834ca09f0af2d02f93bf8",
              ].includes(line_id)
            ) {
              this.isAdmin = true;
            }
          }
          this.$store.commit("updateCurrentUser", this.currentuser);
        } else {
          this.logged = false;
          this.verified = false;
          this.session = false;
          window.localStorage.removeItem("currentuser");
          this.resetUserData();
          this.openModalLoginOnly();
        }
      });
    },
    resetUserData() {
      this.currentuser = {
        id: "",
        name: "",
        displayName: "",
        displayUrl: "",
        email: "",
        role: "",
        telephone: "",
        company: "",
        memberid: "",
        provider: "",
        isActive: false,
        timestamp: null,
        eventid: "",
      };
    },
    async checkAndUpdatePhoto(user) {
      try {
        const response = await axios.get(user.photoURL);
        if (response.status !== 200) {
          await this.updateUserLinePhoto(user);
        }
      } catch (error) {
        await this.updateUserLinePhoto(user);
        console.error("Error checking URL:", error);
      }
    },
    async updateUserLinePhoto(user) {
      if (user.uid) {
        try {
          const response = await axios.post(
            "https://asia-northeast1-jinnsolution.cloudfunctions.net/UpdateUserPhoto",
            { oid: this.$route.params.oid, userId: user.uid },
            { headers: { "Content-Type": "application/json;charset=UTF-8" } }
          );
          if (response.data.status === "complete") {
            this.currentuser.photoURL = response.data.photoURL;
          }
        } catch (error) {
          console.error("Error updating photo:", error);
        }
      }
    },
    displayPicture() {
      return this.currentuser.displayUrl || "https://jinn.page/img/avatar.png";
    },
    openModalLoginOnly() {
      console.log("modal login-only");
      this.$root.$emit("bv::show::modal", "modal-login", "#btnShow");
    },
    async logout() {
      try {
        await signOut(fbAuth);
        this.resetUserData();
      } catch (error) {
        console.error("Error during logout:", error);
      }
    },
  },
};
</script>

<style scoped>
* {
  text-transform: uppercase !important;
}
/* Navbar customization */
.sticky-top {
  z-index: 1030;
}

.navbar-toggler {
  border-color: transparent;
}
.navbar-expand-lg {
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
}
.navbar {
  background-color: turquoise;
  border: 1px solid #eb467b;
  box-shadow: 0 2px 4px rgba(42, 42, 42, 0.21); /* Default shadow for larger screens */
  border-radius: 3px;
  margin-bottom: 10px;
}

.navbar-brand img {
  margin-right: 8px;
}

/* Dropdown Menu Styles */
.dropdown-menu {
  min-width: 10rem;
}

.navbar-nav .nav-item .nav-link {
  display: flex;
  align-items: center;
}

.dropdown-item-text {
  font-size: 0.9rem;
  color: #6c757d;
}

/* For responsive display */
@media (max-width: 576px) {
  .navbar-text {
    font-size: 0.8rem;
    text-align: center;
  }
  .dropdown-menu {
    width: 100%;
  }
}
</style>
