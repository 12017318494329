<template>
  <div class="events">
    <div v-if="!mode">
      <div>
        <div class="row col-12 align-items-center">
          <div class="col-9 col-sm-9 col-md-9 col-lg-9">
            <h4>{{ $t("adminPage.events.title") }}</h4>
            <p>{{ $t("adminPage.events.text") }}</p>
          </div>
          <div class="col-3 col-sm-3 col-md-3 col-lg-3">
            <img
              src="@/assets/img/events.svg"
              class="img-fluid"
              alt="overview image"
            />
          </div>
        </div>

        <!-- body -->
        <div class="row col-12 justify-content-end">
          <b-form-group
            :label="$t('search.label')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0 col-12 col-lg-10 col-md-10"
          >
            <b-input-group size="sm">
              <b-form-input
                @input="searchNow()"
                v-model="search"
                class="col-12"
                type="search"
                id="filterInput"
                :placeholder="$t('search.input')"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button :disabled="!search" @click="search = ''">{{
                  $t("search.clear")
                }}</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>

        <!-- ./body -->
        <div class="row col-12 mt-2 justify-content-between mx-2">
          <div class="row col-9 col-sm-6 col-lg-6">
            <div class="row justify-content-between" style="max-width: 400px">
              <div>
                <b-pagination
                  class="customPagination col-sm-5"
                  pills
                  v-model="currentPage"
                  :total-rows="events.length"
                  :per-page="perPage"
                ></b-pagination>
              </div>
              <div></div>
              <div>
                <a @click="refreshEventTable()" class="fas fa-undo fa-2x"> </a>
              </div>
            </div>
          </div>
          <div v-if="enableRole('admin')" class="ml-0">
            <b-button variant="success" @click="createNew">{{
              $t("adminPage.events.list-button")
            }}</b-button>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-12 g-0 p-0 m-1">
        <div class="row m-1 mx-auto p-0 justify-content-center">
          <div
            v-for="(event, ei) in !search ? events : searchResults"
            :key="ei"
            class="jpage-card col-lg-5 col-md-5 col-12 mx-auto"
            style="margin: 5px"
          >
            <div class="row">
              <div @click="gotoEventHome(event)" class="col-3 col-lg-3 mx-auto">
                <img
                  v-for="image in event.images"
                  :key="image.id"
                  :src="image"
                  alt="event images"
                  class="img-fluid mx-auto"
                  style="width: 80px"
                />
                <div class="row mx-auto"></div>
              </div>
              <div class="col-3 col-lg-3">
                <span class="row m-0"> {{ event.title[$i18n.locale] }}</span>
              </div>
              <div class="col-2 col-lg-2 m-0 p-1">
                <div v-if="enableRole('admin')" class="row mx-auto m-1">
                  <b-button
                    pill
                    size="sm"
                    class="m-1"
                    variant="primary"
                    @click="editEvent(event)"
                    ><i class="fas p-1 fa-edit"></i
                  ></b-button>
                  <b-button
                    pill
                    size="sm"
                    class="m-1"
                    variant="danger"
                    @click="deleteEvent(event)"
                    ><i class="fas p-1 fa-trash"></i
                  ></b-button>
                  <!-- <b-button
                  pill
                  size="sm"
                  class="mx-1"
                  variant="danger"
                  @click="gotoEventHome(event)"
                  ><i class="fas m-1 fa-home"></i
                ></b-button> -->
                </div>
              </div>
              <div class="col-2 col-lg-2 m-0 p-1">
                <div class="row p-1 justify-content-center">
                  <b-button
                    pill
                    size="sm"
                    variant="danger"
                    class="m-1"
                    @click="paticipates(event)"
                    ><i class="fas p-1 fa-list"></i
                  ></b-button>
                  <b-button
                    pill
                    size="sm"
                    class="m-1"
                    variant="danger"
                    @click="register(event)"
                    ><i class="fas p-1 fa-address-card"></i
                  ></b-button>
                </div>
                <!-- <div class="row p-1 justify-content-center">
                <b-button
                  pill
                  size="sm"
                  variant="danger"
                  class="mx-1"
                  @click="updateSeq(event, index, -1)"
                  ><i class="fas p-1 fa-arrow-up"></i
                ></b-button>
                <b-button
                  pill
                  size="sm"
                  variant="danger"
                  class="mx-1"
                  @click="updateSeq(event, index, 1)"
                  ><i class="fas p-1 fa-arrow-down"></i
                ></b-button>
              </div> -->
              </div>
              <div class="col-2 col-lg-2 m-0">
                <div
                  v-if="enableRole('admin')"
                  class="row m-1 justify-content-center"
                >
                  <b-form-checkbox
                    v-model="event.status"
                    @change="updateStatus(event)"
                    name="checkbox-1"
                    value="1"
                    class="mx-1"
                    unchecked-value="0"
                  >
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
          <!-- TABLE  -->
          <!-- table -->
        </div>
      </div>
      <div>
        <!-- remove bar-loader -->
        <!-- mode -->
      </div>
    </div>
    <div v-else class="mx-1">
      <widget-admin-event-edit
        v-if="event && mode"
        :eventX="event"
        :events="events"
        :changeMode="changeMode"
        :updateEvent="updateEvent"
        :mode="mode"
      ></widget-admin-event-edit>
      <div style="background-color: white"></div>
    </div>
  </div>
</template>
<script>
// Advanced Use - Hook into Quill's API for Custom Functionality

import { fbAuth, db } from "@/configs/firebase";
import firebase from "firebase/compat/app";
import WidgetAdminEventEdit from "@/components/widgets/WidgetAdminEventEdit";
import moment from "moment";

export default {
  name: "AdminEvents",
  props: {},
  components: {
    WidgetAdminEventEdit,
  },
  data() {
    return {
      page: 1,
      perPage: 9,
      currentPage: 1,
      events: [], //events
      event: {
        //single event
        id: "",
        master: null,
        name: "",
        title: { en: "", th: "" },
        info: { en: "", th: "" },
        menus: [],
        menuImage: null,
        type: {
          en: "trade show",
          th: "งานแสดงสินค้า",
          subEvent: [
            { en: "sub seminar", th: "สัมนาย่อย" },
            { en: "training", th: "ฝึกอบรม" },
          ],
          disable: [],
          enable: [],
        },
        isRequiredRegister: true,
        registerRequired: [],
        questions: [],
        payment: {
          en: "Free",
          th: "ไม่เสียค่าใช้จ่าย",
          price: 0,
          term: { en: "", th: "" },
          images: [],
        },
        liffid: "",
        images: [],
        flowNo: 0,
        status: 0,
        isScheduled: false,
        starttime: firebase.firestore.FieldValue.serverTimestamp(),
        endtime: firebase.firestore.FieldValue.serverTimestamp(),
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      },
      activeItem: null, //active selected item
      files: [],
      mode: null,
      loading: false,
      search: "",
      searchResults: [],
    };
  },
  created() {
    // Binding Collections
    this.refreshEventTable();
  },
  watch: {
    events() {
      this.setPages();
    },
  },

  methods: {
    enableRole(rolename) {
      return true;
    },
    reset() {
      this.event = {
        //single event
        id: "",
        name: "",
        master: null,
        title: { en: "", th: "" },
        info: { en: "", th: "" },
        menus: [],
        menuImage: null,
        type: {
          en: "trade show",
          th: "งานแสดงสินค้า",
          subEvent: [
            { en: "sub seminar", th: "สัมนาย่อย" },
            { en: "training", th: "ฝึกอบรม" },
          ],
          disable: [],
          enable: [],
        },
        isRequiredRegister: true,
        registerRequired: [],
        questions: [],
        payment: {
          en: "Free",
          th: "ไม่เสียค่าใช้จ่าย",
          price: 0,
          term: { en: "", th: "" },
          images: [],
        },
        liffid: "",
        images: [],
        flowNo: 0,
        status: 0,
        isScheduled: false,
        starttime: firebase.firestore.FieldValue.serverTimestamp(),
        endtime: firebase.firestore.FieldValue.serverTimestamp(),
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      };
    },
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(this.events.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(items) {
      let page = this.currentPage;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return items.slice(from, to);
    },
    rndStr(len) {
      let text = "";
      let chars =
        "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      let ondate = moment(new Date()).format("YYYYMMDD");
      return ondate + text;
    },
    changeMode(mode) {
      this.mode = mode;
    },
    updateEvent(event) {
      this.event = event;
    },
    updateRegistForm(event, registForm) {
      this.event = event;
      this.event.registerRequired = registForm;
    },
    createNew() {
      this.mode = "new";
      this.reset();
      //this.$refs.edit.show();
    },
    editEvent(event) {
      this.mode = "edit";
      this.event = event;
      //console.log(this.event);
      this.activeItem = JSON.stringify(event);
      // if (this.event !== null) this.$refs.edit.show();
    },
    refreshEventTable() {
      this.events = [];
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("events")
        .onSnapshot((snapshot) => {
          var f = [];
          snapshot.docChanges().forEach((change) => {
            let doc = change.doc;
            //console.log("changed!");
            let getevent = doc.data();
            getevent.id = doc.id;

            let ioldevent = this.events.findIndex(
              (evt) => evt.id == getevent.id
            );
            if (ioldevent < 0) this.events.push(getevent);
            else {
              //this.events = this.events.sort((a, b) => a.flowNo - b.flowNo);
              this.$set(this.events, ioldevent, getevent);
            }
          });
          //this.events = f.sort((a, b) => a.order_no - b.order_no);
        });
    },
    updateStatus(event) {
      event.timestamp = firebase.firestore.FieldValue.serverTimestamp();
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("events")
        .doc(event.id)
        .update(event)
        .then(() => {
          this.$refs.edit.hide();
          toast.fire({
            title: "Update status successfully",
          });
        });
    },
    updateSeq(event, index, move) {
      if (index + move < this.events.length && index + move > -1) {
        event.flowNo = index + move;
        if (move > 0) {
          if (index + 1 < this.events.length) {
            this.events.find((p) => p.id == event.id).flowNo = index + 1;
            this.events[index + 1].flowNo = index;
            db.collection("organizations")
              .doc(this.$route.params.oid)
              .collection("events")
              .doc(event.id)
              .update(event);
          }
        } else {
          if (index - 1 > -1) {
            this.events[index - 1].flowNo = index;
            this.events.find((p) => p.id == event.id).flowNo = index - 1;
            db.collection("organizations")
              .doc(this.$route.params.oid)
              .collection("events")
              .doc(event.id)
              .update(event);
          }
        }
        //this.refreshTable();
      }
      //console.log(this.events);
    },
    gotoEventHome(event) {
      this.$router.push(
        `/${this.$i18n.locale}/${this.$route.params.oid}/event/${event.id}/home`
      );
    },
    paticipates(event) {
      this.$router.push(
        `/${this.$i18n.locale}/${this.$route.params.oid}/admin/event/${event.id}/paticipants`
      );
    },
    gotoEditQuestion(eventId, qId) {
      this.$router.push(
        `/${this.$i18n.locale}/${this.$route.params.oid}/admin/events/${eventId}/questions/${qId}`
      );
    },
    register(event) {
      this.$router.push(
        `/${this.$i18n.locale}/${this.$route.params.oid}/event/${
          event.id
        }/register/${this.rndStr(4)}`
      );
    },
    refreshTable() {
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("events")
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            let doc = change.doc;
            ////console.log("changed!");
            let getevent = doc.data();
            getevent.id = doc.id;
            //console.log(getRegister);
            let ioldevent = this.events.findIndex(
              (evt) => evt.id == getevent.id
            );
            if (ioldevent < 0) this.events.push(getevent);
            else {
              this.events = this.events.sort((a, b) => a.flowNo - b.flowNo);
              this.$set(this.events, ioldevent, getevent);
            }
            if (change.type === "added" || change.type === "removed") {
              this.$set(this.events, ioldevent, getevent);
            }
          });
        });
    },

    deleteEvent(doc) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          db.collection("organizations")
            .doc(this.$route.params.oid)
            .collection("events")
            .doc(doc.id)
            .delete();

          for (var img in doc.images) {
            fbAuth
              .storage()
              .refFromURL(doc.images[img])
              .delete()
              .then(() => {
                console.log("image deleted");
              })
              .catch((err) => {
                console.log(err);
              });
            // console.log(doc.images[img])
          }

          toast.fire({
            title: "Deleted successfully",
          });
        }
      });
    },
    searchNow() {
      this.searchResults = this.events.filter((event) => {
        if (
          event.name.toLowerCase().indexOf(this.search.toLowerCase()) != "-1"
        ) {
          return event;
        }
      });
    },
  }, // -- end methods -- //
};
</script>
<style scoped>
.img-small-wrap {
  position: relative;
}
.img-small-wrap .delete-img {
  position: absolute;
  top: -14px;
  right: -2px;
  cursor: pointer;
}
</style>
