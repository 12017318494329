<template>
  <div class="container">
    <div v-if="organization" class="overview mx-auto" style="max-width: 700px">
      <div class="row">
        <div class="col-12">
          <div class="d-flex align-items-center">
            <div class="col-8 col-sm-7 col-md-9">
              <h3>{{ $t("adminPage.overview.title") }}</h3>
            </div>
            <div class="col-4 col-sm-5 col-md-3">
              <img
                src="@/assets/img/overview.svg"
                class="img-fluid"
                alt="overview image"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row mt-1 mx-0 justify-content-end">
          <div>
            <button class="btn btn-primary m-1" @click="gotoHome()">
              {{ "หน้าแรก" }}
            </button>
            <button class="btn btn-secondary m-1" @click="gotoAccessDevices()">
              {{ "Access Devices" }}
            </button>
          </div>
          <button class="btn btn-danger m-1">
            <i
              class="fas fa-trash m-1"
              :disabled="
                $store.state.currentuser.email != 'attawit.k@gmail.com' ||
                $store.state.currentuser.email != 'mutita.kedkaew@gmail.com'
              "
              @click="deleteOrganization(organization)"
              ><span class="m-1">{{ "ลบองค์กร" }}</span></i
            >
          </button>
        </div>
      </div>
      <div v-if="currentRole.role.toLowerCase() == 'admin'" class="col-12">
        <div class="row col-md-3 col-lg-3 col-4 pl-2 my-auto">
          <strong style="color: blue">Modules</strong>
        </div>
        <div class="row col-12 align-items-sm-start">
          <div
            class="p-1 col-lg-3 col-md-4 col-sm-6 col-6"
            v-for="(module, mindex) in organization.enableModules"
            :key="mindex"
          >
            <div class="module-card">
              <div class="d-flex justify-content-between align-items-center">
                <div class="my-auto">
                  <b-form-checkbox
                    v-model="organization.enableModules[mindex].isActive"
                    @change="updateStatus(organization)"
                    class="mr-2"
                  >
                    {{ organization.enableModules[mindex].name }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="
          organization.enableThemes.length > 0 &&
          currentRole.role.toLowerCase() == 'admin'
        "
        class="col-12"
      >
        <div class="row col-12 align-items-sm-start">
          <div class="row col-md-3 col-lg-3 col-4 pl-2 my-auto">
            <strong style="color: blue">Theme</strong>
          </div>

          <!-- Inside the 'Theme' section -->
          <div
            class="p-1 col-lg-3 col-md-4 col-sm-6 col-12"
            v-for="(theme, mindex) in organization.enableThemes"
            :key="mindex"
          >
            <div class="row align-items-sm-start">
              <div class="mx-1 col-4 px-1">
                {{ organization.enableThemes[mindex].name }}
              </div>
              <div class="mx-1 col-1 align-items-sm-start">
                <b-form-checkbox
                  v-model="organization.enableModules[mindex].isActive"
                  @change="updateStatus(organization)"
                >
                  {{ organization.enableModules[mindex].name }}
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mx-auto col-12 mt-2 justify-content-between">
        <div>
          <div class="row justify-content-between" style="max-width: 400px">
            <div class="p-2"><h3>Member</h3></div>
          </div>
        </div>
        <div class="ml-1">
          <b-button variant="success" @click="gotoCreateMemboerInvite()">
            {{ "เพิ่มสมาชิก" }}
          </b-button>
        </div>
      </div>
      <div v-if="members.length > 0">
        <b-row
          class="p-1 m-1 member-card"
          v-for="(member, mi) in members"
          :key="mi"
        >
          <div class="col-12">
            <div class="row align-items-center">
              <div class="col-md-6">
                <span class="row m-0">{{ member.displayName }}</span>
                <span class="row text-normal text-right m-0">{{
                  member.email
                }}</span>
                <span class="row text-right m-0">Role: {{ member.role }}</span>
              </div>
              <div class="col-md-4">
                <div class="row">
                  <div
                    v-for="(mod, minx) in member.enableModules"
                    :key="minx"
                    class="col-6 col-sm-4 col-md-auto p-1 d-flex align-items-center"
                  >
                    <input
                      @change="updateMemberModule(member)"
                      v-model="member.enableModules[minx].isActive"
                      type="checkbox"
                      :disabled="currentRole.role.toLowerCase() !== 'admin'"
                      :checked="member.enableModules[minx].isActive"
                    />
                    <span class="mx-1">{{
                      member.enableModules[minx].name
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-2 text-right">
                <i
                  class="fas fa-trash delete-icon"
                  @click="deleteTeam(member)"
                ></i>
              </div>
            </div>
          </div>
        </b-row>
      </div>
      <hr />
      <div class="row mx-auto col-12 mt-2 justify-content-between">
        <div>
          <div class="row justify-content-start" style="max-width: 400px">
            <div class="p-2"><h3>รูปภาพ</h3></div>
          </div>
        </div>
      </div>
      <div class="jpage-card">
        <div class="input-group">
          <b-form-file
            @change="uploadImage"
            class="mb-3"
            accept="image/*"
            multiple
            :placeholder="$t('adminPage.events.add-event.choose')"
            drop-placeholder="Drop file here..."
          >
            <template v-slot:file-name="{ names }">
              <b-badge variant="dark">{{ names[0] }}</b-badge>
              <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
                + {{ names.length - 1 }} More files
              </b-badge>
            </template>
          </b-form-file>
        </div>
        <div class="form-group d-flex pt-2">
          <div
            class="p-1"
            v-for="(img, imgindex) in organization.images"
            :key="imgindex"
          >
            <div class="img-small-wrap">
              <img v-if="img" :src="img" width="80px" />
              <span class="delete-img" @click="deleteImage(image, index)"
                >X</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { db, fbAuth } from "@/configs/firebase";
import firebase from "firebase/compat/app";
import { mapGetters, mapState } from "vuex";
import moment from "moment";

export default {
  name: "admin-overview",
  data() {
    return {
      currentRole: { role: "staff" },
      organization: {
        id: "",
        email: "",
        telephone: "",
        enableModules: [
          { name: "product", isActive: true },
          { name: "event", isActive: true },
        ],
        images: [],
        title: "",
        favicon: "",
        enableThemes: [
          { name: "product", isActive: true },
          { name: "event", isActive: true },
        ],
      },
      members: [],
      modules: [
        { name: "product", isActive: true },
        { name: "event", isActive: true },
      ],
    };
  },
  computed: mapState({ users: (state) => state.users }),
  calculatedData() {},
  created() {
    //console.log(this.$route);

    this.$store.dispatch("listAllUsers");
  },
  mounted() {
    this.getRole();
  },
  methods: {
    getRole() {
       db.collection("organizations")
.doc(this.$route.params.oid)
.collection("roles").onSnapshot((snapShot) => {
        this.members = [];
        snapShot.forEach((doc) => {
          var mem = doc.data();
          mem.id = doc.id;
          this.members.push(mem);
        });
      });
    },
    rndStr(len) {
      let text = "";
      let chars =
        "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      let ondate = moment(new Date()).format("YYYYMMDD");
      return ondate + text;
    },
    gotoHome() {
      this.$router.push({
        path: `/${this.$i18n.locale}/${this.$route.params.oid}/home`,
      });
    },
    gotoAccessDevices() {
      this.$router.push({
        path: `/${this.$i18n.locale}/${this.$route.params.oid}/admin/accessdevices`,
      });
    },
    gotoCreateMemboerInvite() {
      //console.log(this.currentRole);
      if (this.currentRole.role.toLowerCase() == "admin") {
        this.$router.push({
          path: `/${this.$i18n.locale}/${this.$route.params.oid}/admin/invite-member`,
        });
      } else {
        Swal.fire({
          title: "You do not have authorized to delete member",
        });
      }
    },
    updateMemberModule(member) {
      let rolemember = member;
      rolemember.timestamp = firebase.firestore.FieldValue.serverTimestamp();
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("roles")
        .doc(rolemember.id)
        .set(rolemember)
        .then(() => {
          toast.fire({
            title: "Update status successfully",
          });
        });
    },
    updateStatus(organization) {
      this.organization = organization;

      this.organization.timestamp =
        firebase.firestore.FieldValue.serverTimestamp();
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .set(this.organization)
        .then(() => {
          toast.fire({
            title: "Update status successfully",
          });
        });
    },
    deleteTeam(team) {
      if (this.currentRole.role.toLowerCase() == "admin") {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            db.collection("organizations")
              .doc(this.$route.params.oid)
              .collection("roles")
              .doc(team.id)
              .delete();
            toast
              .fire({
                title: "Deleted successfully",
              })
              .then(() => {
                this.$router.go();
              });
          }
        });
      } else {
        Swal.fire({
          title: "You do not have authorized to delete member",
        });
      }
    },
    deleteOrganization(doc) {
      if (this.$store.state.currentuser.role.toLowerCase() == "admin") {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            db.collection("organizations").doc(this.$route.params.oid).delete();

            for (var img in doc.images) {
              fbAuth
                .storage()
                .refFromURL(doc.images[img])
                .delete()
                .then(() => {
                  console.log("image deleted");
                })
                .catch((err) => {
                  console.log(err);
                });
              // console.log(doc.images[img])
            }

            toast
              .fire({
                title: "Deleted successfully",
              })
              .then(() => {
                this.$router.push(`/${this.$i18n.locale}/`);
              });
          }
        });
      } else {
        Swal.fire({
          title: "You do not have authorized to delete member",
        });
      }
    },
    uploadImage(e) {
      let that = this;
      let oid = this.$route.params.oid;

      if (e.target.files) {
        for (let i = 0; i < e.target.files.length; i++) {
          let file = e.target.files[i];
          multiUpload(file);
        }
      }

      function multiUpload(file) {
        let filename = that.rndStr(4);

        var storageRef = fbAuth
          .storage()
          .ref(`organizations/${oid}/imgs/${filename}`);
        var uploadTask = storageRef.put(file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
          },
          (error) => {
            // Handle unsuccessful uploads
            console.log(error);
          },
          () => {
            // Handle successful uploads on complete
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              let images = that.organization.images || [];
              images.push(downloadURL); // Push the new URL to the array
              that.updateStatus(that.organization);
            });
          }
        );
      }
    },
    deleteImage(img, index) {
      let image = fbAuth.storage().refFromURL(img);
      // console.log(image);
      this.organization.images.splice(index, 1);
      image
        .delete()
        .then(() => {
          console.log("Image deleted");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
:root {
  --primary-color: #ff0000;
  --secondary-color: #00ff00;
}
.module-card {
  padding: 10px;
  margin: 1px;
  background-color: white;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.member-card .delete-icon {
  cursor: pointer;
  color: var(--primary-color);
  font-size: 20px;
}

.member-card .delete-icon:hover {
  color: var(--secondary-color);
}
.member-card {
  padding: 10px;
  margin: 10px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
}
.member-card:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.member-card span {
  color: #333;
}
.member-card input[type="checkbox"] {
  min-width: 15px !important;
  margin-right: 5px;
}
.member-card i {
  cursor: pointer;
  color: var(--primary-color);
}

/* Additional styles if needed */
/* Adjust the colors according to your preference */
.member-card .row.m-0 {
  margin: 0;
}
.member-card .row.text-right.m-0 {
  color: var(--primary-color);
}
</style>
