<template>
  <div>
    <div v-if="!mode">
      <div>
        <div class="row col-12 align-items-center">
          <div class="col-9 col-sm-9 col-md-9 col-lg-9">
            <h4>{{ $t("adminPage.entries.title") }}</h4>
            <p>{{ $t("adminPage.entries.text") }}</p>
          </div>
          <div class="col-3 col-sm-3 col-md-3 col-lg-3">
            <img
              src="@/assets/img/events.svg"
              class="img-fluid"
              alt="overview image"
            />
          </div>
        </div>

        <!-- body -->
        <div class="row col-12 justify-content-end">
          <b-form-group
            :label="$t('search.label')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0 col-12 col-lg-10 col-md-10"
          >
            <b-input-group size="sm">
              <b-form-input
                @input="searchNow()"
                v-model="search"
                class="col-12"
                type="search"
                id="filterInput"
                :placeholder="$t('search.input')"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button :disabled="!search" @click="search = ''">{{
                  $t("search.clear")
                }}</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>

        <!-- ./body -->
        <div class="row col-12 mt-2 justify-content-between mx-2">
          <div class="row col-9 col-sm-6 col-lg-6">
            <div class="row justify-content-between" style="max-width: 400px">
              <div>
                <b-pagination
                  class="customPagination col-sm-5"
                  pills
                  v-model="currentPage"
                  :total-rows="entries.length"
                  :per-page="perPage"
                ></b-pagination>
              </div>
              <div class="mx-2">
                <a @click="refreshEntryTable()" class="fas fa-undo fa-2x"> </a>
              </div>
            </div>
          </div>
          <div v-if="enableRole('admin')" class="ml-1">
            <b-button variant="success" @click="createNew">{{
              $t("adminPage.entries.list-button")
            }}</b-button>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-12 p-0 m-1">
        <div class="row m-1 mx-auto p-0 justify-content-center">
          <div
            v-for="(entry, ei) in !search ? entries : searchResults"
            :key="ei"
            class="jpage-card col-lg-5 col-md-5 col-12 mx-auto m-1"
            style="margin: 5px"
          >
            <div class="row p-2">
              <div @click="gotoEntryHome(entry)" class="col-3 col-lg-3 mx-auto">
                <img
                  v-for="image in entry.images"
                  :key="image.id"
                  :src="image"
                  alt="entry images"
                  class="img-fluid mx-auto"
                  style="width: 80px"
                />
              </div>
              <div class="col-3 col-lg-4 col-md-4 p-0">
                <span class="row m-0"> {{ entry.title[$i18n.locale] }}</span>
                <!-- <span class="row text-right m-0">
                Info :{{ entry.info[$i18n.locale] }}
              </span> -->
              </div>
              <div class="col-2 col-lg-2 col-md-2 m-0 p-0">
                <div v-if="enableRole('admin')" class="row mx-auto m-1">
                  <b-button
                    pill
                    size="sm"
                    class="m-1"
                    variant="primary"
                    @click="editEntry(entry)"
                    ><i class="fas p-1 fa-edit"></i
                  ></b-button>
                  <b-button
                    pill
                    size="sm"
                    class="m-1"
                    variant="danger"
                    @click="deleteEntry(entry)"
                    ><i class="fas p-1 fa-trash"></i
                  ></b-button>
                </div>
              </div>
              <div class="col-2 col-lg-2 col-md-2 m-0 p-0">
                <div class="row p-1 justify-content-center">
                  <b-button
                    pill
                    size="sm"
                    variant="danger"
                    class="m-1"
                    @click="paticipates(entry)"
                    ><i class="fas p-1 fa-list"></i
                  ></b-button>
                  <b-button
                    pill
                    size="sm"
                    class="m-1"
                    variant="danger"
                    @click="register(entry)"
                    ><i class="fas p-1 fa-address-card"></i
                  ></b-button>
                  <b-button
                    pill
                    size="sm"
                    class="m-1"
                    variant="primary"
                    @click="copyEntry(entry)"
                    ><i class="fa-solid fa-copy"></i
                  ></b-button>
                </div>
              </div>
              <div class="col-2 col-lg-1 col-md-1 m-0 my-auto">
                <div
                  v-if="enableRole('admin')"
                  class="row m-1 justify-content-center"
                >
                  <b-form-checkbox
                    v-model="entry.status"
                    @change="updateStatus(entry)"
                    name="checkbox-1"
                    value="1"
                    class="m-1"
                    unchecked-value="0"
                  >
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>

          <!-- mode -->
        </div>
      </div>
    </div>
    <div v-else class="mx-1">
      <widget-admin-entry-edit
        v-if="entry && mode"
        :entryX="entry"
        :entries="entries"
        :changeMode="changeMode"
        :updateEntry="updateEntry"
        :mode="mode"
      ></widget-admin-entry-edit>
      <div style="background-color: white"></div>
    </div>
  </div>
</template>
<script>
// Advanced Use - Hook into Quill's API for Custom Functionality

import { fbAuth, db } from "@/configs/firebase";
import firebase from "firebase/compat/app";
import WidgetAdminEntryEdit from "@/components/widgets/WidgetAdminEntryEdit";
import moment from "moment";
import axios from "axios";

export default {
  name: "AdminEntries",
  props: {},
  components: {
    WidgetAdminEntryEdit,
  },
  data() {
    return {
      page: 1,
      perPage: 9,
      currentPage: 1,
      entries: [], //entries
      datajson: [],
      entry: {
        //single entry
        id: "",
        master: null,
        name: "",
        title: { en: "", th: "" },
        info: { en: "", th: "" },
        menus: [],
        downloadUrl: "",
        colorThemes: [],
        menuImage: null,
        type: {
          en: "school",
          th: "โรงเรียน",
        },
        isRequiredRegister: true,
        registerRequired: [],
        questions: [],
        payment: {
          en: "Free",
          th: "ไม่เสียค่าใช้จ่าย",
          price: 0,
          term: { en: "", th: "" },
          images: [],
        },
        databaseFile: "",
        images: [],
        flowNo: 0,
        status: 0,
        isScheduled: false,
        starttime: firebase.firestore.FieldValue.serverTimestamp(),
        endtime: firebase.firestore.FieldValue.serverTimestamp(),
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      },
      activeItem: null, //active selected item
      files: [],
      mode: null,
      loading: false,
      search: "",
      searchResults: [],
    };
  },
  created() {
    // Binding Collections
    this.refreshEntryTable();
  },
  watch: {
    entries() {
      this.setPages();
    },
  },

  methods: {
    enableRole(rolename) {
      return true;
    },
    reset() {
      this.entry = {
        //single entry
        id: "",
        name: "",
        master: null,
        title: { en: "", th: "" },
        info: { en: "", th: "" },
        menus: [],
        downloadUrl: "",
        colorThemes: [],
        menuImage: null,
        type: {
          en: "school",
          th: "โรงเรียน",
        },
        isRequiredRegister: true,
        registerRequired: [],
        questions: [],
        payment: {
          en: "Free",
          th: "ไม่เสียค่าใช้จ่าย",
          price: 0,
          term: { en: "", th: "" },
          images: [],
        },
        databaseFile: "",
        images: [],
        flowNo: 0,
        status: 0,
        isScheduled: false,
        starttime: firebase.firestore.FieldValue.serverTimestamp(),
        endtime: firebase.firestore.FieldValue.serverTimestamp(),
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      };
    },
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(this.entries.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(items) {
      let page = this.currentPage;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return items.slice(from, to);
    },
    rndStr(len) {
      let text = "";
      let chars =
        "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      let ondate = moment(new Date()).format("YYYYMMDD");
      return ondate + text;
    },
    changeMode(mode) {
      this.mode = mode;
    },
    updateEntry(entry) {
      this.entry = entry;
    },
    updateRegistForm(entry, registForm) {
      this.entry = entry;
      this.entry.registerRequired = registForm;
    },
    createNew() {
      this.mode = "new";
      this.reset();
      //this.$refs.edit.show();
    },
    editEntry(entry) {
      this.mode = "edit";
      this.entry = entry;
      //console.log(this.entry);
      this.activeItem = JSON.stringify(entry);
      // if (this.entry !== null) this.$refs.edit.show();
    },
    refreshEntryTable() {
      this.entries = [];
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("entries")
        .onSnapshot((snapshot) => {
          var f = [];
          snapshot.docChanges().forEach((change) => {
            let doc = change.doc;
            //console.log("changed!");
            let getentry = doc.data();
            getentry.id = doc.id;

            let ioldentry = this.entries.findIndex(
              (evt) => evt.id == getentry.id
            );
            if (ioldentry < 0) this.entries.push(getentry);
            else {
              //this.entries = this.entries.sort((a, b) => a.flowNo - b.flowNo);
              this.$set(this.entries, ioldentry, getentry);
            }
          });
          //this.entries = f.sort((a, b) => a.order_no - b.order_no);
        });
    },
    updateStatus(entry) {
      entry.timestamp = firebase.firestore.FieldValue.serverTimestamp();
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("entries")
        .doc(entry.id)
        .update(entry)
        .then(() => {
          this.$refs.edit.hide();
          toast.fire({
            title: "Update status successfully",
          });
        });
    },
    setDataFiletoFirestore() {
      fetch("https://www.groupwing.com/imx/data/studentdata.json")
        .then((response) => {
          //console.lgo(response);
          this.datajson = response.json();
          //console.log(this.datajson);
        })
        .then((data) => console.log(data));
    },
    updateSeq(entry, index, move) {
      if (index + move < this.entries.length && index + move > -1) {
        entry.flowNo = index + move;
        if (move > 0) {
          if (index + 1 < this.entries.length) {
            this.entries.find((p) => p.id == entry.id).flowNo = index + 1;
            this.entries[index + 1].flowNo = index;
            db.collection("organizations")
              .doc(this.$route.params.oid)
              .collection("entries")
              .doc(entry.id)
              .update(entry);
          }
        } else {
          if (index - 1 > -1) {
            this.entries[index - 1].flowNo = index;
            this.entries.find((p) => p.id == entry.id).flowNo = index - 1;
            db.collection("organizations")
              .doc(this.$route.params.oid)
              .collection("entries")
              .doc(entry.id)
              .update(entry);
          }
        }
        //this.refreshTable();
      }
      //console.log(this.entries);
    },
    gotoEntryHome(entry) {
      this.$router.push(
        `/${this.$i18n.locale}/${this.$route.params.oid}/entry/${entry.id}/home`
      );
    },
    paticipates(entry) {
      this.$router.push(
        `/${this.$i18n.locale}/${this.$route.params.oid}/admin/entry/${entry.id}/paticipants`
      );
    },
    gotoEditQuestion(entryId, qId) {
      this.$router.push(
        `/${this.$i18n.locale}/${this.$route.params.oid}/admin/entries/${entryId}/questions/${qId}`
      );
    },
    register(entry) {
      this.$router.push(
        `/${this.$i18n.locale}/${this.$route.params.oid}/entry/${
          entry.id
        }/main/${this.rndStr(4)}`
      );
    },
    refreshTable() {
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("entries")
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            let doc = change.doc;
            ////console.log("changed!");
            let getentry = doc.data();
            getentry.id = doc.id;
            //console.log(getRegister);
            let ioldentry = this.entries.findIndex(
              (evt) => evt.id == getentry.id
            );
            if (ioldentry < 0) this.entries.push(getentry);
            else {
              this.entries = this.entries.sort((a, b) => a.flowNo - b.flowNo);
              this.$set(this.entries, ioldentry, getentry);
            }
            if (change.type === "added" || change.type === "removed") {
              this.$set(this.entries, ioldentry, getentry);
            }
          });
        });
    },
    copyEntry(doc) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to copy this entry!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, create copy it!",
      }).then((result) => {
        var newEntry = doc;
        delete newEntry.id;
        db.collection("organizations")
          .doc(this.$route.params.oid)
          .collection("entries")
          .add(newEntry);
      });
    },
    deleteEntry(doc) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          db.collection("organizations")
            .doc(this.$route.params.oid)
            .collection("entries")
            .doc(doc.id)
            .delete();

          for (var img in doc.images) {
            fbAuth
              .storage()
              .refFromURL(doc.images[img])
              .delete()
              .then(() => {
                console.log("image deleted");
              })
              .catch((err) => {
                console.log(err);
              });
            // console.log(doc.images[img])
          }

          toast.fire({
            title: "Deleted successfully",
          });
        }
      });
    },
    searchNow() {
      this.searchResults = this.entries.filter((entry) => {
        if (
          entry.name.toLowerCase().indexOf(this.search.toLowerCase()) != "-1"
        ) {
          return entry;
        }
      });
    },
  }, // -- end methods -- //
};
</script>
<style scoped>
.img-small-wrap {
  position: relative;
}
.img-small-wrap .delete-img {
  position: absolute;
  top: -14px;
  right: -2px;
  cursor: pointer;
}
</style>
