import { createApp, computed } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./stores/store"; // assuming your store file is named store.js
import { version } from "../package.json";
import '@fortawesome/fontawesome-free/css/all.css'; // Import Font Awesome CSS

import "flatpickr/dist/flatpickr.css";
// Import Bootstrap and BootstrapVue
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import { BootstrapVue3 } from "bootstrap-vue-3";
// i18n
import i18n from "./i18n/i18n";
import Swal from "sweetalert2";

// Attach Swal to the window object
window.Swal = Swal;
const toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
window.toast = toast;

import moment from "moment";
window.moment = moment;

// axios
import axios from "axios";
window.axios = axios;

const app = createApp(App);
app.use(BootstrapVue3);
app.use(router); // Use router in the app
app.use(i18n); // Use i18n in the app
app.use(store); // Use store in the app

// Define fullLang computed property based on the global locale
const fullLang = computed(() => {
  switch (i18n.global.locale.value) {
    case "en":
      return "Eng";
    case "th":
      return "Thai";
    default:
      return i18n.global.locale.value; // Fallback to locale code if not defined
  }
});

// Set up global properties for i18n
app.config.globalProperties.$i18n = {
  get locale() {
    return i18n.global.locale;
  },
  get availableLocales() {
    return i18n.global.availableLocales;
  },
  get fullLang() {
    return fullLang.value;
  },
};
app.config.globalProperties.$setLocale = function (newLocale) {
  const currentLocale = i18n.global.locale; // Get the current locale
  if (newLocale !== currentLocale) {
    i18n.global.locale = newLocale; // Update the locale
    this.$router.push({ params: { lang: newLocale } }); // Update the route
  }
};

app.config.globalProperties.$jpversion = version;
app.mount("#app");

// Dynamic component registration
app.component("fb-login", () => import("./components/logins/FbLogin.vue"));
app.component("google-login", () =>
  import("./components/logins/GoogleLogin.vue")
);
app.component("login-only", () => import("./components/logins/LoginOnly.vue"));
app.component("line-login", () => import("./components/logins/LineLogin.vue"));
