<template>
  <div class="Category">
    <div class="container h-100">
      <div class="intro h-100">
        <div class="row h-100 align-items-center">
          <div class="col-md-8">
            <h1>{{ $t("adminPage.promotions.title") }}</h1>
            <p>{{ $t("adminPage.promotions.text") }}</p>
          </div>
          <div class="col-md-4">
            <img
              src="@/assets/img/promotions.svg"
              class="img-fluid"
              alt="overview image"
            />
          </div>
        </div>

        <!-- body -->
        <div class="mt-2 mb-2 p-1 row justify-content-between">
          <h3 class="d-inline-block">
            {{ $t("adminPage.promotions.list-title") }}
          </h3>
          <button class="btn btn-success p-2 mt-0" @click="createNew">
            {{ $t("adminPage.promotions.list-button") }}
          </button>
        </div>
        <!-- ./body -->

        <div class="row align-items-center">
          <!-- search -->
          <div class="col-md-5">
            <label :for="'filterInput'" class="form-label">
              {{ $t("search.label") }}
            </label>
            <div class="input-group mb-3">
              <input
                type="search"
                class="form-control"
                id="filterInput"
                v-model="search"
                @input="searchNow()"
                :placeholder="$t('search.input')"
              />
              <button
                class="btn btn-outline-secondary"
                :disabled="!search"
                @click="search = ''"
              >
                {{ $t("search.clear") }}
              </button>
            </div>
          </div>
          <!-- ./search -->

          <div class="col-md-5">
            <h5 class="h2-responsive">
              {{ $t("total") }}
              <span class="badge bg-dark">
                {{ !search ? promotions.length : searchResults.length }}
              </span>
            </h5>
          </div>
        </div>

        <div class="table-responsive table-bordered text-center mt-4">
          <table class="table p-0">
            <thead class="table-dark">
              <tr>
                <th scope="col">
                  {{ $t("adminPage.promotions.table.images") }}
                </th>
                <th scope="col">{{ $t("adminPage.promotions.table.name") }}</th>
                <th scope="col">
                  {{ $t("adminPage.promotions.table.title") }}
                </th>
                <th scope="col">
                  {{ $t("adminPage.promotions.table.modify.title") }}
                </th>
                <th scope="col">
                  {{ $t("adminPage.promotions.table.modify.status") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(promotion, index) in !search
                  ? promotions
                  : searchResults"
                :key="index"
              >
                <td class="align-middle">
                  <img
                    v-for="image in promotion.images"
                    :key="image.id"
                    :src="image"
                    alt="promotion images"
                    class="img-fluid"
                    style="width: 80px"
                  />
                </td>
                <td class="align-middle">{{ promotion.name }}</td>
                <td class="align-middle">
                  {{ promotion.title[$i18n.locale] }}
                </td>
                <td class="align-middle">
                  <div class="d-flex justify-content-center">
                    <button
                      class="btn btn-primary btn-sm"
                      @click="editCategory(promotion)"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                    <button
                      class="btn btn-danger btn-sm"
                      @click="deleteCategory(promotion)"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                  <div class="d-flex justify-content-center">
                    <button
                      class="btn btn-danger btn-sm"
                      @click="updateSeq(promotion, index, -1)"
                    >
                      <i class="fas fa-arrow-up"></i>
                    </button>
                    <button
                      class="btn btn-danger btn-sm"
                      @click="updateSeq(promotion, index, 1)"
                    >
                      <i class="fas fa-arrow-down"></i>
                    </button>
                  </div>
                </td>
                <td class="align-middle">
                  <input
                    type="checkbox"
                    v-model="promotion.status"
                    @change="updateStatus(promotion)"
                    class="form-check-input"
                  />
                </td>
              </tr>
              <!-- no search results -->
              <tr v-if="search && !searchResults.length">
                <td colspan="5" class="text-center">
                  <div class="alert alert-warning">
                    {{ $t("search.no-res") }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- table -->

        <!-- modal -->
        <b-modal ref="edit" centered id="promotion" size="xl">
          <template #modal-title>
            <div class="d-flex justify-content-between">
              <div v-if="modal === 'new'" class="pt-2">
                {{ $t("adminPage.promotions.add-promotion.new-title") }}
              </div>
              <div v-if="modal === 'edit'" class="pt-2">
                {{ $t("adminPage.promotions.add-promotion.edit-title") }}
              </div>
              <div>
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="langDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <strong>{{ $i18n.locale }}</strong>
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="langDropdown">
                    <li
                      v-for="(lang, i) in $i18n.availableLocales"
                      :key="`Lang${i}`"
                    >
                      <a class="dropdown-item" @click.prevent="$setLocale(lang)">
                        {{ $i18n.locale }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:modal-body>
            <div class="row">
              <div class="col-lg-8">
                <div class="mb-3">
                  <label class="form-label">
                    {{
                      $t("adminPage.promotions.add-promotion.promotion-name")
                    }}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="promotion.title[$i18n.locale]"
                  />
                </div>
                <div class="input-group mb-4">
                  <span class="input-group-text">
                    <i class="fas fa-pencil-alt"></i>
                  </span>
                  <vue-editor
                    class="form-control"
                    v-model="promotion.info[$i18n.locale]"
                  ></vue-editor>
                </div>
              </div>

              <div class="col">
                <div class="mb-3">
                  <input
                    type="file"
                    class="form-control"
                    @change="uploadImage"
                    accept="image/*"
                    multiple
                  />
                  <label class="form-label">
                    {{ $t("adminPage.promotions.add-promotion.choose") }}
                  </label>
                </div>
                <div class="d-flex pt-2 flex-wrap">
                  <div
                    class="p-1"
                    v-for="(image, index) in promotion.images"
                    :key="index"
                  >
                    <div class="img-small-wrap">
                      <img
                        v-if="image"
                        :src="image"
                        :alt="promotion.title[$i18n.locale]"
                        width="80px"
                      />
                      <span
                        class="delete-img"
                        @click="deleteImage(image, index)"
                      >
                        X
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:modal-footer>
            <button
              class="btn btn-success"
              @click="addCategory"
              v-if="modal == 'new'"
            >
              {{ $t("adminPage.promotions.add-promotion.create") }}
            </button>
            <button
              class="btn btn-success"
              @click="updateCategory()"
              v-if="modal == 'edit'"
            >
              {{ $t("adminPage.promotions.add-promotion.save") }}
            </button>
            <button class="btn btn-danger" @click="$refs.edit.hide()">
              {{ $t("adminPage.promotions.add-promotion.cancel") }}
            </button>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
// Advanced Use - Hook into Quill's API for Custom Functionality

import { VueEditor } from "vue2-editor";
import { fbAuth, db } from "@/configs/firebase";

export default {
  name: "AdminPromotions",
  props: {},
  components: {
    VueEditor,
  },
  data() {
    return {
      promotions: [], //promotions
      promotion: {
        //single promotion
        name: "",
        title: { en: "", th: "" },
        info: { en: "", th: "" },
        images: [],
        flowNo: 0,
        status: 0,
      },
      activeItem: null, //active selected item
      files: [],
      modal: null,
      loading: false,
      search: "",
      searchResults: [],
    };
  },
  computed: {
    // ..
  },
  methods: {
    
    
    uploadImage(e) {
      if (e.target.files) {
        for (let i = 0; i < e.target.files.length; i++) {
          let file = e.target.files[i];
          this.multiUpload(file);
        }
      }
    },

    multiUpload(file) {
      const storageRef = fbAuth
        .storage()
        .ref(
          "organizations/" + this.$route.params.oid + "/promotions/" + file.name
        );
      const uploadTask = storageRef.put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            // var downloadURL = uploadTask.snapshot.ref.downloadURL;
            let images = this.promotion.images || [];
            images.push(downloadURL);
            this.promotion.images = images; // Update the images array
          });
        }
      );
    },
    deleteImage(img, index) {
      let image = fbAuth.storage().refFromURL(img);
      // console.log(image);
      this.promotion.images.splice(index, 1);
      image
        .delete()
        .then(() => {
          console.log("Image deleted");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleChange(v) {
      this.files = v;
    },
    reset() {
      this.promotion = {
        name: "",
        title: { en: "", th: "" },
        info: { en: "", th: "" },
        images: [],
        flowNo: 0,
        status: 0,
      };
    },
    createNew() {
      this.modal = "new";
      this.reset();
      this.$refs.edit.show();
    },
    addCategory() {
      this.promotion.flowNo = this.promotions.length;
      this.$dbpromotions.add(this.promotion);
      this.$refs.edit.hide();
      // .catch(function(error) {
      //     console.error("Error adding document: ", error);
      // });
    },
    editCategory(promotion) {
      this.modal = "edit";
      this.promotion = promotion;
      this.activeItem = JSON.stringify(promotion);
      this.$refs.edit.show();
    },
    updateStatus(promotion) {
      this.$dbpromotions
        .doc(promotion.id)
        .update(promotion)
        .then(() => {
          this.$refs.edit.hide();
          toast.fire({
            title: "Update status successfully",
          });
        });
    },
    updateSeq(promotion, index, move) {
      if (index + move < this.promotions.length && index + move > -1) {
        promotion.flowNo = index + move;
        if (move > 0) {
          if (index + 1 < this.promotions.length) {
            this.promotions.find((p) => p.id == promotion.id).flowNo =
              index + 1;
            this.promotions[index + 1].flowNo = index;
            this.$dbpromotions.doc(promotion.id).update(promotion);
          }
        } else {
          if (index - 1 > -1) {
            this.promotions[index - 1].flowNo = index;
            this.promotions.find((p) => p.id == promotion.id).flowNo =
              index - 1;
            this.$dbpromotions.doc(promotion.id).update(promotion);
          }
        }
        this.refreshTable();
      }
      //console.log(this.promotions);
    },
    refreshTable() {
      this.$binding("promotions", this.$dbpromotions).then((promotions) => {
        this.promotions = promotions.sort((a, b) => a.flowNo - b.flowNo); // => __ob__: Observer
        //console.log(this.promotions);
      });
    },
    updateCategory() {
      // Update function has issues so I have to apply this work-around
      // this.$firestore.promotions.doc(this.promotion['.key']).set(this.promotion).then(() => {
      //   this.$firestore.promotions.doc(this.activeItem).delete()}).then(()=>{
      // this.$refs.edit.hide()
      // toast.fire({
      //     type: 'success',
      //     title: 'Updated successfully'
      // });
      //   });

      this.$dbpromotions
        .doc(this.promotion.id)
        .update(this.promotion)
        .then(() => {
          this.$refs.edit.hide();
          toast.fire({
            title: "Updated successfully",
          });
        });
    },
    deleteCategory(doc) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$dbpromotions.doc(doc.id).delete();

          for (var img in doc.images) {
            fbAuth
              .storage()
              .refFromURL(doc.images[img])
              .delete()
              .then(() => {
                console.log("image deleted");
              })
              .catch((err) => {
                console.log(err);
              });
            // console.log(doc.images[img])
          }

          toast.fire({
            title: "Deleted successfully",
          });
        }
      });
    },
    searchNow() {
      this.searchResults = this.promotions.filter((promotion) => {
        if (
          promotion.title[this.$i18n.locale]
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) != "-1"
        ) {
          return promotion;
        }
      });
    },
  }, // -- end methods -- //
  created() {
    // Binding Collections
    this.$binding("promotions", this.$dbpromotions).then((promotions) => {
      this.promotions = promotions.sort((a, b) => a.flowNo - b.flowNo); // => __ob__: Observer
      //console.log(this.promotions);
    });
  },
};
</script>
<style scoped>
.img-small-wrap {
  position: relative;
}
.img-small-wrap .delete-img {
  position: absolute;
  top: -14px;
  right: -2px;
  cursor: pointer;
}
</style>
