/* eslint-disable indent,object-shorthand,operator-linebreak,object-property-newline,arrow-parens */
import { createStore } from "vuex";
import axios from "axios";

let cart = window.localStorage.getItem("cart");
let currentuser = window.localStorage.getItem("currentuser");
let liffid = window.localStorage.getItem("liffid");
let event = window.localStorage.getItem("event");
let suggestedId = window.localStorage.getItem("suggestedId");
let registerState = window.localStorage.getItem("registerState");
let loginState = window.localStorage.getItem("loginState");
let currentRole = window.localStorage.getItem("currentRole");
let adminTheme = window.localStorage.getItem("adminTheme");
let iddeToken = window.localStorage.getItem("iddeToken");
let organization = window.localStorage.getItem("organization");
let selectedUser = window.localStorage.getItem("selectedUser");
let scanDailyRecord = window.localStorage.getItem("scanDailyRecord");

const store = createStore({
  state: {
    scanDailyRecord: scanDailyRecord ? JSON.parse(scanDailyRecord) : null,
    selectedUser: selectedUser ? JSON.parse(selectedUser) : null,
    cart: cart ? JSON.parse(cart) : [],
    loginState: loginState ? JSON.parse(loginState) : null,
    currentRole: currentRole ? JSON.parse(currentRole) : null,
    adminTheme: adminTheme
      ? JSON.parse(adminTheme)
      : { default: "default", platform: "desktop" },
    registerState: registerState
      ? JSON.parse(registerState)
      : { oid: null, id: null, path: null },
    suggestedId: suggestedId ? JSON.parse(suggestedId) : "",
    iddeToken: iddeToken ? JSON.parse(iddeToken) : "",
    organization: organization ? JSON.parse(organization) : null,
    title: { oid: "", name: "" },

    currentuser: currentuser
      ? JSON.parse(currentuser)
      : {
          id: iddeToken ? iddeToken.id : "",
          name: iddeToken ? iddeToken.name : "",
          displayName: iddeToken ? iddeToken.name : "",
          displayUrl: iddeToken ? iddeToken.picture : "",
          email: iddeToken ? iddeToken.email : "",
          telephone: "",
          company: "",
          memberid: "",
          provider: "",
          isActive: false,
          timestamp: null,
          eventid: "",
        },
    liffid: liffid ?? "",
    event: event ? JSON.parse(event) : null,
    users: [],
    products: [],
    cost: 0.0,
  },
  getters: {
    getLine(state) {
      return state.currentuser;
    },
    totalPrice(state) {
      let total = 0;
      state.cart.forEach((item) => {
        total +=
          (parseFloat(item.productPrice) +
            parseFloat(item.productOptionVarPrice)) *
          item.productQuantity;
      });
      return total;
    },
  },
  actions: {
    listAllUsers({ commit }) {
      axios
        .get("https://us-central1-jinnsolution.cloudfunctions.net/listUsers")
        .then((response) => {
          commit("loadUserData", response.data.users);
        })
        .catch((err) => console.log(err));
    },
  },
  mutations: {
    removeLocalSelectedUser(state) {
      window.localStorage.removeItem("selectedUser");
      state.selectedUser = null;
    },
    removeScanDailyRecord(state) {
      window.localStorage.removeItem("scanDailyRecord");
      state.scanDailyRecord = null;
    },
    saveSelectedUser(state, item) {
      state.selectedUser = item;
      window.localStorage.setItem(
        "selectedUser",
        JSON.stringify(state.selectedUser)
      );
    },
    saveScanDailyRecord(state, item) {
      state.scanDailyRecord = item;
      window.localStorage.setItem(
        "scanDailyRecord",
        JSON.stringify(state.scanDailyRecord)
      );
    },
    saveCurrentRole(state, item) {
      state.currentRole = item;
      window.localStorage.setItem(
        "currentRole",
        JSON.stringify(state.currentRole)
      );
    },
    saveOrganization(state, item) {
      state.organization = item;
      window.localStorage.setItem(
        "organization",
        JSON.stringify(state.organization)
      );
    },
    saveAdminTheme(state, item) {
      state.adminTheme = item;
      window.localStorage.setItem(
        "adminTheme",
        JSON.stringify(state.adminTheme)
      );
    },
    saveRegisterState(state, item) {
      state.registerState = item;
      window.localStorage.setItem(
        "registerState",
        JSON.stringify(state.registerState)
      );
    },
    saveStateBfLogin(state, item) {
      state.loginState = item;
      window.localStorage.setItem(
        "loginState",
        JSON.stringify(state.loginState)
      );
    },
    saveTitle(state, item) {
      state.title = item;
      window.localStorage.setItem("title", JSON.stringify(state.title));
    },
    updateCurrentUser(state, item) {
      state.currentuser = item;
      window.localStorage.setItem(
        "currentuser",
        JSON.stringify(state.currentuser)
      );
    },
    updateLiffId(state, item) {
      state.liffid = item;
      window.localStorage.setItem("liffid", state.liffid);
    },
    updateEvent(state, item) {
      state.event = item;
      window.localStorage.setItem("event", JSON.stringify(state.event));
    },
    addToCart(state, item) {
      const product = state.cart.find(
        (p) =>
          p.productId === item.productId &&
          p.productOption === item.productOption &&
          p.productOptionVarPrice === item.productOptionVarPrice
      );

      if (product) {
        product.productQuantity++;
      } else {
        item.images = [];
        state.cart.push(item);
      }
      this.commit("saveData");
    },
    decreaseQuantity(state, item) {
      const product = state.cart.find(
        (p) =>
          p.productId === item.productId &&
          p.productOption === item.productOption
      );
      if (product && product.productQuantity > 1) {
        product.productQuantity--;
        if (product.images.length > product.productQuantity) {
          product.images.splice(product.images.length - 1, 1);
        }
      }
      this.commit("saveData");
    },
    addImage(state, option) {
      const { item, file } = option;
      const product = state.cart.find(
        (p) =>
          p.productId === item.productId && p.productOption === item.productOption
      );
      if (product) {
        product.images = product.images || [];
        product.images.push(file);
      }
      this.commit("saveData");
    },
    removeImage(state, option) {
      const { item, ix } = option;
      const product = state.cart.find(
        (p) =>
          p.productId === item.productId && p.productOption === item.productOption
      );
      if (product && ix > -1) {
        product.images.splice(ix, 1);
      }
      this.commit("saveData");
    },
    saveData(state) {
      window.localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    saveSuggested(state) {
      window.localStorage.setItem(
        "suggestedId",
        JSON.stringify(state.suggestedId)
      );
    },
    addSuggested(state, suggestedId) {
      state.suggestedId = suggestedId;
    },
    saveCost(state, price) {
      state.cost = price;
    },
    clearCart(state) {
      state.cart = [];
      this.commit("saveData");
    },
    removeData(state, item) {
      const index = state.cart.indexOf(item);
      if (index > -1) {
        state.cart.splice(index, 1);
        this.commit("saveData");
      }
    },
    loadUserData(state, payload) {
      state.users = payload;
    },
  },
});

export default store;
