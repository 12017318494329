<template>
  <div class="jpage-card col-12 m-0 py-1 px-0">
    <b-row class="m-1 mt-1 d-flex justify-content-between">
      <div class="row col-lg-6 col-sm-12 justify-content-between">
        <div>
          <h4 v-if="mode === 'new'" class="p-0">
            {{ $t("adminPage.questions.add-question.new-title") }}
          </h4>
          <h4 v-if="mode === 'edit'" class="p-0">
            {{ $t("adminPage.questions.add-question.edit-title") }}
          </h4>
        </div>
      </div>
    </b-row>
    <b-row class="row g-0 m-0 p-0">
      <b-row class="col-lg-6 col-sm-12 m-0 p-0">
        <b-col class="mx-auto">
          <div>
            <label>
              {{ $t("adminPage.questions.add-question.question-name") }}
            </label>
            <b-input type="text" v-model="quiz.title" />
          </div>
          <div class="row justify-content-between m-2">
            <b-col>
              <label class="mx-auto mt-1 mb-0">{{
                $t("adminPage.questions.answer")
              }}</label>
            </b-col>

            <b-col>
              <template v-slot:append>
                <b-button
                  size="sm"
                  @click="addAnswer"
                  class="pt-1 pb-1 pr-2 pl-2"
                  ><i class="fas fa-plus"></i>
                </b-button>
              </template>
            </b-col>
          </div>
          <div
            v-for="(ans, aindex) in quiz.answers"
            :key="aindex"
            class="row justify-content-between m-0"
          >
            <div class="input-group mb-1">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ $t("adminPage.questions.answer") }}
                </div>
              </div>
              <input type="text" class="form-control" v-model="ans.answer" />
              <div class="input-group-append border" style="width: 120px">
                <input
                  class="mx-1"
                  type="checkbox"
                  id="checkbox"
                  v-model="ans.isRight"
                />
                <label class="mt-2 mx-1" for="checkbox">{{
                  ans.isRight ? "Correct" : "Not Correct"
                }}</label>
              </div>
              <div class="input-group-append">
                <div @click="removeAnswer(aindex)" class="input-group-text">
                  <i class="fas fa-trash"></i>
                </div>
              </div>
            </div>
          </div>
          <vue-editor
            style="width: 100%; text-transform: none !important"
            class="text-normalx"
            v-model="quiz.question"
          ></vue-editor>
        </b-col>
      </b-row>
      <b-row class="col-lg-6 col-sm-12 m-0 p-0">
        <b-col class="mx-auto">
          <b-col>
            <label class="mx-auto mt-1 mb-0">{{ "Solution" }}</label>
          </b-col>
          <vue-editor
            style="width: 100%; text-transform: none !important"
            class="text-normalx"
            v-model="quiz.solution"
          ></vue-editor>
          <div class="input-group mt-2">
            <b-form-file
              ref="fileupload"
              @change="uploadImage"
              class="mb-3"
              accept="image/*"
              multiple
              :placeholder="$t('adminPage.questions.add-question.choose')"
              drop-placeholder="Drop file here..."
            >
              <template v-slot:file-name="{ names }">
                <b-badge variant="dark">{{ names[0] }}</b-badge>
                <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
                  + {{ names.length - 1 }} More files
                </b-badge>
              </template>
            </b-form-file>
          </div>
          <div
            class="form-group d-flex flex-wrap align-content-end align-content-center align-content-start p-1 m-2"
          >
            <div class="p-1" v-for="(image, inx) in quiz.images" :key="inx">
              <div class="img-small-wrap">
                <img
                  v-if="image"
                  :src="image"
                  :alt="quiz.title"
                  width="400px"
                />
                <span class="delete-img" @click="deleteImage(image, inx)"
                  >X</span
                >
                <span class="move-right-img" @click="seqQuizImage(inx, 1)"
                  ><i class="fas fa-arrow-right"></i
                ></span>

                <span class="move-left-img" @click="seqQuizImage(inx, -1)"
                  ><i class="fas fa-arrow-left"></i
                ></span>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-row>
    <b-row class="row g-0 m-0 p-0" align-h="end">
      <b-button
        pill
        size="sm"
        variant="success"
        @click="addQuiz"
        class="mx-2"
        v-if="mode == 'new'"
        >{{ $t("adminPage.questions.add-question.create") }}</b-button
      >
      <b-button
        pill
        size="sm"
        variant="success"
        @click="saveQuiz"
        v-if="mode == 'edit'"
        class="mx-2"
        >{{ $t("adminPage.questions.add-question.save") }}</b-button
      >
      <b-button
        pill
        size="sm"
        variant="danger"
        class="mx-2"
        @click="changeMode(null)"
        >{{ $t("adminPage.questions.add-question.cancel") }}</b-button
      >
    </b-row>
  </div>
</template>
<script>
//let intervalTimer;
import { VueEditor } from "vue2-editor";
import { fbAuth, db } from "@/configs/firebase";
import firebase from "firebase/compat/app";
import moment from "moment";

export default {
  props: {
    quizX: {
      type: Object,
      required: true,
    },
    questions: {
      type: Array,
    },
    events: {
      type: Array,
    },
    shopclasses: {
      type: Array,
    },
    changeMode: {
      type: Function,
    },
    updateQuiz: {
      type: Function,
    },
    mode: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
  },
  components: {
    VueEditor,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months prior
    const minDate = new Date(today);
    minDate.setMonth(minDate.getMonth() - 2);
    minDate.setDate(15);
    // 15th in two months
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth() + 24);
    maxDate.setDate(15);
    return {
      isQuizModule: true,
      selectRegisterOptionId: 0,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
      payTypeOption: [
        {
          en: "Free",
          th: "ไม่เสียค่าใช้จ่าย",
          price: 0,
          term: { en: "", th: "" },
        },
        {
          en: "Register Fee",
          th: "มีค่าใช้จ่าย",
          price: 0,
          term: { en: "", th: "" },
        },
      ],
      quizTypeOption: [
        {
          en: "student",
          th: "นักเรียน",
          disable: [],
          enable: [],
        },
        {
          en: "teacher",
          th: "ครู",
          disable: [],
          enable: [],
        },
        {
          en: "staff",
          th: "ทีมงาน",
          disable: [],
          enable: [],
        },
        {
          en: "officer",
          th: "พนักงาน",
          disable: [],
          enable: [],
        },
        {
          en: "member",
          th: "ระบบสมาชิก",
          disable: [],
          enable: [],
        },
      ],
      qTypeOption: [
        { en: "test", th: "ฝึกทำโจทย์", limitedTime: false },
        { en: "vote", th: "โหวต", limitedTime: true },
        { en: "quiz", th: "คำถาม", limitedTime: true },
        { en: "competition quiz", th: "คำถาม แข่งขัน", limitedTime: true },
      ],
      min: minDate,
      max: maxDate,
      startQuizDate: moment(new Date()).format("YYYY-MM-DD"),
      startQuizTime: moment(new Date()).format("HH:mm:ss"),
      endQuizDate: moment(new Date()).format("YYYY-MM-DD"),
      endQuizTime: moment(new Date()).format("HH:mm:ss"),
      quiz: this.quizX,
    };
  },
  methods: {
    rndStr(len) {
      let text = "";
      let chars =
        "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      let ondate = moment(new Date()).format("YYYYMMDD");
      return ondate + text;
    },
    
    
    gotoEditQuestion(quizid, qId) {
      this.$router.push(
        `/${this.$i18n.locale}/${this.$route.params.oid}/admin/questions/${quizid}/questions/${qId}`
      );
    },
    setQuizType(type) {
      this.quiz.type = type;
      this.updateQuiz(this.quiz);
    },
    removeAnswer(index) {
      this.quiz.answers.splice(index, 1);
      this.updateQuiz(this.quiz);
    },
    addAnswer() {
      this.quiz.answers = this.quiz.answers || [];
      this.quiz.answers.push({
        answer: "",
        isRight: false,
        score: 1,
      });
      this.updateQuiz(this.quiz);
    },
    removeVideo(index) {
      this.quiz.video.splice(index, 1);
      this.updateQuiz(this.quiz);
    },
    remove(item) {
      this.quiz.tags.splice(this.quiz.tags.indexOf(item), 1);
      this.updateQuiz(this.quiz);
    },
    removeMenu(index) {
      this.quiz.menus.splice(index, 1);
      this.updateQuiz(this.quiz);
    },
    addQuiz() {
       db.collection("organizations")
.doc(this.$route.params.oid)
.collection("events")
        .doc(this.$route.params.eventid)
        .collection("questions")
        .doc(this.$route.params.qid)
        .collection("quizes")
        .get()
        .then((snap) => {
          this.quiz.id = snap.size.toString();
           db.collection("organizations")
.doc(this.$route.params.oid)
.collection("events")
            .doc(this.$route.params.eventid)
            .collection("questions")
            .doc(this.$route.params.qid)
            .collection("quizes")
            .add(this.quiz);
        });
      this.changeMode(null);
    },
    saveQuiz() {
       db.collection("organizations")
.doc(this.$route.params.oid)
.collection("events")
        .doc(this.$route.params.eventid)
        .collection("questions")
        .doc(this.$route.params.qid)
        .collection("quizes")
        .doc(this.quiz.id)
        .update(this.quiz)
        .then(() => {
          //this.$refs.edit.hide();
          this.updateQuiz(this.quiz);
          this.changeMode(null);

          toast.fire({
            title: "Updated successfully",
          });
        });
      this.changeMode(null);
    },
    handleChange(v) {
      this.files = v;
    },
    uploadImage(e) {
      let oid = this.$route.params.oid;
      let quizid = this.mode === "edit" ? this.quiz.id : "new-question";

      if (e.target.files) {
        const files = e.target.files; // Get files from the event
        for (let i = 0; i < files.length; i++) {
          let file = files[i];
          this.multiUpload(file, oid, quizid); // Call the external function
        }
      }
    },

    multiUpload(file, oid, quizid) {
      let filename = this.rndStr(4);

      var storageRef = fbAuth
        .storage()
        .ref(`organizations/${oid}/questions/${quizid}/${filename}`);
      var uploadTask = storageRef.put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          // Handle unsuccessful uploads
          console.error(error);
        },
        () => {
          // Handle successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            let images = this.quiz.images || [];
            images.push(downloadURL); // Add the new URL to the images array
            this.quiz.images = images; // Update the quiz images
          });
        }
      );
    },
    deleteImage(img, index) {
      let image = fbAuth.storage().refFromURL(img);
      // console.log(image);
      this.quiz.images.splice(index, 1);
      this.updateQuiz(this.quiz);
      image
        .delete()
        .then(() => {
          console.log("Image deleted");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
* {
  text-transform: none !important;
}
</style>
