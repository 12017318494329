<template>
  <div>
    <div v-if="!mode">
      <div>
        <div class="row col-12 align-items-center">
          <div class="col-9 col-sm-9 col-md-9 col-lg-9">
            <h2>{{ $t("adminPage.products.title") }}</h2>
            <p>{{ $t("adminPage.products.text") }}</p>
          </div>
          <div class="col-3 col-sm-3 col-md-3 col-lg-3">
            <img
              src="@/assets/img/products.svg"
              class="img-fluid"
              alt="overview image"
            />
          </div>
        </div>

        <!-- body -->
        <div class="row col-12 justify-content-end">
          <b-form-group
            :label="$t('search.label')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0 col-12 col-lg-10 col-md-10"
          >
            <b-input-group size="sm">
              <b-form-input
                @input="searchNow()"
                v-model="search"
                class="col-12"
                type="search"
                id="filterInput"
                :placeholder="$t('search.input')"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button :disabled="!search" @click="search = ''">{{
                  $t("search.clear")
                }}</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
        <!-- ./body -->

        <div class="row col-12 mt-2 justify-content-between mx-2">
          <div class="row col-9 col-sm-6 col-lg-6">
            <div class="row justify-content-between" style="max-width: 400px">
              <div>
                <b-pagination
                  class="customPagination col-sm-5"
                  pills
                  v-model="currentPage"
                  :total-rows="products.length"
                  :per-page="perPage"
                ></b-pagination>
              </div>
              <div></div>
              <div class="mx-2">
                <a @click="refreshTable()" class="fas fa-undo fa-2x"> </a>
              </div>
            </div>
          </div>
          <div v-if="enableRole()" class="ml-1">
            <b-button
              class="mx-1"
              variant="success"
              :to="`/${$i18n.locale}/${$route.params.oid}/admin/categories`"
              >{{ "Categories" }}</b-button
            >
            <b-button variant="success" @click="createNew" class="mx-1">{{
              $t("adminPage.products.list-button")
            }}</b-button>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-12 p-0 m-1">
        <div class="row m-1 mx-auto p-0 justify-content-center">
          <div
            v-for="(product, ei) in !search ? products : searchResults"
            :key="ei"
            class="jpage-card col-lg-5 col-md-5 col-12 mx-auto m-1"
            style="margin: 5px"
          >
            <div class="row p-2">
              <div
                @click="gotoProductHome(product)"
                class="row col-3 col-lg-3 mx-auto"
              >
                <div
                  v-for="(image, imgindex) in product.images"
                  :key="imgindex"
                >
                  <img
                    v-if="imgindex == 0"
                    :src="image"
                    alt="product images"
                    class="img-fluid mx-auto"
                    style="width: 80px"
                  />
                </div>
                <div class="row mx-auto">
                  {{ product.price }}
                </div>
              </div>
              <div class="col-3 col-lg-3">
                <span class="row m-0"> {{ product.title[$i18n.locale] }}</span>
              </div>
              <div class="col-2 col-lg-2 m-0 p-1">
                <div v-if="enableRole()" class="row mx-auto m-1">
                  <b-button
                    pill
                    size="sm"
                    class="m-1"
                    variant="primary"
                    @click="editProduct(product)"
                    ><i class="fas p-1 fa-edit"></i
                  ></b-button>
                  <b-button
                    pill
                    size="sm"
                    class="m-1"
                    variant="danger"
                    @click="deleteProduct(product)"
                    ><i class="fas p-1 fa-trash"></i
                  ></b-button>
                  <b-button
                    pill
                    size="sm"
                    class="mx-1"
                    variant="primary"
                    @click="gotoProductHome(product)"
                    ><i class="fas m-1 fa-home"></i
                  ></b-button>
                </div>
              </div>
              <!-- <div class="col-2 col-lg-2 m-0 p-1">
              <div class="row p-1 justify-content-center">
                <b-button
                  pill
                  size="sm"
                  variant="danger"
                  class="m-1"
                  @click="updateSeq(product, index, -1)"
                  ><i class="fas p-1 fa-arrow-up"></i
                ></b-button>
                <b-button
                  pill
                  size="sm"
                  variant="danger"
                  class="m-1"
                  @click="updateSeq(product, index, 1)"
                  ><i class="fas p-1 fa-arrow-down"></i
                ></b-button>
              </div>
            </div> -->
              <div class="col-2 col-lg-2 m-0">
                <div v-if="enableRole()" class="row m-1 justify-content-center">
                  <b-form-checkbox
                    v-model="product.status"
                    @change="updateStatus(product)"
                    name="checkbox-1"
                    value="1"
                    class="mx-1"
                    unchecked-value="0"
                  >
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- modal -->
    </div>
    <div v-else class="mx-1">
      <widget-admin-product-edit
        class="p-1"
        v-if="product && mode && categories.length > 0"
        :productX="product"
        :products="products"
        :categories="categories"
        :changeMode="changeMode"
        :updatePX="updatePX"
        :mode="mode"
      ></widget-admin-product-edit>
      <div style="background-color: white"></div>
    </div>
  </div>
</template>
<script>
// Advanced Use - Hook into Quill's API for Custom Functionality

import moment from "moment";
import { fbAuth, db } from "@/configs/firebase";
import firebase from "firebase/compat/app";
import WidgetAdminProductEdit from "@/components/widgets/WidgetAdminProductEdit";

export default {
  name: "admin-products",
  props: {},
  components: {
    WidgetAdminProductEdit,
  },
  data() {
    return {
      page: 1,
      perPage: 9,
      currentPage: 1,
      pages: [],
      products: [],
      categories: [],
      product: {
        name: "",
        title: { en: "", th: "" },
        model: "",
        categories: [],
        info: { en: "", th: "" },
        price: 0.0,
        imgProduct: true,
        requires: {
          minImageSize: 0.3,
          maxImageSize: 1.0,
          minImages: 1,
          maxImages: 1,
          frontPage: false,
          backPage: false,
        },
        tags: [],
        video: [],
        subs: [],
        subItems: [],
        images: [],
        status: 0,
        instruction: { en: "", th: "" },
        curriers: [],
        weight: { lg: 0, kg: 0 },
        size: {
          h: 0,
          d: 0,
          w: 0,
        },
        flowNo: 0,
        modDate: null,
      },
      activeItem: null, //active selected item
      files: [],
      mode: null,
      modal: null,
      tag: "", // single tag
      category: null,
      item: { name: "", title: "", varPrice: "" }, // single
      loading: false,
      search: "",
      searchResults: [],
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
    };
  },
  created() {
    this.refreshTable();
    this.$binding(
      "categories",
       db.collection("organizations")
.doc(this.$route.params.oid)
.collection("categories")
    ).then((categories) => {
      this.categories = categories; // => __ob__: Observer
      console.log(this.categories);
    });
  },
  computed: {
    displayedProducts() {
      return this.paginate(this.products);
    },
  },
  watch: {
    products() {
      this.setPages();
    },
  },
  methods: {
    enableRole() {
      let moduleindex = this.$store.state.currentRole.enableModules.findIndex(
        (x) => x.name == "products"
      );
      let isActive =
        moduleindex > -1
          ? this.$store.state.currentRole.enableModules[moduleindex].isActive
          : false;
      if (
        this.$store.state.currentRole != null &&
        moduleindex > -1 &&
        isActive
      ) {
        return true;
      }
      return false;
    },
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(this.products.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(products) {
      let page = this.currentPage;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return products.slice(from, to);
    },
    
    
    rndStr(len) {
      let text = "";
      let chars =
        "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      let ondate = moment(new Date()).format("YYYYMMDD");
      return ondate + text;
    },
    addTag() {
      // Checker for null array
      this.product.tags = this.product.tags || [];
      // pushing each tagword
      if (this.product.tags.length <= 5)
        this.product.tags.push(this.tag.slice(0, -1));

      this.tag = "";
      //console.log(this.product.tags);
    },
    addItem() {
      this.product.subs = this.product.subs || [];
      if (this.product.subs.length <= 5) {
        this.product.subs.push({
          title: { en: "", th: "" },
          varPrice: 0.0,
          varPage: 0,
          review: true,
        });
      }
    },
    removesubItems(index) {
      this.product.subs.splice(index, 1);
    },
    remove(item) {
      this.product.tags.splice(this.product.tags.indexOf(item), 1);
    },
    updatePX(px) {
      this.product = px;
    },
    changeMode(mode) {
      this.mode = mode;
    },
    uploadImage(e) {
      const oid = this.$route.params.oid;

      if (this.product.title[this.$i18n.locale] === "") {
        this.$refs.fileupload.value = null;
        return;
      }

      if (e.target.files) {
        for (let i = 0; i < e.target.files.length; i++) {
          let file = e.target.files[i];
          this.multiUpload(file, oid);
        }
      }
    },

    multiUpload(file, oid) {
      const filename = this.rndStr(4);
      const storageRef = fbAuth
        .storage()
        .ref(`organizations/${oid}/products/${this.product.id}/${filename}`);

      const uploadTask = storageRef.put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            let images = this.product.images || [];
            images.push(downloadURL); // Push the new image URL
            this.$set(this.product, "images", images); // Make sure the reactive property is updated
          });
        }
      );
    },
    deleteImage(img, index) {
      let image = fbAuth.storage().refFromURL(img);
      // console.log(image);
      this.product.images.splice(index, 1);
      this.updateProduct();
      image
        .delete()
        .then(() => {
          console.log("Image deleted");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleChange(v) {
      this.files = v;
    },
    reset() {
      this.product = {
        name: "",
        title: { en: "", th: "" },
        model: "",
        categories: [],
        info: { en: "", th: "" },
        price: 0.0,
        imgProduct: true,
        requires: {
          minImageSize: 0.3,
          maxImageSize: 1.0,
          minImages: 1,
          maxImages: 1,
          frontPage: false,
          backPage: false,
        },
        tags: [],
        video: [],
        subs: [],
        subItems: [],
        images: [],
        status: 0,
        instruction: { en: "", th: "" },
        curriers: [],
        weight: { lg: 0, kg: 0 },
        size: {
          h: 0,
          d: 0,
          w: 0,
        },
        flowNo: 0,
        modDate: null,
      };
    },
    createNew() {
      this.mode = "new";
      this.reset();
      this.refreshTable();
      //console.log(this.product);
    },
    addCategory() {
      this.product.categories = this.product.categories || [];
      this.product.categories.push(this.category);
    },
    addProduct() {
      this.product.modDate = firebase.firestore.FieldValue.serverTimestamp();
      this.product.flowNo = this.products.length;
       db.collection("organizations")
.doc(this.$route.params.oid)
.collection("products").add(this.product);
    },
    duplicate(product) {
      var tproduct = product;
      tproduct.modDate = firebase.firestore.FieldValue.serverTimestamp();
      tproduct.flowNo = this.products.length;
      //delete tproduct[id];
       db.collection("organizations")
.doc(this.$route.params.oid)
.collection("products").add(tproduct);
      this.refreshTable();
    },
    editProduct(product) {
      this.mode = "edit";
      this.product = product;
      this.activeItem = JSON.stringify(product);
    },
    updateStatus(product) {
      product.modDate = firebase.firestore.FieldValue.serverTimestamp();
       db.collection("organizations")
.doc(this.$route.params.oid)
.collection("products")
        .doc(product.id)
        .update(product)
        .then(() => {
          this.$refs.edit.hide();
          toast.fire({
            title: "Update status successfully",
          });
        });
    },
    seqProductImage(index, move) {
      let tmp;
      if (index + move > -1 && index + move < this.product.images.length) {
        if (move > 0) {
          tmp = this.product.images[index + 1];
          this.product.images[index + 1] = this.product.images[index];
          this.product.images[index] = tmp;
           db.collection("organizations")
.doc(this.$route.params.oid)
.collection("products")
            .doc(this.product.id)
            .update(this.product);
        } else {
          tmp = this.product.images[index - 1];
          this.product.images[index - 1] = this.product.images[index];
          this.product.images[index] = tmp;
           db.collection("organizations")
.doc(this.$route.params.oid)
.collection("products")
            .doc(this.product.id)
            .update(this.product);
        }
      }
    },
    updateSeq(product, index, move) {
      if (index + move < this.products.length && index + move > -1) {
        product.flowNo = index + move;
        if (move > 0) {
          if (index + 1 < this.products.length) {
            this.products.find((p) => p.id == product.id).flowNo = index + 1;
            this.products[index + 1].flowNo = index;
             db.collection("organizations")
.doc(this.$route.params.oid)
.collection("products")
              .doc(product.id)
              .update(product);
          }
        } else {
          if (index - 1 > -1) {
            this.products[index - 1].flowNo = index;
            this.products.find((p) => p.id == product.id).flowNo = index - 1;
             db.collection("organizations")
.doc(this.$route.params.oid)
.collection("products")
              .doc(product.id)
              .update(product);
          }
        }
        this.refreshTable();
      }
      //console.log(this.products);
    },
    updateProduct() {
       db.collection("organizations")
.doc(this.$route.params.oid)
.collection("products")
        .doc(this.product.id)
        .update(this.product)
        .then(() => {
          //this.refreshTable();
          this.$refs.edit.hide();
          toast.fire({
            title: "Updated successfully",
          });
        });
    },
    gotoProductHome(product) {
      this.$router.push(
        `/${this.$i18n.locale}/${this.$route.params.oid}/p/${
          product.title[this.$i18n.locale]
        }`
      );
    },
    deleteProduct(doc) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
           db.collection("organizations")
.doc(this.$route.params.oid)
.collection("products")
            .doc(doc.id)
            .delete();

          for (var img in doc.images) {
            fbAuth
              .storage()
              .refFromURL(doc.images[img])
              .delete()
              .then(() => {
                console.log("image deleted");
              })
              .catch((err) => {
                console.log(err);
              });
            // console.log(doc.images[img])
          }

          toast
            .fire({
              title: "Deleted successfully",
            })
            .then(() => {
              this.refreshTable();
            });
        }
      });
    },
    refreshTable() {
      // Binding Collections
       db.collection("organizations")
.doc(this.$route.params.oid)
.collection("products").onSnapshot(
        (snapshot) => {
          var f = [];
          snapshot.docChanges().forEach((change) => {
            let doc = change.doc;
            //console.log("changed!");
            let getproduct = doc.data();
            // if (getproduct.title == null) {
            //   getproduct.title = {
            //     en: getproduct.nameLang.en,
            //     th: getproduct.nameLang.th,
            //   };
            //   getproduct.info = {
            //     en: getproduct.infoLang.en,
            //     th: getproduct.infoLang.th,
            //   };
            //   this.product = getproduct;
            //   this.updateProduct();
            // }

            getproduct.id = doc.id;
            let ioldproduct = this.products.findIndex(
              (pro) => pro.id == getproduct.id
            );
            if (ioldproduct < 0) this.products.push(getproduct);
            else {
              this.products = this.products.sort((a, b) => a.flowNo - b.flowNo);
              this.$set(this.products, ioldproduct, getproduct);
            }
          });
        }
      );
    },
    searchNow() {
      this.searchResults = this.products.filter((product) => {
        if (
          product.title[this.$i18n.locale]
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) != "-1"
        ) {
          return product;
        }
      });
    },
  }, // -- end methods -- //
};
</script>
<style scoped>
.img-small-wrap {
  position: relative;
}
.img-small-wrap .delete-img {
  position: absolute;
  top: -14px;
  right: -2px;
  cursor: pointer;
}
.img-small-wrap .move-right-img {
  position: absolute;
  bottom: -14px;
  right: -2px;
  cursor: pointer;
}
.img-small-wrap .move-left-img {
  position: absolute;
  bottom: -14px;
  left: -2px;
  cursor: pointer;
}
</style>
