<template>
  <div class="jpage-card col-12 m-0 py-1 px-0">
    <b-row class="m-1 mt-1 d-flex justify-content-between">
      <div class="row col-lg-6 col-sm-12 justify-content-between">
        <div>
          <h4 v-if="mode === 'new'" class="p-0">
            {{ $t("adminPage.forms.add-form.new-title") }}
          </h4>
          <h4 v-if="mode === 'edit'" class="p-0">
            {{ $t("adminPage.forms.add-form.edit-title") }}
          </h4>
        </div>
        <div class="row">
          <div class="m-1 p-0" style="background-color: white; border: none">
            {{ $i18n.locale }}
          </div>
          <b-dropdown
            size="sm"
            tag="li"
            variant="link"
            no-caret
            style="z-index: 99999"
          >
            <b-dropdown-toggle tag="a" class="m-0 p-0" navLink waves-fixed
              ><strong>{{ $i18n.locale }}</strong></b-dropdown-toggle
            >
            <template #button-content>
              <span class="material-icons"> language </span>
            </template>
            <b-dropdown-item
              @click.prevent="$setLocale(lang)"
              v-for="(lang, i) in $i18n.availableLocales"
              :key="`Lang${i}`"
              :value="lang"
              >{{ $i18n.locale }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>

      <div class="row col-lg-6 col-sm-12 justify-content-end pr-1">
        <div class="input-group-prepend">
          <div v-if="!isFormentryModule" class="row">
            <label class="mt-1" for="name" style="color: red">{{
              $t("adminPage.forms.add-form.master-form")
            }}</label>
            <div class="m-1 p-0 mx-2">
              {{
                formentry.master
                  ? formentry.master.title[$i18n.locale]
                  : $t("adminPage.forms.add-form.no-master-form")
              }}
            </div>
          </div>
          <b-dropdown
            v-if="!isFormentryModule"
            size="sm"
            tag="li"
            variant="link"
            no-caret
          >
            <template #button-content>
              <span class="material-icons md-light md-14"> assignment </span>
            </template>
            <b-dropdown-item
              @click.prevent="setMasterFormentry(msevnt)"
              v-for="(msevnt, msei) in forms"
              :key="'msi_' + msei"
              :value="msevnt"
              ><span v-if="formentry.id != msevnt.id">
                {{ msevnt.title[$i18n.locale] }}</span
              >
              <span v-else
                >{{ $t("adminPage.forms.add-form.no-master-form") }}
              </span></b-dropdown-item
            >
          </b-dropdown>
        </div>
        <div class="input-group-prepend">
          <div>
            <div class="m-1 p-0">
              {{ formentry.payment[$i18n.locale] }}
            </div>
          </div>
          <b-dropdown size="sm" tag="li" variant="link" no-caret>
            <b-dropdown-toggle tag="a" navLink waves-fixed
              ><strong>{{
                formentry.payment[$i18n.locale]
              }}</strong></b-dropdown-toggle
            >
            <template #button-content>
              <span class="material-icons md-light md-14"> paid </span>
            </template>
            <b-dropdown-item
              @click.prevent="setFormentryPaymentType(type)"
              v-for="(type, ptypei) in payTypeOption"
              :key="'ptype_' + ptypei"
              :value="type"
              >{{ type[$i18n.locale] }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
        <div class="input-group-prepend">
          <div class="m-1 p-0">
            {{ formentry.type[$i18n.locale] }}
          </div>
          <b-dropdown size="sm" tag="li" variant="link" no-caret>
            <b-dropdown-toggle tag="a" navLink waves-fixed
              ><strong>{{
                formentry.type[$i18n.locale]
              }}</strong></b-dropdown-toggle
            >
            <template #button-content>
              <span class="material-icons md-14"> formentry </span>
            </template>
            <b-dropdown-item
              @click.prevent="setFormentryType(type)"
              v-for="(type, typei) in formentryTypeOption"
              :key="'type_' + typei"
              :value="type"
              >{{ type[$i18n.locale] }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </b-row>
    <b-row class="row g-0 m-0 p-0">
      <b-row class="col-lg-6 col-sm-12 m-0 p-0">
        <b-col class="mx-auto">
          <div>
            <label class="mt-1" for="name">{{
              $t("adminPage.forms.add-form.form-name")
            }}</label>
            <b-input type="text" v-model="formentry.title[$i18n.locale]" />
          </div>
          <label class="mt-1" for="name">{{
            $t("adminPage.events.add-event.start-time")
          }}</label>
          <div class="input-group">
            <div class="row col-12 mx-1">
              <b-form-datepicker
                class="col-6"
                initial-date="YYYY-MM-DD"
                v-model="startFormDate"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                :min="min"
                :max="max"
                locale="en-US"
              ></b-form-datepicker>
              <b-form-timepicker
                class="col-6"
                v-model="startFormTime"
                :hour12="true"
                :min="min"
                :max="max"
                locale="en-US"
              ></b-form-timepicker>
            </div>
          </div>
          <label class="mt-1" for="name">{{
            $t("adminPage.events.add-event.end-time")
          }}</label>
          <div class="input-group">
            <div class="row col-12 mx-1">
              <b-form-datepicker
                class="col-6"
                v-model="endFormDate"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                :min="min"
                :max="max"
                locale="en"
              ></b-form-datepicker>
              <b-form-timepicker
                class="col-6"
                v-model="endFormTime"
                :hour12="true"
                :min="min"
                :max="max"
                locale="en-US"
              ></b-form-timepicker>
            </div>
          </div>
          <div>
            <label class="mt-1" for="name">{{
              $t("adminPage.forms.add-form.download")
            }}</label>
            <b-input type="text" v-model="formentry.downloadUrl" />
          </div>
          <div>
            <label class="mt-1" for="name">API URL</label>
            <b-input type="text" v-model="formentry.apiUrl" />
          </div>
          <label class="mt-1" for="name">{{
            $t("adminPage.forms.add-form.database-file")
          }}</label>
          <div>
            <b-form-select
              v-model="formentry.databaseFile"
              :options="databaseFileOptions"
            ></b-form-select>
          </div>
          <div class="input-group">
            <b-input
              class="input-group-prepend"
              disabled
              type="text"
              v-model="formentry.databaseFile"
            />
            <div class="input-group-append">
              <i
                @click="gotoEditDatabase(formentry.databaseFile, formentry.id)"
                class="input-group-text fas fa-edit"
              ></i>
            </div>
          </div>
          <!-- <b-row class="col-12 mt-2">
            <span class="mr-2">
              <b-button
                size="sm"
                @click="addMenus()"
                class="pt-1 pb-1 pr-2 pl-2"
                ><i class="fas fa-plus"></i>
              </b-button>
            </span>
          </b-row> -->
          <!-- <label class="mt-1 mb-0">{{
            $t("adminPage.add-entry.n-menu")
          }}</label>
          <div v-for="(menu, mindex) in entry.menus" :key="mindex">
            <b-input
              type="text"
              v-model="entry.menu[mindex].title[$i18n.locale]"
            /> 
          </div>-->

          <div>
            <b-row class="col-12 mt-2">
              <span class="mr-2">
                <b-button
                  size="sm"
                  @click="addQuestion()"
                  class="pt-1 pb-1 pr-2 pl-2"
                  ><i class="fas fa-plus"></i>
                </b-button>
              </span>
              <label class="mt-1 mb-0">{{
                $t("adminPage.forms.add-form.questions")
              }}</label>
            </b-row>
            <!-- <div v-if="formentry.questions.length > 0"> -->
            <div
              v-for="(quiz, qindex) in formentry.questions"
              :key="'question_' + qindex"
              class="row mx-0 p-1 mt-1 mb-2 question"
            >
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    {{ $t("adminPage.forms.add-form.question") }}
                  </div>
                </div>
                <b-input
                  type="text"
                  class="form-control"
                  v-model="formentry.questions[qindex].title[$i18n.locale]"
                />
                <div class="input-group-append">
                  <div
                    v-if="mode == 'edit'"
                    @click="gotoEditQuestion(formentry.id, qindex)"
                    class="input-group-text"
                  >
                    <i class="fas fa fa-id-card"></i>
                  </div>
                  <div @click="removeQuestion(qindex)" class="input-group-text">
                    <i class="fas fa-trash"></i>
                  </div>
                </div>
              </div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    {{ $t("adminPage.forms.add-form.info") }}
                  </div>
                </div>
                <b-textarea
                  type="text"
                  class="form-control"
                  v-model="formentry.questions[qindex].info[$i18n.locale]"
                />
              </div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    {{ $t("adminPage.forms.add-form.min-select") }}
                  </div>
                </div>
                <b-input
                  type="text"
                  class="form-control"
                  v-model="formentry.questions[qindex].minSelect"
                />
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    {{ $t("adminPage.forms.add-form.max-select") }}
                  </div>
                </div>
                <b-input
                  type="text"
                  class="form-control"
                  v-model="formentry.questions[qindex].maxSelect"
                />
              </div>
              <div class="input-group">
                <div class="input-group-text">
                  {{ "ประเภท คำถาม" }}
                </div>
                <div class="input-group-prepend">
                  <div
                    class="input-group-text m-0"
                    style="background-color: white"
                  >
                    {{ formentry.questions[qindex].type[$i18n.locale] }}
                  </div>
                </div>
                <b-dropdown size="sm" tag="li" variant="link" no-caret>
                  <template #button-content>
                    <span class="material-icons"> arrow_drop_down </span>
                  </template>
                  <b-dropdown-item
                    @click.prevent="setQuestionType(type, qindex)"
                    v-for="(type, qi) in qTypeOption"
                    :key="'type_' + qi"
                    :value="type"
                    >{{ type[$i18n.locale] }}</b-dropdown-item
                  >
                </b-dropdown>
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    :aria-checked="formentry.questions[qindex].isScheduled"
                    v-model="formentry.questions[qindex].isScheduled"
                  />
                  <label class="m-1" for="isScheduled">{{
                    " ตั้งกำหนดเวลา "
                  }}</label>
                </div>
                <div
                  v-if="formentry.questions[qindex].isScheduled"
                  class="input-group-text input-group-append"
                >
                  {{ "ระยะเวลา (นาที)" }}
                </div>
                <div class="input-group-text px-1">
                  <b-input
                    v-if="formentry.questions[qindex].isScheduled"
                    type="number"
                    style="width: 70px"
                    class="px-1"
                    @change="convertToEndDate(qindex)"
                    v-model="formentry.questions[qindex].minduration"
                  />
                </div>
              </div>

              <label
                v-if="formentry.questions[qindex].isScheduled"
                class="mt-1 mx-auto"
                for="name"
                >{{ "เวลาเริ่ม " }}</label
              >
              <div
                v-if="formentry.questions[qindex].isScheduled"
                class="input-group"
              >
                <div class="row col-12">
                  <b-form-datepicker
                    format="YYYY-MM-DD"
                    @input="convertToEndDate(qindex)"
                    class="col-6"
                    v-model="formentry.questions[qindex].sdate"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    :min="min"
                    :max="max"
                    locale="en"
                  ></b-form-datepicker>
                  <b-form-timepicker
                    class="col-6"
                    @input="convertToEndDate(qindex)"
                    v-model="formentry.questions[qindex].stime"
                    :hour12="true"
                    :min="min"
                    :max="max"
                    locale="en-US"
                  ></b-form-timepicker>
                </div>
              </div>
            </div>
          </div>
          <label class="mt-1" for="name">{{
            $t("adminPage.forms.add-form.form-details")
          }}</label>
          <div class="form-group" style="width=100%">
            <vue-editor
              style="width: 100%; text-transform: none"
              class="text-normal"
              v-model="formentry.info[$i18n.locale]"
              :editor-toolbar="customToolbar"
            ></vue-editor>
          </div>
          <div class="row mx-0 p-1 justify-content-between">
            <div class="row mx-2">
              <div
                class="p-0 mr-5"
                style="background-color: white; border: none"
              >
                {{
                  registerRequiredOptions[selectRegisterOptionId].value.label[
                    $i18n.locale
                  ]
                }}
              </div>
              <b-dropdown
                id="dropdown-offset"
                size="sm"
                tag="li"
                variant="link"
                offset="25"
                text="Offset Dropdown"
                no-caret
              >
                <template #button-content>
                  <span class="material-icons">
                    keyboard_double_arrow_down
                  </span>
                </template>
                <b-dropdown-item
                  @click="selectRegisterOptionId = ri"
                  v-for="(regist, ri) in registerRequiredOptions"
                  :key="'re_' + ri"
                  :value="regist"
                  >{{ regist.value.label[$i18n.locale] }}</b-dropdown-item
                >
              </b-dropdown>
            </div>
            <div class="row mx-2">
              <span class="m-1">
                <b-button
                  size="sm"
                  @click.prevent="addRegisterRequired(selectRegisterOptionId)"
                  class="pt-1 pb-1 pr-2 pl-2"
                  ><i class="fas fa-plus"></i>
                </b-button>
              </span>
              <label class="mt-1 mb-0">{{
                $t("adminPage.forms.add-form.add-form-required")
              }}</label>
            </div>
          </div>
          <div
            v-for="(regist, rindex) in formentry.registerRequired"
            :key="'ri_' + rindex"
            class="row col-12 mx-0 p-1 mt-1 mb-2 question"
          >
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ "label" }}
                </div>
              </div>
              <b-input
                type="text"
                class="form-control"
                v-model="formentry.registerRequired[rindex].label[$i18n.locale]"
              />
            </div>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ formentry.registerRequired[rindex].type }}
                </div>
              </div>

              <b-dropdown
                id="dropdown-offset"
                size="sm"
                tag="li"
                variant="link"
                offset="25"
                text="Offset Dropdown"
                no-caret
              >
                <template #button-content>
                  <span class="material-icons">
                    keyboard_double_arrow_down
                  </span>
                </template>
                <b-dropdown-item
                  @click.prevent="setRegisterRequiredType(rindex, type)"
                  v-for="(type, ri) in typeRequireField"
                  :key="'type_' + ri"
                  :value="type"
                  >{{ type }}</b-dropdown-item
                >
              </b-dropdown>
              <div class="input-group-append">
                <div class="input-group-text">Prefered</div>
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    v-model="formentry.registerRequired[rindex].isPrefered"
                  />
                </div>
              </div>
              <div class="input-group-append">
                <div class="input-group-text">Required</div>
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    @input="
                      formentry.registerRequired[rindex].isRequired
                        ? (formentry.registerRequired[rindex].isPrefered = true)
                        : (formentry.registerRequired[rindex].isRequired =
                            formentry.registerRequired[rindex].isRequired)
                    "
                    v-model="formentry.registerRequired[rindex].isRequired"
                  />
                </div>
              </div>

              <div class="input-group-append">
                <div
                  @click.prevent="removeRegisterRequired(rindex)"
                  class="input-group-text"
                >
                  <i class="fas fa-trash"></i>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="col-lg-6 col-sm-12 m-0 p-0">
        <b-col class="mx-auto mr-1">
          <b-row class="col-12 my-2">
            <span class="mr-2">
              <b-button
                size="sm"
                @click.prevent="addCustomRequired()"
                class="pt-1 pb-1 pr-2 pl-2"
                ><i class="fas fa-plus"></i>
              </b-button>
            </span>
            <label class="mt-1 mb-0">{{ "Custom" }}</label>
          </b-row>
          <div
            v-for="(regist, cindex) in formentry.customRequired"
            :key="'cIndex_' + cindex"
            class="row col-12 mx-0 p-1 mt-1 mb-2 question"
          >
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ "label" }}
                </div>
              </div>
              <b-input
                type="text"
                class="form-control"
                v-model="formentry.customRequired[cindex].label[$i18n.locale]"
              />
            </div>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">Type</div>
              </div>
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ formentry.customRequired[cindex].type }}
                </div>
              </div>

              <b-dropdown size="sm" tag="li" variant="link" no-caret>
                <template #button-content>
                  <span class="material-icons">
                    keyboard_double_arrow_down
                  </span>
                </template>
                <b-dropdown-item
                  @click.prevent="setCustomRequiredType(cindex, type)"
                  v-for="(type, ci) in typeRequireField"
                  :key="'typeC_' + ci"
                  :value="type"
                  >{{ type }}</b-dropdown-item
                >
              </b-dropdown>
              <div class="input-group-append">
                <div class="input-group-text">Prefered</div>
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    v-model="formentry.customRequired[cindex].isPrefered"
                  />
                </div>
              </div>
              <div class="input-group-append">
                <div class="input-group-text">Required</div>
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    @input="
                      formentry.customRequired[cindex].isRequired
                        ? (formentry.customRequired[cindex].isPrefered = true)
                        : (formentry.customRequired[cindex].isRequired =
                            formentry.customRequired[cindex].isRequired)
                    "
                    v-model="formentry.customRequired[cindex].isRequired"
                  />
                </div>
              </div>
              <div class="input-group-append">
                <div class="input-group-text">Check Dup</div>
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    @input="formentry.customRequired[cindex].checkDup"
                    v-model="formentry.customRequired[cindex].checkDup"
                  />
                </div>
              </div>
              <div class="input-group-prepend">
                <div class="input-group-text">RemoveIfDup</div>
              </div>
              <div
                v-if="
                  formentry.customRequired[cindex].removeThisIfDupField != null
                "
                class="input-group-prepend"
              >
                <div class="input-group-text">
                  {{
                    formentry.customRequired[cindex].removeThisIfDupField.label[
                      $i18n.locale
                    ]
                  }}
                </div>
              </div>
              <b-dropdown
                id="dropdown-offset"
                size="sm"
                tag="li"
                variant="link"
                offset="25"
                text="Offset Dropdown"
                no-caret
              >
                <template #button-content>
                  <span class="material-icons">
                    keyboard_double_arrow_down
                  </span>
                </template>
                <b-dropdown-item
                  @click.prevent="setRemoveIfDupItem(cindex, opdep)"
                  v-for="(opdep, depi) in formentry.customRequired"
                  :key="'opd_' + depi"
                  :value="opdep"
                  >{{ opdep.label[$i18n.locale] }}</b-dropdown-item
                >
                <b-dropdown-item
                  @click.prevent="setRemoveIfDupItem(cindex, null)"
                  value="null"
                  >{{ "---" }}</b-dropdown-item
                >
              </b-dropdown>
              <div class="input-group-prepend">
                <div class="input-group-text">DependItem</div>
              </div>
              <div
                v-if="formentry.customRequired[cindex].isDependItem != null"
                class="input-group-prepend"
              >
                <div class="input-group-text">
                  {{
                    formentry.customRequired[cindex].isDependItem.label[
                      $i18n.locale
                    ]
                  }}
                </div>
              </div>
              <b-dropdown
                id="dropdown-offset"
                size="sm"
                tag="li"
                variant="link"
                offset="25"
                text="Offset Dropdown"
                no-caret
              >
                <template #button-content>
                  <span class="material-icons">
                    keyboard_double_arrow_down
                  </span>
                </template>
                <b-dropdown-item
                  @click.prevent="setDependOnItem(cindex, opdep)"
                  v-for="(opdep, depi) in formentry.customRequired"
                  :key="'depen_' + depi"
                  :value="opdep"
                  >{{ opdep.label[$i18n.locale] }}</b-dropdown-item
                >
                <b-dropdown-item
                  @click.prevent="setDependOnItem(cindex, null)"
                  value="'null'"
                  >{{ "null" }}</b-dropdown-item
                >
              </b-dropdown>
              <div class="input-group-append">
                <div
                  @click.prevent="removeCustomRequired(cindex)"
                  class="input-group-text"
                >
                  <i class="fas fa-trash"></i>
                </div>
                <div
                  @click.prevent="moveUpCustomForm(cindex)"
                  class="input-group-text mx-1"
                >
                  <i class="fa-solid fa-caret-up"></i>
                  <div
                    @click.prevent="moveDownCustomForm(cindex)"
                    class="input-group-text mx-1"
                  >
                    <i class="fa-solid fa-caret-down"></i>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                formentry.customRequired[cindex].type == 'options' ||
                formentry.customRequired[cindex].type == 'qrcode' ||
                formentry.customRequired[cindex].type == 'cost'
              "
            >
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <b-button
                      @click="
                        formentry.customRequired[cindex].typeOptions.push({
                          th: '',
                          en: '',
                        })
                      "
                    >
                      +Option</b-button
                    >
                  </div>
                </div>

                <div
                  v-for="(tyeof, tindex) in formentry.customRequired[cindex]
                    .typeOptions"
                  :key="'custom_' + tindex"
                  class="input-group-append"
                >
                  <div class="input-group-text">{{ tindex + 1 }}</div>
                  <div
                    @click="removeCustomTypeOptions(cindex, tindex)"
                    class="input-group-text"
                  >
                    X
                  </div>
                  <div class="input-group-text">
                    <input
                      type="text"
                      v-model="
                        formentry.customRequired[cindex].typeOptions[tindex][
                          $i18n.locale
                        ]
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="formentry.customRequired[cindex].isDependItem != null">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <b-button
                      @click="
                        formentry.customRequired[cindex].showIfInclude.push({
                          en: '',
                          th: '',
                        })
                      "
                    >
                      +Incld</b-button
                    >
                  </div>
                </div>

                <div
                  v-for="(tyeof, iCindex) in formentry.customRequired[cindex]
                    .showIfInclude"
                  :key="'custom_' + iCindex"
                  class="input-group-append"
                >
                  <div class="input-group-text">{{ iCindex + 1 }}</div>
                  <div
                    @click="removeShowIfIncludes(cindex, iCindex)"
                    class="input-group-text"
                  >
                    X
                  </div>
                  <div class="input-group-text">
                    <input
                      type="text"
                      v-model="
                        formentry.customRequired[cindex].showIfInclude[iCindex][
                          $i18n.locale
                        ]
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="formentry.payment.en != 'Free'" class="form-group">
            <div>
              <label class="mt-1" for="name">{{
                $t("adminPage.forms.add-form.payment-price")
              }}</label>
              <b-input type="text" v-model="formentry.payment.price" />
            </div>
            <div>
              <label class="mt-1" for="name">{{
                $t("adminPage.forms.add-form.payment-term")
              }}</label>
              <b-textarea
                type="text"
                v-model="formentry.payment.term[$i18n.locale]"
              />
            </div>
          </div>
          <b-row class="col-12 my-2">
            <span class="mr-2">
              <b-button
                size="sm"
                @click="addMenus()"
                class="pt-1 pb-1 pr-2 pl-2"
                ><i class="fas fa-plus"></i>
              </b-button>
            </span>
            <label class="mt-1 mb-0">{{
              $t("adminPage.forms.add-form.add-form-menu")
            }}</label>
          </b-row>
          <div
            v-for="(menu, mix) in formentry.menus"
            :key="'mix_' + mix"
            class="row col-12 mx-0 p-1 mt-1 mb-2 question"
          >
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ "title" }}
                </div>
              </div>
              <b-input
                type="text"
                class="form-control"
                v-model="formentry.menus[mix].title[$i18n.locale]"
              />
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ "url" }}
                </div>
              </div>
              <b-input
                type="text"
                class="form-control text-normal"
                v-model="formentry.menus[mix].url"
              />
              <div class="input-group-append">
                <div @click.prevent="removeMenu(mix)" class="input-group-text">
                  <i class="fas fa-trash"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="input-group">
            <b-form-file
              @change="uploadImage"
              class="mb-3"
              accept="image/*"
              multiple
              :placeholder="$t('adminPage.forms.add-form.choose')"
              drop-placeholder="Drop file here..."
            >
              <template v-slot:file-name="{ names }">
                <b-badge variant="dark">{{ names[0] }}</b-badge>
                <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
                  + {{ names.length - 1 }} More files
                </b-badge>
              </template>
            </b-form-file>
          </div>
          <div class="form-group d-flex pt-2">
            <div
              class="p-1"
              v-for="(image, imgindex) in formentry.images"
              :key="'img_' + imgindex"
            >
              <div class="img-small-wrap">
                <img
                  v-if="image"
                  :src="image"
                  :alt="formentry.title[$i18n.locale]"
                  width="80px"
                />
                <span class="delete-img" @click="deleteImage(image, imgindex)"
                  >X</span
                >
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-row>
    <b-row class="row col-12" align-h="end">
      <b-button
        pill
        size="sm"
        variant="success"
        @click="addFormentry"
        class="mx-2"
        v-if="mode == 'new'"
        >{{ $t("adminPage.forms.add-form.create") }}</b-button
      >
      <b-button
        pill
        size="sm"
        variant="success"
        @click="saveFormentry()"
        v-if="mode == 'edit'"
        class="mx-2"
        >{{ $t("adminPage.forms.add-form.save") }}</b-button
      >
      <b-button
        pill
        size="sm"
        variant="danger"
        class="mx-2"
        @click="changeMode(null)"
        >{{ $t("adminPage.forms.add-form.cancel") }}</b-button
      >
    </b-row>
  </div>
</template>
<script>
//let intervalTimer;
import { VueEditor } from "vue2-editor";
import { fbAuth, db } from "@/configs/firebase";
import firebase from "firebase/compat/app";
import moment from "moment";

export default {
  props: {
    formentryX: {
      type: Object,
      required: true,
    },
    forms: {
      type: Array,
    },
    changeMode: {
      type: Function,
    },
    updateFormentry: {
      type: Function,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  components: {
    VueEditor,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months prior
    const minDate = new Date(today);
    minDate.setMonth(minDate.getMonth() - 2);
    minDate.setDate(15);
    // 15th in two months
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth() + 24);
    maxDate.setDate(15);
    return {
      isFormentryModule: true,
      selectRegisterOptionId: 0,
      databaseFileOptions: [
        "students",
        "officers",
        "members",
        "custom1",
        "custom2",
        "custom3",
      ],
      typeRequireField: [
        "text",
        "gender",
        "telephone",
        "email",
        "date",
        "time",
        "link",
        "number",
        "boolean",
        "image_person",
        "image_cash",
        "image_card",
        "consent",
        "section",
        "print_section",
        "qrcode",
        "options",
        "cost",
      ],
      customRequiredOptions: [],
      registerRequiredOptions: [
        {
          id: 0,
          value: {
            field: "name",
            id: 0,
            typeoptions: [],
            isRequired: true,
            isPrefered: true,
            type: "text",
            label: { en: "Name", th: "ชื่อ" },
          },
        },
        {
          id: 1,
          value: {
            field: "email",
            id: 1,
            typeoptions: [],
            isRequired: true,
            isPrefered: true,
            type: "email",
            label: { en: "email", th: "อีเมล์" },
          },
        },
        {
          id: 2,
          value: {
            field: "idcard",
            id: 2,
            typeoptions: [],
            isRequired: true,
            isPrefered: true,
            type: "text",
            label: { en: "id card", th: "บัตรประชาชน" },
          },
        },
        {
          id: 3,
          value: {
            field: "telephone",
            id: 3,
            typeoptions: [],
            isRequired: true,
            isPrefered: true,
            type: "telephone",
            label: { en: "telephone", th: "เบอร์โทร" },
          },
        },
        {
          id: 4,
          value: {
            field: "address",
            id: 4,
            typeoptions: [],
            isRequired: true,
            isPrefered: true,
            type: "text",
            label: { en: "address", th: "ที่อยู่" },
          },
        },
        {
          id: 5,
          value: {
            field: "birthday",
            id: 5,
            typeoptions: [],
            isRequired: true,
            isPrefered: true,
            type: "date",
            label: { en: "birthday", th: "วันเกิด" },
          },
        },
        {
          id: 6,
          value: {
            field: "age",
            id: 6,
            typeoptions: [],
            isRequired: true,
            isPrefered: true,
            type: "number",
            label: { en: "age", th: "อายุ" },
          },
        },
        {
          id: 7,
          value: {
            field: "gender",
            id: 7,
            typeoptions: [],
            isRequired: true,
            isPrefered: true,
            type: "gender",
            label: { en: "gender", th: "เพศ" },
          },
        },
        {
          id: 8,
          value: {
            field: "zipconde",
            id: 8,
            typeoptions: [],
            isRequired: true,
            isPrefered: true,
            type: "text",
            label: { en: "zipcode", th: "รหัสไปรยณีย์" },
          },
        },
        {
          id: 9,
          value: {
            field: "company",
            id: 9,
            typeoptions: [],
            isRequired: true,
            isPrefered: true,
            type: "text",
            label: { en: "company", th: "หน่วยงาน" },
          },
        },
        {
          id: 10,
          value: {
            field: "ig",
            id: 10,
            typeoptions: [],
            isRequired: true,
            isPrefered: true,
            type: "text",
            label: { en: "ig", th: "อินสตาแกรม" },
          },
        },
        {
          id: 11,
          value: {
            field: "lineid",
            id: 11,
            typeoptions: [],
            isRequired: true,
            isPrefered: true,
            type: "text",
            label: { en: "line id", th: "ไอดี ไลน์" },
          },
        },
        {
          id: 12,
          value: {
            field: "facebook",
            id: 12,
            typeoptions: [],
            isRequired: true,
            isPrefered: true,
            type: "text",
            label: { en: "facebook", th: "เฟสบุค" },
          },
        },
        {
          id: 13,
          value: {
            field: "grade",
            id: 13,
            typeoptions: [],
            isRequired: true,
            isPrefered: true,
            type: "text",
            label: { en: "Grade", th: "ระดับชั้น" },
          },
        },
        {
          id: 14,
          value: {
            field: "class",
            id: 14,
            typeoptions: [],
            isRequired: true,
            isPrefered: true,
            type: "text",
            label: { en: "class", th: "ห้อง" },
          },
        },
        {
          id: 15,
          value: {
            field: "memberid",
            id: 15,
            typeoptions: [],
            isRequired: true,
            isPrefered: true,
            type: "text",
            label: { en: "Member Id", th: "เลขสมาชิก" },
          },
        },
        {
          id: 16,
          value: {
            field: "secretcode",
            id: 16,
            typeoptions: [],
            isRequired: true,
            isPrefered: true,
            type: "text",
            label: { en: "Secret Code", th: "รหัสผ่าน" },
          },
        },

        {
          id: 17,
          value: {
            field: "image1",
            id: 17,
            type: "image",
            typeoptions: [],
            isRequired: true,
            isPrefered: true,
            label: { en: "Image #1", th: "รูป #1" },
          },
        },
        {
          id: 18,
          value: {
            field: "image2",
            id: 18,
            typeoptions: [],
            isRequired: true,
            isPrefered: true,
            type: "image_person",
            label: { en: "Image #2", th: "รูป #2" },
          },
        },
        {
          id: 19,
          value: {
            field: "image3",
            id: 19,
            typeoptions: [],
            isRequired: true,
            isPrefered: true,
            type: "image_person",
            label: { en: "Image #3", th: "รูป #3" },
          },
        },
        {
          id: 20,
          value: {
            field: "image4",
            id: 20,
            typeoptions: [],
            isRequired: true,
            isPrefered: true,
            type: "image_person",
            label: { en: "Image #4", th: "รูป #4" },
          },
        },
        {
          id: 21,
          value: {
            field: "image5",
            id: 21,
            typeoptions: [],
            isRequired: true,
            isPrefered: true,
            type: "image_person",
            label: { en: "Image #5", th: "รูป #5" },
          },
        },
        {
          id: 22,
          value: {
            field: "image4",
            id: 22,
            typeoptions: [],
            isRequired: true,
            isPrefered: true,
            type: "image_person",
            label: { en: "Image #6", th: "รูป #6" },
          },
        },
      ],
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
      payTypeOption: [
        {
          en: "Free",
          th: "ไม่เสียค่าใช้จ่าย",
          price: 0,
          term: { en: "", th: "" },
        },
        {
          en: "Register Fee",
          th: "มีค่าใช้จ่าย",
          price: 0,
          term: { en: "", th: "" },
        },
      ],
      formentryTypeOption: [
        {
          en: "student",
          th: "นักเรียน",
          disable: [],
          enable: [],
        },
        {
          en: "teacher",
          th: "ครู",
          disable: [],
          enable: [],
        },
        {
          en: "staff",
          th: "ทีมงาน",
          disable: [],
          enable: [],
        },
        {
          en: "officer",
          th: "พนักงาน",
          disable: [],
          enable: [],
        },
        {
          en: "member",
          th: "ระบบสมาชิก",
          disable: [],
          enable: [],
        },
        {
          en: "member_vote",
          th: "โหวตในระบบสมาชิก",
          disable: [],
          enable: [],
        },
      ],
      qTypeOption: [
        { en: "test", th: "ฝึกทำโจทย์", limitedTime: false },
        { en: "vote", th: "โหวต", limitedTime: true },
        { en: "quiz", th: "คำถาม", limitedTime: true },
        { en: "competition quiz", th: "คำถาม แข่งขัน", limitedTime: true },
      ],
      min: minDate,
      max: maxDate,
      startFormDate: moment(new Date()).format("YYYY-MM-DD"),
      startFormTime: moment(new Date()).format("HH:mm:ss"),
      endFormDate: moment(new Date()).format("YYYY-MM-DD"),
      endFormTime: moment(new Date()).format("HH:mm:ss"),
      formentry: this.formentryX,
    };
  },
  created() {
    //console.log(this.entry.starttime);
    this.setTimeForm();
    this.addNewItemCustomRequirement();
  },
  mounted() {
    //this.addNewItemCustomRequirement();
  },
  methods: {
    addNewItemCustomRequirement() {
      for (let i = 0; i < this.formentry.customRequired.length; i++) {
        if (
          this.formentry.customRequired[i].removeThisIfDupField == undefined
        ) {
          this.formentry.customRequired[i].removeThisIfDupField = null;
        }
        if (this.formentry.customRequired[i].checkDup === undefined) {
          this.formentry.customRequired[i].checkDup = false;
        }
        //this.formentry.customRequired[i][checkDup] = false;
        //this.formentry.customRequired[i][removeThisIfDupField] = null;
      }
      this.updateFormentry(this.formentry);
    },
    setTimeForm() {
      if (this.mode == "edit") {
        let sDate = new Date(this.formentry.starttime.seconds * 1000);
        let stimeoffset = sDate.getTimezoneOffset();
        sDate = new Date(sDate.getTime() - stimeoffset * 60 * 1000);
        this.startFormDate = sDate.toISOString().split("T")[0];
        this.startFormTime = sDate.toISOString().split("T")[1].slice(0, 8);
        let eDate = new Date(this.formentry.endtime.seconds * 1000);
        let etimeoffset = eDate.getTimezoneOffset();
        eDate = new Date(eDate.getTime() - etimeoffset * 60 * 1000);
        this.endFormDate = eDate.toISOString().split("T")[0];
        this.endFormTime = eDate.toISOString().split("T")[1].slice(0, 8);
      } else {
        this.startFormDate = moment(new Date()).format("YYYY-MM-DD");
        this.startFormTime = moment(new Date()).format("HH:mm:ss");
        this.endFormDate = moment(new Date()).format("YYYY-MM-DD");
        this.endFormTime = moment(new Date()).format("HH:mm:ss");
      }
    },
    rndStr(len) {
      let text = "";
      let chars =
        "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return text;
    },
    
    
    convertToEndDate(qindex) {
      // start time
      var sDate = new Date(
        this.formentry.questions[qindex].sdate +
          "T" +
          this.formentry.questions[qindex].stime
      );
      //let stimeoffset = sDate.getTimezoneOffset();
      //sDate = new Date(sDate.getTime() + stimeoffset * 60 * 1000);

      var seconds =
        Math.floor(sDate.getTime() / 1000) +
        this.formentry.questions[qindex].minduration * 60;
      var eDate = new Date(seconds * 1000);

      this.formentry.questions[qindex].starttime =
        firebase.firestore.Timestamp.fromDate(sDate);
      this.formentry.questions[qindex].endtime =
        firebase.firestore.Timestamp.fromDate(eDate);
      //console.log("s date", sDate, " end date", eDate, "current", new Date());
    },
    gotoEditQuestion(formentryId, qId) {
      this.$router.push(
        `/${this.$i18n.locale}/${this.$route.params.oid}/admin/forms/${formentryId}/questions/${qId}`
      );
    },
    setQuestionType(type, index) {
      this.formentry.questions[index].type = type;
      this.updateFormentry(this.formentry);
    },
    setFormentryType(type) {
      this.formentry.type = type;
      this.updateFormentry(this.formentry);
    },
    setMasterFormentry(msformentry) {
      if (msformentry.id == this.formentry.id) {
        this.formentry.master = null;
      } else {
        this.formentry.master = {
          id: msformentry.id,
          title: { en: msformentry.title.en, th: msformentry.title.th },
          images: msformentry.images,
        };
      }
      this.updateFormentry(this.formentry);
    },
    setFormentryPaymentType(type) {
      this.formentry.payment = type;
      this.updateFormentry(this.formentry);
    },
    setDependOnItem(index, item) {
      this.formentry.customRequired[index].isDependItem = item;
      this.updateFormentry(this.formentry);
    },
    setRemoveIfDupItem(index, item) {
      this.formentry.customRequired[index].removeThisIfDupField = item;
      this.updateFormentry(this.formentry);
    },
    setCustomRequiredType(index, type) {
      this.formentry.customRequired[index].type = type;
      this.updateFormentry(this.formentry);
    },
    setRegisterRequiredType(index, type) {
      this.formentry.registerRequired[index].type = type;
      this.updateFormentry(this.formentry);
    },
    addMenus() {
      this.formentry.menus = this.formentry.menus || [];
      this.formentry.menus.push({ title: { en: "", th: "" }, url: "" });
      this.updateFormentry(this.formentry);
    },
    addQuestion() {
      this.formentry.questions = this.formentry.questions || [];
      this.formentry.questions.push({
        name: "",
        title: { en: "", th: "" },
        info: { en: "", th: "" },
        sdate: moment(new Date()).format("YYYY-MM-DD"),
        stime: moment(new Date()).format("HH:mm"),
        minduration: 60,
        starttime: null,
        endtime: null,
        isCompetition: true,
        author: { id: "", name: "", timestamp: "" },
        type: { en: "vote", th: "โหวต", limitedTime: true },
        minSelect: 1,
        maxSelect: 1,
      });
      this.updateFormentry(this.formentry);
    },
    addCustomRequired() {
      this.formentry.customRequired = this.formentry.customRequired || [];
      const rnd = this.rndStr(3);
      this.formentry.customRequired.push({
        field: "custom_" + rnd,
        id: rnd,
        type: this.typeRequireField[0],
        typeOptions: [],
        checkDup: false,
        removeThisIfDupField: null,
        isDependItem: null,
        isRequired: true,
        isPrefered: true,
        showIfInclude: [],
        label: { en: "custom #", th: "custom #" },
      });
      this.formentry.rnd = this.rndStr(3);
      this.updateFormentry(this.formentry);
    },
    arrayMove(arr, fromIndex, toIndex) {
      if (toIndex < arr.length) {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
      }
      return arr;
    },
    moveUpCustomForm(index) {
      this.formentry.customRequired = this.arrayMove(
        this.formentry.customRequired,
        index,
        index - 1
      );
      this.updateFormentry(this.formentry);
    },
    moveDownCustomForm(index) {
      this.formentry.customRequired = this.arrayMove(
        this.formentry.customRequired,
        index,
        index + 1
      );
      this.updateFormentry(this.formentry);
    },
    addRegisterRequired(optionId) {
      //console.log(this.registerRequiredOptions[optionId]);
      this.formentry.registerRequired = this.formentry.registerRequired || [];
      this.formentry.registerRequired.push(
        this.registerRequiredOptions[optionId].value
      );
      //console.log(this.formentry.registerRequired);
      //this.updateRegistForm(this.formentry, this.formentry.registerRequired);
      this.updateFormentry(this.formentry);
    },
    removeQuestion(index) {
      this.formentry.questions.splice(index, 1);
      this.updateFormentry(this.formentry);
    },
    removeRegisterRequired(index) {
      this.formentry.registerRequired.splice(index, 1);
      this.updateFormentry(this.formentry);
    },
    removeCustomRequired(index) {
      this.formentry.customRequired.splice(index, 1);
      this.updateFormentry(this.formentry);
    },

    removeCustomTypeOptions(cindex, index) {
      this.formentry.customRequired[cindex].typeOptions.splice(index, 1);
      this.updateFormentry(this.formentry);
    },
    removeShowIfIncludes(cindex, index) {
      this.formentry.customRequired[cindex].showIfInclude.splice(index, 1);
      this.updateFormentry(this.formentry);
    },
    removeMenu(index) {
      this.formentry.menus.splice(index, 1);
      this.updateFormentry(this.formentry);
    },
    addFormentry() {
       db.collection("organizations")
.doc(this.$route.params.oid)
.collection("forms").add(this.formentry);
      this.updateFormentry(this.formentry);
      this.changeMode(null);
      //this.$refs.edit.hide();
      // .catch(function(error) {
      //     console.error("Error adding document: ", error);
      // });
    },
    saveFormentry() {
      this.formentry.timestamp =
        firebase.firestore.FieldValue.serverTimestamp();
      // start time
      var sDate = new Date(this.startFormDate + "T" + this.startFormTime);
      this.formentry.starttime = firebase.firestore.Timestamp.fromDate(sDate);
      /// end time
      var eDate = new Date(this.endFormDate + "T" + this.endFormTime);
      this.formentry.endtime = firebase.firestore.Timestamp.fromDate(eDate);

       db.collection("organizations")
.doc(this.$route.params.oid)
.collection("forms")
        .doc(this.formentry.id)
        .update(this.formentry)
        .then(() => {
          //this.$refs.edit.hide();
          this.changeMode(null);
          this.updateFormentry(this.formentry);
          toast.fire({
            title: "Updated successfully",
          });
        });
    },
    handleChange(v) {
      this.files = v;
    },
    uploadImage(e) {
      let oid = this.$route.params.oid;
      let formentryid =
        this.mode === "edit" ? this.formentry.id : "new-formentry";

      if (e.target.files) {
        for (let file of e.target.files) {
          this.multiUpload(file, oid, formentryid);
        }
      }
    },

    multiUpload(file, oid, formentryid) {
      let filename = this.rndStr(4);
      let storageRef = fbAuth
        .storage()
        .ref(`organizations/${oid}/forms/${formentryid}/${filename}`);

      let uploadTask = storageRef.put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            let images = this.formentry.images || [];
            images.push(downloadURL); // Push the new URL
            this.formentry.images = images; // Update the images array
          });
        }
      );
    },
    deleteImage(img, index) {
      let image = fbAuth.storage().refFromURL(img);
      // console.log(image);
      this.formentry.images.splice(index, 1);
      this.updateFormentry(this.formentry);
      image
        .delete()
        .then(() => {
          console.log("Image deleted");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    gotoEditDatabase(databaseFile, formentryId) {
      this.$router.push(
        `/${this.$i18n.locale}/${this.$route.params.oid}/admin/database/${databaseFile}?formentryId=${formentryId}`
      );
    },
    // gotoEditQuestion(formentryId, qId) {
    //   this.$router.push(
    //     `/${this.$i18n.locale}/${this.$route.params.oid}/admin/forms/${formentryId}/questions/${qId}`
    //   );
    // },
  },
};
</script>
<style scoped>
.jpage-card {
  font-size: 1em;
  overflow: hidden;
  padding: 5;
  border: none;
  border-radius: 0.28571429rem;
  box-shadow: 0 3px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  margin-top: 20px;
  margin-bottom: 20px;
}
.question {
  background-color: #dbdbec;
}
</style>
