<template>
  <div class="jpage-card col-12 m-0 py-1 px-0">
    <b-row class="m-1 mt-1 d-flex justify-content-between">
      <div class="row col-lg-6 col-sm-12 justify-content-between">
        <div>
          <h4 v-if="mode === 'new'" class="p-0">
            {{ $t("adminPage.products.add-product.new-title") }}
          </h4>
          <h4 v-if="mode === 'edit'" class="p-0">
            {{ $t("adminPage.products.add-product.edit-title") }}
          </h4>
        </div>
        <div class="row">
          <div class="m-1 p-0" style="background-color: white; border: none">
            {{ $i18n.locale }}
          </div>
          <b-dropdown class="ml-3">
            <template v-slot:button-content>
              <strong>{{ $i18n.locale }}</strong>
              <span class="material-icons">language</span>
            </template>
            <b-dropdown-item
              @click.prevent="$setLocale(lang)"
              v-for="(lang, i) in $i18n.availableLocales"
              :key="`Lang${i}`"
              :value="lang"
            >
              {{ $i18n.locale }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </b-row>
    <b-row class="row g-0 m-0 p-0">
      <b-row class="col-lg-6 col-sm-12 m-0 p-0">
        <b-col class="mx-auto">
          <div>
            <label>
              {{ "Idential-Name" }}
            </label>
            <b-input type="text" v-model="product.name" />
          </div>
          <div>
            <label>
              {{ $t("adminPage.products.add-product.product-name") }}
            </label>
            <b-input type="text" v-model="product.title[$i18n.locale]" />
          </div>
          <label>
            {{ $t("adminPage.products.add-product.product-model") }}
          </label>
          <b-input type="text" v-model="product.model" />
          <!-- size -->
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fas fa-box"> </i>
              </div>
            </div>
            <b-input
              type="number"
              class="form-control"
              :placeholder="$t('adminPage.products.add-product.min-image-size')"
              v-model="product.size.w"
            />
            <b-input
              type="number"
              class="form-control"
              :placeholder="$t('adminPage.products.add-product.max-image-size')"
              v-model="product.size.d"
            />
            <b-input
              type="number"
              class="form-control"
              :placeholder="$t('adminPage.products.add-product.max-image-size')"
              v-model="product.size.h"
            />
          </div>
          <!-- weight -->
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="fas fa-weight"> </i>
              </div>
            </div>
            <b-input
              type="number"
              class="form-control"
              :placeholder="$t('adminPage.products.add-product.min-image-size')"
              v-model="product.weight.kg"
            />
            <b-input
              type="number"
              class="form-control"
              :placeholder="$t('adminPage.products.add-product.max-image-size')"
              v-model="product.weight.lb"
            />
          </div>
          <!-- select category -->
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input
                  type="checkbox"
                  id="imageRequire"
                  v-model="product.imgProduct"
                />
              </div>
            </div>
            <b-input
              type="number"
              class="form-control"
              :placeholder="$t('adminPage.products.add-product.min-image-size')"
              v-model="product.requires.minImageSize"
            />
            <b-input
              type="number"
              class="form-control"
              :placeholder="$t('adminPage.products.add-product.max-image-size')"
              v-model="product.requires.maxImageSize"
            />
            <b-input
              type="number"
              class="form-control"
              :placeholder="$t('adminPage.products.add-product.min-image-no')"
              v-model="product.requires.minImages"
            />
            <b-input
              type="number"
              class="form-control"
              :placeholder="$t('adminPage.products.add-product.max-image-no')"
              v-model="product.requires.maxImages"
            />
          </div>

          <b-row v-if="categories.length > 0" class="m-1">
            <b-form-select
              v-model="product.categories"
              @change="console.log(product)"
              multiple
              :select-size="4"
              size="sm"
              class="mt-0"
            >
              <option :value="null" disabled>
                -- Please select an option --
              </option>
              <option
                v-for="option in categories"
                :key="option.title[$i18n.locale]"
                :value="option.title[$i18n.locale]"
              >
                {{ option.title[$i18n.locale] }}
              </option>
            </b-form-select> </b-row
          ><!-- ./select category -->
          <label class="col-12"> {{ "infomation" }}</label>
          <vue-editor
            style="width: 100%"
            class="text-normal"
            v-model="product.info[$i18n.locale]"
            :editor-toolbar="customToolbar"
          ></vue-editor>
          <label class="col-12"> {{ "order instruction" }}</label>
          <vue-editor
            style="width: 100%"
            class="text-normal"
            v-model="product.instruction[$i18n.locale]"
            :editor-toolbar="customToolbar"
          ></vue-editor>
        </b-col>
      </b-row>
      <b-row class="col-lg-6 col-sm-12 m-0 p-0">
        <b-col class="mx-auto">
          <div class="my-1">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ "price" }}
                </div>
              </div>
              <b-input
                :placeholder="
                  $t('adminPage.products.add-product.product-price')
                "
                type="text"
                class="form-control"
                v-model="product.price"
              ></b-input>
              <div class="input-group-append">
                <div class="input-group-text">THB</div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <b-row align-v="center">
              <b-col>
                <label class="mx-auto mt-1 mb-0">{{
                  $t("adminPage.products.add-product.subitems")
                }}</label>
              </b-col>
              <b-col>
                <template v-slot:append>
                  <b-button size="sm" @click="addItem" class="m-1"
                    ><i class="fas fa-plus"></i>
                  </b-button>
                </template>
              </b-col>
            </b-row>

            <div
              class="row col-12 mx-0 p-1 mt-1 mb-2 question"
              v-for="(itx, idxEndSub) in product.subs"
              :key="idxEndSub"
            >
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    {{ $t("adminPage.products.add-product.sub-item") }}
                  </div>
                </div>
                <input
                  type="text"
                  class="form-control"
                  v-model="product.subs[idxEndSub].title[$i18n.locale]"
                />
                <input
                  type="number"
                  class="form-control"
                  v-model="product.subs[idxEndSub].varPrice"
                />
                <input
                  type="number"
                  class="form-control"
                  v-model="product.subs[idxEndSub].varPage"
                />
                <div
                  @click="removesubItems(idxEndSub)"
                  class="input-group-append"
                >
                  <div class="input-group-text">
                    <i class="fas fa-trash"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <b-row align-v="center">
              <b-col>
                <label class="mx-auto mt-1 mb-0">{{
                  $t("adminPage.products.add-product.video-item")
                }}</label>
              </b-col>
              <b-col>
                <template v-slot:append>
                  <b-button
                    size="sm"
                    @click="product.video.push('')"
                    class="m-1"
                    ><i class="fas fa-plus"></i>
                  </b-button>
                </template>
              </b-col>
            </b-row>

            <div>
              <div
                class="input-group mb-3"
                v-for="(vid, ivx) in product.video"
                :key="`vid-${ivx}`"
              >
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    {{ $t("adminPage.products.add-product.video-item") }}
                  </div>
                </div>
                <input
                  type="text"
                  class="form-control"
                  style="text-transform: none !important"
                  v-model="product.video[ivx]"
                />
                <div @click="removeVideo(ivx)" class="input-group-append">
                  <div class="input-group-text">
                    <i class="fas fa-trash"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="input-group">
            <b-form-file
              ref="fileupload"
              @change="uploadImage"
              class="mb-3"
              accept="image/*"
              multiple
              :placeholder="$t('adminPage.products.add-product.choose')"
              drop-placeholder="Drop file here..."
            >
              <template v-slot:file-name="{ names }">
                <b-badge variant="dark">{{ names[0] }}</b-badge>
                <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
                  + {{ names.length - 1 }} More files
                </b-badge>
              </template>
            </b-form-file>
          </div>
          <div
            class="form-group d-flex flex-wrap align-content-end align-content-center align-content-start p-1 m-2"
          >
            <div
              class="p-1"
              v-for="(image, inx) in product.images"
              :key="image.id || inx"
            >
              <div class="img-small-wrap">
                <img
                  v-if="image"
                  :src="image"
                  :alt="product.title[$i18n.locale]"
                  width="80px"
                />
                <span class="delete-img" @click="deleteImage(image, inx)"
                  >X</span
                >
                <span class="move-right-img" @click="seqProductImage(inx, 1)">
                  <i class="fas fa-arrow-right"></i>
                </span>
                <span class="move-left-img" @click="seqProductImage(inx, -1)">
                  <i class="fas fa-arrow-left"></i>
                </span>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-row>
    <b-row class="row g-0 m-0 p-0" align-h="end">
      <b-button
        pill
        size="sm"
        variant="success"
        @click="addProduct"
        class="mx-2"
        v-if="mode == 'new'"
        >{{ $t("adminPage.products.add-product.create") }}</b-button
      >
      <b-button
        pill
        size="sm"
        variant="success"
        @click="saveProduct()"
        v-if="mode == 'edit'"
        class="mx-2"
        >{{ $t("adminPage.products.add-product.save") }}</b-button
      >
      <b-button
        pill
        size="sm"
        variant="danger"
        class="mx-2"
        @click="changeMode(null)"
        >{{ $t("adminPage.products.add-product.cancel") }}</b-button
      >
    </b-row>
  </div>
</template>
<script>
//let intervalTimer;
import { VueEditor } from "vue2-editor";
import { fbAuth, db } from "@/configs/firebase";
import firebase from "firebase/compat/app";
import moment from "moment";

export default {
  props: {
    productX: {
      type: Object,
      required: true,
    },
    products: {
      type: Array,
    },
    categories: {
      type: Array,
    },
    changeMode: {
      type: Function,
    },
    updatePX: {
      type: Function,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  components: {
    VueEditor,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months prior
    const minDate = new Date(today);
    minDate.setMonth(minDate.getMonth() - 2);
    minDate.setDate(15);
    // 15th in two months
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth() + 24);
    maxDate.setDate(15);
    return {
      isProductModule: true,
      selectRegisterOptionId: 0,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
      payTypeOption: [
        {
          en: "Free",
          th: "ไม่เสียค่าใช้จ่าย",
          price: 0,
          term: { en: "", th: "" },
        },
        {
          en: "Register Fee",
          th: "มีค่าใช้จ่าย",
          price: 0,
          term: { en: "", th: "" },
        },
      ],
      productTypeOption: [
        {
          en: "student",
          th: "นักเรียน",
          disable: [],
          enable: [],
        },
        {
          en: "teacher",
          th: "ครู",
          disable: [],
          enable: [],
        },
        {
          en: "staff",
          th: "ทีมงาน",
          disable: [],
          enable: [],
        },
        {
          en: "officer",
          th: "พนักงาน",
          disable: [],
          enable: [],
        },
        {
          en: "member",
          th: "ระบบสมาชิก",
          disable: [],
          enable: [],
        },
      ],
      qTypeOption: [
        { en: "test", th: "ฝึกทำโจทย์", limitedTime: false },
        { en: "vote", th: "โหวต", limitedTime: true },
        { en: "quiz", th: "คำถาม", limitedTime: true },
        { en: "competition quiz", th: "คำถาม แข่งขัน", limitedTime: true },
      ],
      min: minDate,
      max: maxDate,
      startProductDate: moment(new Date()).format("YYYY-MM-DD"),
      startProductTime: moment(new Date()).format("HH:mm:ss"),
      endProductDate: moment(new Date()).format("YYYY-MM-DD"),
      endProductTime: moment(new Date()).format("HH:mm:ss"),
      product: this.productX,
    };
  },
  created() {
    if (this.mode == "edit") {
      if (this.product.starttime == undefined) {
        this.product.starttime =
          firebase.firestore.FieldValue.serverTimestamp();
      }
      if (this.product.endtime == undefined) {
        this.product.endtime = firebase.firestore.FieldValue.serverTimestamp();
      }
      let sDate = new Date(this.product.starttime.seconds * 1000);
      let stimeoffset = sDate.getTimezoneOffset();
      sDate = new Date(sDate.getTime() - stimeoffset * 60 * 1000);
      this.startProductDate = sDate.toISOString().split("T")[0];
      this.startProductTime = sDate.toISOString().split("T")[1].slice(0, 8);
      let eDate = new Date(this.product.endtime.seconds * 1000);
      let etimeoffset = eDate.getTimezoneOffset();
      eDate = new Date(eDate.getTime() - etimeoffset * 60 * 1000);
      this.endProductDate = eDate.toISOString().split("T")[0];
      this.endProductTime = eDate.toISOString().split("T")[1].slice(0, 8);
    } else {
      this.startProductDate = moment(new Date()).format("YYYY-MM-DD");
      this.startProductTime = moment(new Date()).format("HH:mm:ss");
      this.endProductDate = moment(new Date()).format("YYYY-MM-DD");
      this.endProductTime = moment(new Date()).format("HH:mm:ss");
    }
    //console.log(this.product.starttime);
  },
  methods: {
    rndStr(len) {
      let text = "";
      let chars =
        "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      let ondate = moment(new Date()).format("YYYYMMDD");
      return ondate + text;
    },
    
    
    convertToEndDate(qindex) {
      // start time
      var sDate = new Date(
        this.product.questions[qindex].sdate +
          "T" +
          this.product.questions[qindex].stime
      );
      //let stimeoffset = sDate.getTimezoneOffset();
      //sDate = new Date(sDate.getTime() + stimeoffset * 60 * 1000);

      var seconds =
        Math.floor(sDate.getTime() / 1000) +
        this.product.questions[qindex].minduration * 60;
      var eDate = new Date(seconds * 1000);

      this.product.questions[qindex].starttime =
        firebase.firestore.Timestamp.fromDate(sDate);
      this.product.questions[qindex].endtime =
        firebase.firestore.Timestamp.fromDate(eDate);
      //console.log("s date", sDate, " end date", eDate, "current", new Date());
    },
    gotoEditQuestion(productId, qId) {
      this.$router.push(
        `/${this.$i18n.locale}/${this.$route.params.oid}/admin/products/${productId}/questions/${qId}`
      );
    },
    setQuestionType(type, index) {
      this.product.questions[index].type = type;
      this.updatePX(this.product);
    },
    setProductType(type) {
      this.product.type = type;
      this.updatePX(this.product);
    },
    addTag() {
      // Checker for null array
      this.product.tags = this.product.tags || [];
      // pushing each tagword
      if (this.product.tags.length <= 5)
        this.product.tags.push(this.tag.slice(0, -1));

      this.tag = "";
      //console.log(this.product.tags);
      this.updatePX(this.product);
    },
    addItem() {
      this.product.subs = this.product.subs || [];
      if (this.product.subs.length <= 5) {
        this.product.subs.push({
          title: { en: "", th: "" },
          varPrice: 0.0,
          varPage: 0,
          review: true,
        });
      }
      this.updatePX(this.product);
    },
    removesubItems(index) {
      this.product.subs.splice(index, 1);
      this.updatePX(this.product);
    },
    removeVideo(index) {
      this.product.video.splice(index, 1);
      this.updatePX(this.product);
    },

    remove(item) {
      this.product.tags.splice(this.product.tags.indexOf(item), 1);
      this.updatePX(this.product);
    },
    removeMenu(index) {
      this.product.menus.splice(index, 1);
      this.updatePX(this.product);
    },
    addProduct() {
      this.product.flowNo = this.products.length;
       db.collection("organizations")
.doc(this.$route.params.oid)
.collection("products").add(this.product);
      this.updatePX(this.product);
      this.changeMode(null);
      //this.$refs.edit.hide();
      // .catch(function(error) {
      //     console.error("Error adding document: ", error);
      // });
    },
    saveProduct() {
      this.product.timestamp = firebase.firestore.FieldValue.serverTimestamp();
      // start time
      var sDate = new Date(this.startProductDate + "T" + this.startProductTime);
      this.product.starttime = firebase.firestore.Timestamp.fromDate(sDate);
      /// end time
      var eDate = new Date(this.endProductDate + "T" + this.endProductTime);
      this.product.endtime = firebase.firestore.Timestamp.fromDate(eDate);

       db.collection("organizations")
.doc(this.$route.params.oid)
.collection("products")
        .doc(this.product.id)
        .update(this.product)
        .then(() => {
          //this.$refs.edit.hide();
          this.changeMode(null);
          this.updatePX(this.product);
          toast.fire({
            title: "Updated successfully",
          });
        });
    },
    handleChange(v) {
      this.files = v;
    },
    uploadImage(e) {
      let oid = this.$route.params.oid;
      let productid = this.mode === "edit" ? this.product.id : "new-product";

      if (e.target.files) {
        for (let i = 0; i < e.target.files.length; i++) {
          let file = e.target.files[i];
          this.multiUpload(file, oid, productid);
        }
      }
    },

    multiUpload(file, oid, productid) {
      let filename = this.rndStr(4);
      let storageRef = fbAuth
        .storage()
        .ref(`organizations/${oid}/products/${productid}/${filename}`);
      let uploadTask = storageRef.put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            let images = this.product.images || [];
            images.push(downloadURL);
            this.product.images = images; // Update the images array
          });
        }
      );
    },
    deleteImage(img, index) {
      let image = fbAuth.storage().refFromURL(img);
      // console.log(image);
      this.product.images.splice(index, 1);
      this.updatePX(this.product);
      image
        .delete()
        .then(() => {
          console.log("Image deleted");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
