<template>
  <div>
    <div v-if="!mode">
      <div>
        <div class="row col-12 align-items-center">
          <div class="col-9 col-sm-9 col-md-9 col-lg-9">
            <h4>{{ $t("adminPage.forms.title") }}</h4>
            <p>{{ $t("adminPage.forms.text") }}</p>
          </div>
          <div class="col-3 col-sm-3 col-md-3 col-lg-3">
            <img
              src="@/assets/img/events.svg"
              class="img-fluid"
              alt="overview image"
            />
          </div>
        </div>

        <!-- body -->
        <div class="row col-12 justify-content-end">
          <b-form-group
            :label="$t('search.label')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0 col-12 col-lg-10 col-md-10"
          >
            <b-input-group size="sm">
              <b-form-input
                @input="searchNow()"
                v-model="search"
                class="col-12"
                type="search"
                id="filterInput"
                :placeholder="$t('search.input')"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button :disabled="!search" @click="search = ''">{{
                  $t("search.clear")
                }}</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>

        <!-- ./body -->
        <div class="row col-12 mt-2 justify-content-between mx-2">
          <div class="row col-9 col-sm-6 col-lg-6">
            <div class="row justify-content-between" style="max-width: 400px">
              <div>
                <b-pagination
                  class="customPagination col-sm-5"
                  pills
                  v-model="currentPage"
                  :total-rows="formentries.length"
                  :per-page="perPage"
                ></b-pagination>
              </div>
              <div class="mx-2">
                <a @click="refreshFormentryTable()" class="fas fa-undo fa-2x">
                </a>
              </div>
            </div>
          </div>
          <div v-if="enableRole('admin')" class="ml-1">
            <b-button variant="success" @click="createNew">{{
              $t("adminPage.forms.list-button")
            }}</b-button>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-12 p-0 m-1">
        <div class="row m-1 mx-auto p-0 justify-content-center">
          <div
            v-for="(formentry, ei) in !search ? formentries : searchResults"
            :key="ei"
            class="jpage-card col-lg-5 col-md-5 col-12 mx-auto m-1"
            style="margin: 5px"
          >
            <div class="row p-2">
              <div
                @click="gotoFormentryHome(formentry)"
                class="col-3 col-lg-3 mx-1 text-left"
              >
                <img
                  v-for="(image, imgid) in formentry.images"
                  :key="imgid"
                  :src="image"
                  alt="formentry images"
                  class="img-fluid text-center"
                  style="width: 70px"
                />
                <div class="row text-left">
                  <small>{{ formentry.id }}</small>
                </div>
              </div>
              <div class="col-5 col-lg-5 col-md-5 p-0 mt-2">
                <span class="row text-left mx-1">
                  {{ formentry.title[$i18n.locale] }}</span
                >
                <!-- <span class="row text-right m-0">
                Info :{{ entry.info[$i18n.locale] }}
              </span> -->
              </div>
              <div class="col-1 col-lg-1 col-md-1 m-2 p-0">
                <div v-if="enableRole('admin')" class="row mx-auto m-1">
                  <b-button
                    pill
                    size="sm"
                    class="m-1"
                    variant="primary"
                    @click="editFormentry(formentry)"
                    ><i class="fas p-1 fa-edit"></i
                  ></b-button>
                  <b-button
                    pill
                    size="sm"
                    class="m-1"
                    variant="primary"
                    @click="duplicateFormentry(formentry)"
                    ><i class="fas p-1 fa-copy"></i
                  ></b-button>
                  <b-button
                    pill
                    size="sm"
                    class="m-1"
                    variant="danger"
                    @click="deleteFormentry(formentry)"
                    ><i class="fas p-1 fa-trash"></i
                  ></b-button>
                  <!-- <b-button
                  pill
                  size="sm"
                  class="mx-1"
                  variant="danger"
                  @click="gotoFormentryHome(formentry)"
                  ><i class="fas m-1 fa-home"></i
                ></b-button> -->
                </div>
              </div>
              <div class="col-1 col-lg-1 col-md-1 m-2 p-0">
                <div class="row mx-auto m-1">
                  <b-button
                    pill
                    size="sm"
                    variant="danger"
                    class="m-1"
                    @click="paticipates(formentry)"
                    ><i class="fas p-1 fa-list"></i
                  ></b-button>
                  <b-button
                    pill
                    size="sm"
                    class="m-1"
                    variant="danger"
                    @click="register(formentry)"
                    ><i class="fas p-1 fa-address-card"></i
                  ></b-button>
                  <!-- <b-button
                    pill
                    size="sm"
                    variant="danger"
                    class="m-1"
                    @click="setDataFiletoFirestore()"
                    ><i class="fas p-1 fa-download"></i
                  ></b-button> -->
                </div>
              </div>
              <!-- <div class="col-2 col-lg-1 col-md-1 m-0 my-auto">
                <div v-if="enableRole()" class="row m-1 justify-content-center">
                  <b-form-checkbox
                    v-model="formentry.status"
                    @change="updateStatus(formentry)"
                    name="checkbox-1"
                    value="1"
                    class="m-1"
                    unchecked-value="0"
                  >
                  </b-form-checkbox>
                </div>
              </div> -->
            </div>
          </div>

          <!-- mode -->
        </div>
      </div>
    </div>
    <div v-else class="mx-1">
      <widget-admin-formentry-edit
        v-if="formentry && mode"
        :formentryX="formentry"
        :formentries="formentries"
        :changeMode="changeMode"
        :updateFormentry="updateFormentry"
        :mode="mode"
      ></widget-admin-formentry-edit>
      <div style="background-color: white"></div>
    </div>
  </div>
</template>
<script>
// Advanced Use - Hook into Quill's API for Custom Functionality

import { fbAuth, db } from "@/configs/firebase";
import firebase from "firebase/compat/app";
import WidgetAdminFormentryEdit from "@/components/widgets/WidgetAdminFormEdit";
import moment from "moment";
import axios from "axios";

export default {
  name: "form-entries",
  props: {},
  components: {
    WidgetAdminFormentryEdit,
  },
  data() {
    return {
      page: 1,
      perPage: 9,
      currentPage: 1,
      formentries: [], //formentries
      datajson: [],
      formentry: {
        //single formentry
        id: "",
        master: null,
        name: "",
        title: { en: "", th: "" },
        info: { en: "", th: "" },
        menus: [],
        downloadUrl: "",
        apiUrl: "",
        apiMapUrl: null,
        menuImage: null,
        type: {
          en: "school",
          th: "โรงเรียน",
          disable: [],
          enable: [],
        },
        isRequiredRegister: true,
        customRequired: [],
        registerRequired: [],
        questions: [],
        payment: {
          en: "Free",
          th: "ไม่เสียค่าใช้จ่าย",
          price: 0,
          term: { en: "", th: "" },
          images: [],
        },
        databaseFile: "",
        images: [],
        flowNo: 0,
        status: 0,
        isScheduled: false,
        starttime: firebase.firestore.FieldValue.serverTimestamp(),
        endtime: firebase.firestore.FieldValue.serverTimestamp(),
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      },
      activeItem: null, //active selected item
      files: [],
      mode: null,
      loading: false,
      search: "",
      searchResults: [],
    };
  },
  created() {
    // Binding Collections
    this.refreshFormentryTable();
  },
  watch: {
    formentries() {
      this.setPages();
    },
  },

  methods: {
    enableRole(rolename) {
      let moduleindex = this.$store.state.currentRole.enableModules.findIndex(
        (x) => x.name.toLowerCase() == "forms"
      );
      let isActive =
        moduleindex > -1
          ? this.$store.state.currentRole.enableModules[moduleindex].isActive
          : false;
      if (
        this.$store.state.currentRole != null &&
        moduleindex > -1 &&
        isActive
      ) {
        if (rolename != null) {
          if (this.$store.state.currentRole.role.toLowerCase() == rolename) {
            return true;
          } else return false;
        }
        return true;
      }
      return false;
    },
    reset() {
      this.formentry = {
        //single formentry
        id: "",
        name: "",
        master: null,
        title: { en: "", th: "" },
        info: { en: "", th: "" },
        menus: [],
        downloadUrl: "",
        apiUrl: "",
        apiMapUrl: null,
        menuImage: null,
        type: {
          en: "school",
          th: "โรงเรียน",
          disable: [],
          enable: [],
        },
        isRequiredRegister: true,
        registerRequired: [],
        questions: [],
        payment: {
          en: "Free",
          th: "ไม่เสียค่าใช้จ่าย",
          price: 0,
          term: { en: "", th: "" },
          images: [],
        },
        databaseFile: "",
        images: [],
        flowNo: 0,
        status: 0,
        isScheduled: false,
        starttime: firebase.firestore.FieldValue.serverTimestamp(),
        endtime: firebase.firestore.FieldValue.serverTimestamp(),
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      };
    },
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(this.formentries.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(items) {
      let page = this.currentPage;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return items.slice(from, to);
    },
    rndStr(len) {
      let text = "";
      let chars =
        "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      let ondate = moment(new Date()).format("YYYYMMDD");
      return ondate + text;
    },
    changeMode(mode) {
      this.mode = mode;
    },
    updateFormentry(formentry) {
      this.formentry = formentry;
      //console.log(this.formentry);
    },
    updateRegistForm(formentry, registForm) {
      this.formentry = formentry;
      this.formentry.registerRequired = registForm;
    },
    createNew() {
      this.mode = "new";
      this.reset();
      //this.$refs.edit.show();
    },
    editFormentry(formentry) {
      this.mode = "edit";
      this.formentry = formentry;
      //console.log(this.formentry);
      this.activeItem = JSON.stringify(formentry);
      // if (this.formentry !== null) this.$refs.edit.show();
    },
    duplicateFormentry(formentry) {
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("forms")
        .add(formentry)
        .then(() => {
          this.refreshFormentryTable();
          toast.fire({
            title: "Duplicate form successfully",
          });
        });
    },
    refreshFormentryTable() {
      this.formentries = [];
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("forms")
        .onSnapshot((snapshot) => {
          var f = [];
          snapshot.docChanges().forEach((change) => {
            let doc = change.doc;
            //console.log("changed!");
            let getformentry = doc.data();
            getformentry.id = doc.id;

            let ioldformentry = this.formentries.findIndex(
              (evt) => evt.id == getformentry.id
            );
            if (ioldformentry < 0) this.formentries.push(getformentry);
            else {
              //this.formentries = this.formentries.sort((a, b) => a.flowNo - b.flowNo);
              this.$set(this.formentries, ioldformentry, getformentry);
            }
          });
          //this.formentries = f.sort((a, b) => a.order_no - b.order_no);
        });
    },
    updateStatus(formentry) {
      formentry.timestamp = firebase.firestore.FieldValue.serverTimestamp();
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("forms")
        .doc(formentry.id)
        .update(formentry)
        .then(() => {
          this.$refs.edit.hide();
          toast.fire({
            title: "Update status successfully",
          });
        });
    },
    setDataFiletoFirestore() {
      fetch("https://www.groupwing.com/imx/data/studentdata.json")
        .then((response) => {
          //console.lgo(response);
          this.datajson = response.json();
          //console.log(this.datajson);
        })
        .then((data) => console.log(data));
    },
    updateSeq(formentry, index, move) {
      if (index + move < this.formentries.length && index + move > -1) {
        formentry.flowNo = index + move;
        if (move > 0) {
          if (index + 1 < this.formentries.length) {
            this.formentries.find((p) => p.id == formentry.id).flowNo =
              index + 1;
            this.formentries[index + 1].flowNo = index;
            db.collection("organizations")
              .doc(this.$route.params.oid)
              .collection("forms")
              .doc(formentry.id)
              .update(formentry);
          }
        } else {
          if (index - 1 > -1) {
            this.formentries[index - 1].flowNo = index;
            this.formentries.find((p) => p.id == formentry.id).flowNo =
              index - 1;
            db.collection("organizations")
              .doc(this.$route.params.oid)
              .collection("forms")
              .doc(formentry.id)
              .update(formentry);
          }
        }
        //this.refreshTable();
      }
      //console.log(this.formentries);
    },
    gotoFormentryHome(formentry) {
      this.$router.push(
        `/${this.$i18n.locale}/${this.$route.params.oid}/form/${formentry.id}/home`
      );
    },
    paticipates(formentry) {
      this.$router.push(
        `/${this.$i18n.locale}/${this.$route.params.oid}/admin/form/${formentry.id}/paticipants`
      );
    },
    gotoEditQuestion(formentryId, qId) {
      this.$router.push(
        `/${this.$i18n.locale}/${this.$route.params.oid}/admin/form/${formentryId}/questions/${qId}`
      );
    },
    register(formentry) {
      this.$router.push(
        `/${this.$i18n.locale}/${this.$route.params.oid}/form/${
          formentry.id
        }/register/${this.rndStr(4)}`
      );
    },
    refreshTable() {
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("forms")
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            let doc = change.doc;
            ////console.log("changed!");
            let getformentry = doc.data();
            getformentry.id = doc.id;
            //console.log(getRegister);
            let ioldformentry = this.formentries.findIndex(
              (evt) => evt.id == getformentry.id
            );
            if (ioldformentry < 0) this.formentries.push(getformentry);
            else {
              this.formentries = this.formentries.sort(
                (a, b) => a.flowNo - b.flowNo
              );
              this.$set(this.formentries, ioldformentry, getformentry);
            }
            if (change.type === "added" || change.type === "removed") {
              this.$set(this.formentries, ioldformentry, getformentry);
            }
          });
        });
    },

    deleteFormentry(doc) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          db.collection("organizations")
            .doc(this.$route.params.oid)
            .collection("forms")
            .doc(doc.id)
            .delete();

          for (var img in doc.images) {
            fbAuth
              .storage()
              .refFromURL(doc.images[img])
              .delete()
              .then(() => {
                console.log("image deleted");
              })
              .catch((err) => {
                console.log(err);
              });
            // console.log(doc.images[img])
          }

          toast.fire({
            title: "Deleted successfully",
          });
        }
      });
    },
    searchNow() {
      this.searchResults = this.formentries.filter((formentry) => {
        if (
          formentry.name.toLowerCase().indexOf(this.search.toLowerCase()) !=
          "-1"
        ) {
          return formentry;
        }
      });
    },
  }, // -- end methods -- //
};
</script>
<style scoped>
.img-small-wrap {
  position: relative;
}
.img-small-wrap .delete-img {
  position: absolute;
  top: -14px;
  right: -2px;
  cursor: pointer;
}
</style>
