<template>
  <div class="news">
    <div v-if="!mode">
      <div class="row mx-0">
        <div class="row col-12 align-items-center">
          <div class="col-9 col-sm-9 col-md-9 col-lg-9">
            <h2>{{ $t("adminPage.news.title") }}</h2>
            <p>{{ $t("adminPage.news.text") }}</p>
          </div>
          <div
            @click="gotoShopClasses()"
            class="col-3 col-sm-3 col-md-3 col-lg-3"
          >
            <img
              src="@/assets/img/exhibitors.svg"
              class="img-fluid"
              alt="overview image"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="row mt-1 mx-0 justify-content-end">
            <i class="fas fa-home m-1" @click="gotoExhibitor()"
              ><span class="m-1">{{ "Home" }}</span></i
            >
            <i class="fas fa-list m-1" @click="gotoExhibitorList()"
              ><span class="m-1">{{ "List" }}</span></i
            >
            <i class="fas fa-list-alt m-1" @click="gotoShopClasses()"
              ><span class="m-1">{{ "Category" }}</span></i
            >
          </div>
        </div>
        <div class="row col-12 justify-content-end">
          <!-- search -->
          <b-form-group
            :label="$t('search.label')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0 col-12 col-lg-10 col-md-10"
          >
            <b-input-group size="sm">
              <b-form-input
                @input="searchNow()"
                v-model="search"
                class="col-12"
                type="search"
                id="filterInput"
                :placeholder="$t('search.input')"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button :disabled="!search" @click="search = ''">{{
                  $t("search.clear")
                }}</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>

        <div class="row col-12 mt-2 mx-1 justify-content-between">
          <div class="m-2">
            <div class="row justify-content-between" style="max-width: 400px">
              <div>
                <b-pagination
                  class="customPagination col-9"
                  pills
                  v-model="currentPage"
                  :total-rows="news.length"
                  :per-page="perPage"
                ></b-pagination>
              </div>
              <div>
                <a class="fas fa-undo fa-2x"> </a>
              </div>
            </div>
          </div>
          <div v-if="enableRole()" class="ml-2 my-2">
            <b-button variant="success" @click="createNew">{{
              $t("adminPage.news.list-button")
            }}</b-button>
          </div>
        </div>
      </div>
      <!-- table -->
      <div class="col-lg-12 col-md-12 col-12 p-0 m-1">
        <div class="row m-1 mx-auto">
          <div
            v-for="(vnews, bi) in !search ? newsList : searchResults"
            :key="bi"
            class="jpage-card col-lg-5 col-md-5 col-12 mx-auto"
            style="margin: 5px"
          >
            <div class="row p-2">
              <div class="col-3 col-lg-3 mx-auto">
                <img
                  v-for="image in vnews.images"
                  :key="image.id"
                  :src="image"
                  alt="news images"
                  class="img-fluid mx-auto"
                  style="width: 80px"
                />
                <div class="row mx-auto"></div>
              </div>
              <div class="col-3 col-lg-3">
                <span class="row m-0"> {{ vnews.title[$i18n.locale] }}</span>
              </div>
              <div v-if="enableRole()" class="col-4 col-lg-2 m-0 p-1">
                <div class="row mx-auto m-1">
                  <b-button
                    pill
                    size="sm"
                    class="m-1"
                    variant="primary"
                    @click="editExhibitor(vnews)"
                    ><i class="fas p-1 fa-edit"></i
                  ></b-button>
                  <b-button
                    pill
                    size="sm"
                    class="m-1"
                    variant="danger"
                    @click="deleteExhibitor(vnews)"
                    ><i class="fas p-1 fa-trash"></i
                  ></b-button>
                </div>
              </div>
              <!-- <div class="col-2 col-lg-2 m-0 p-1">
              <div class="row p-1 justify-content-center">
                <b-row class="mx-auto">
                  <b-button
                    pill
                    size="sm"
                    variant="danger"
                    @click="updateSeq(vnews, index, -1)"
                    ><i class="fas fa-arrow-up"></i
                  ></b-button>
                  <b-button
                    pill
                    size="sm"
                    variant="danger"
                    @click="updateSeq(vnews, index, 1)"
                    ><i class="fas fa-arrow-down"></i
                  ></b-button>
                </b-row>
              </div>
            </div> -->
              <div v-if="enableRole()" class="col-2 col-lg-2 m-0">
                <div class="row m-1 justify-content-center">
                  <b-form-checkbox
                    v-model="vnews.isActive"
                    @change="updateStatus(vnews)"
                    name="checkbox-1"
                    value="true"
                    class="mx-1 my-auto"
                    unchecked-value="false"
                  >
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mx-1">
      <widget-admin-news-edit
        class="p-1"
        v-if="news && mode"
        :newsX="news"
        :newsList="newsList"
        :events="events"
        :shopclasses="shopclasses"
        :changeMode="changeMode"
        :updatePX="updatePX"
        :mode="mode"
      ></widget-admin-news-edit>
      <div style="background-color: white"></div>
    </div>
  </div>
</template>
<script>
// Advanced Use - Hook into Quill's API for Custom Functionality

import moment from "moment";
import { fbAuth, db } from "@/configs/firebase";
import WidgetAdminNewsEdit from "@/components/widgets/WidgetAdminNewsEdit";

import firebase from "firebase/compat/app";
export default {
  name: "admin-news",
  props: {},
  components: {
    WidgetAdminNewsEdit,
  },
  data() {
    return {
      page: 1,
      perPage: 9,
      currentPage: 1,
      pages: [],
      newsList: [],
      events: [],
      mode: null,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
      news: {
        name: "",
        title: { en: "", th: "" },
        model: "",
        events: [],
        shopclasses: [],
        url: "",
        telephone: "",
        address: "",
        zip: "",
        province: "",
        company: "",
        taxid: "",
        contactperson: "",
        email: "",
        info: { en: "", th: "" },
        tags: [],
        video: [],
        images: [],
        status: 0,
        instruction: { en: [], th: [] },
        curriers: [],
        flowNo: 0,
        modDate: null,
        isActive: false,
      },
      activeItem: null, //active selected item
      files: [],
      modal: null,
      tag: "", // single tag
      category: null,
      item: { name: "", title: "", varPrice: "" }, // single
      loading: false,
      search: "",
      searchResults: [],
    };
  },
  computed: {
    displayedExhibitors() {
      return this.paginate(this.newsList);
    },
  },
  watch: {
    newsList() {
      this.setPages();
    },
  },
  methods: {
    enableRole() {
      let moduleindex = this.$store.state.currentRole.enableModules.findIndex(
        (x) => x.name.toLowerCase() == "news"
      );
      let isActive =
        moduleindex > -1
          ? this.$store.state.currentRole.enableModules[moduleindex].isActive
          : false;
      if (
        this.$store.state.currentRole != null &&
        moduleindex > -1 &&
        isActive
      ) {
        return true;
      }
      return false;
    },
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(this.newsList.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(news) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return news.slice(from, to);
    },
    
    
    gotoExhibitor() {
      this.$router.push({
        path: `/${this.$i18n.locale}/${this.$route.params.oid}/home?news=only`,
      });
    },
    gotoExhibitorList() {
      this.$router.push({
        path: `/${this.$i18n.locale}/${this.$route.params.oid}/news/list`,
      });
    },
    gotoShopClasses() {
      this.$router.push({
        path: `/${this.$i18n.locale}/${this.$route.params.oid}/admin/shopclasses`,
      });
    },
    rndStr(len) {
      let text = "";
      let chars =
        "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyzQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      let ondate = moment(new Date()).format("YYYYMMDD");
      return ondate + text;
    },
    addTag() {
      // Checker for null array
      this.news.tags = this.news.tags || [];
      // pushing each tagword
      if (this.news.tags.length <= 5)
        this.news.tags.push(this.tag.slice(0, -1));

      this.tag = "";
      //console.log(this.news.tags);
    },
    addItem() {
      switch (this.$i18n.locale) {
        case "en":
          this.news.subs.en = this.news.subs.en || [];
          if (this.news.subs.en.length <= 5) {
            this.news.subs.en.push({
              name: "",
              varPrice: 0.0,
              varPage: 0,
              review: true,
            });
          }
          break;
        case "th":
          this.news.subs.th = this.news.subs.th || [];
          if (this.news.subs.th.length <= 5) {
            this.news.subs.th.push({
              name: "",
              varPrice: 0.0,
              varPage: 0,
              review: true,
            });
          }
          break;
        default:
          this.news.subItems = this.news.subItems || [];
          if (this.news.subItems.length <= 5) {
            this.news.subItems.push({
              name: "",
              varPrice: 0.0,
              varPage: 0,
              review: true,
            });
          }
      }
    },
    removesubItems(index) {
      switch (this.$i18n.locale) {
        case "en":
          this.news.subs.en.splice(index, 1);
          break;
        case "th":
          this.news.subs.th.splice(index, 1);
          break;
        default:
          this.news.subItems.splice(index, 1);
      }
    },
    remove(item) {
      this.news.tags.splice(this.news.tags.indexOf(item), 1);
    },
    uploadImage(e) {
      const oid = this.$route.params.oid;

      // Check if the title is empty
      if (this.news.title[this.$i18n.locale] === "") {
        this.$refs.fileupload.value = null;
        Swal.fire({
          title: "Please provide title",
        });
        return;
      }

      // Process files if available
      if (e.target.files) {
        for (let i = 0; i < e.target.files.length; i++) {
          let file = e.target.files[i];
          this.multiUpload(file, oid);
        }
      }
    },

    multiUpload(file, oid) {
      const filename = this.rndStr(4);
      const storageRef = fbAuth
        .storage()
        .ref(`organizations/${oid}/news/${filename}`);

      const uploadTask = storageRef.put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            let images = this.news.images || [];
            images.push(downloadURL); // Push the new image URL
            this.$set(this.news, "images", images); // Ensure reactivity
          });
        }
      );
    },
    deleteImage(img, index) {
      let image = fbAuth.storage().refFromURL(img);
      // console.log(image);
      this.news.images.splice(index, 1);
      this.updateNews();
      image
        .delete()
        .then(() => {
          console.log("Image deleted");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleChange(v) {
      this.files = v;
    },
    reset() {
      this.news = {
        name: "",
        title: { en: "", th: "" },
        model: "",
        events: [],
        shopclasses: [],
        url: "",
        telephone: "",
        address: "",
        zip: "",
        province: "",
        company: "",
        taxid: "",
        contactperson: "",
        email: "",
        info: { en: "", th: "" },
        tags: [],
        video: [],
        images: [],
        status: 0,
        instruction: { en: [], th: [] },
        curriers: [],
        flowNo: 0,
        modDate: null,
        isActive: false,
      };
    },
    changeMode(mode) {
      this.mode = mode;
    },
    createNew() {
      this.mode = "new";
      this.reset();
      this.refreshTable();
      console.log(this.news);
    },
    addEvent() {
      this.news.events = this.news.events || [];
      this.news.events.push(this.category);
    },
    addNews() {
      this.news.modDate = firebase.firestore.FieldValue.serverTimestamp();
      this.news.flowNo = this.news.length;
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("news")
        .add(this.news);
    },
    duplicate(news) {
      var tnews = news;
      tnews.modDate = firebase.firestore.FieldValue.serverTimestamp();
      tnews.flowNo = this.newsList.length;
      //delete tnews[id];
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("news")
        .add(tnews);
      this.refreshTable();
    },
    editExhibitor(news) {
      this.mode = "edit";
      this.news = news;
      this.activeItem = JSON.stringify(news);
    },
    updateStatus(news) {
      news.modDate = firebase.firestore.FieldValue.serverTimestamp();
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("news")
        .doc(news.id)
        .update(news)
        .then(() => {
          toast.fire({
            title: "Update status successfully",
          });
        });
      this.refreshTable();
    },
    seqExhibitorImage(index, move) {
      let tmp;
      if (index + move > -1 && index + move < this.news.images.length) {
        if (move > 0) {
          tmp = this.news.images[index + 1];
          this.news.images[index + 1] = this.news.images[index];
          this.news.images[index] = tmp;
          db.collection("organizations")
            .doc(this.$route.params.oid)
            .collection("news")
            .doc(this.news.id)
            .update(this.news);
        } else {
          tmp = this.news.images[index - 1];
          this.news.images[index - 1] = this.news.images[index];
          this.news.images[index] = tmp;
          db.collection("organizations")
            .doc(this.$route.params.oid)
            .collection("news")
            .doc(this.news.id)
            .update(this.news);
        }
      }
    },
    updateSeq(news, index, move) {
      if (index + move < this.newsList.length && index + move > -1) {
        news.flowNo = index + move;
        if (move > 0) {
          if (index + 1 < this.newsList.length) {
            this.newsList.find((p) => p.id == news.id).flowNo = index + 1;
            this.newsList[index + 1].flowNo = index;
            db.collection("organizations")
              .doc(this.$route.params.oid)
              .collection("news")
              .doc(news.id)
              .update(news);
          }
        } else {
          if (index - 1 > -1) {
            this.newsList[index - 1].flowNo = index;
            this.newsList.find((p) => p.id == news.id).flowNo = index - 1;
            db.collection("organizations")
              .doc(this.$route.params.oid)
              .collection("news")
              .doc(news.id)
              .update(news);
          }
        }
        this.refreshTable();
      }
      console.log(this.newsList);
    },
    updatePX(px) {
      this.news = px;
    },
    updateNews() {
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("news")
        .doc(this.news.id)
        .update(this.news)
        .then(() => {
          //this.refreshTable();
          toast.fire({
            title: "Updated successfully",
          });
        });
    },
    deleteExhibitor(doc) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          db.collection("organizations")
            .doc(this.$route.params.oid)
            .collection("news")
            .doc(doc.id)
            .delete();

          for (var img in doc.images) {
            fbAuth
              .storage()
              .refFromURL(doc.images[img])
              .delete()
              .then(() => {
                console.log("image deleted");
              })
              .catch((err) => {
                console.log(err);
              });
            // console.log(doc.images[img])
          }

          toast.fire({
            title: "Deleted successfully",
          });
        }
      });
    },
    refreshTable() {
      this.newsList = [];
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("news")
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            let doc = change.doc;
            let getnews = doc.data();
            getnews.id = doc.id;
            let ioldexh = this.newsList.findIndex(
              (evt) => evt.id == getnews.id
            );
            if (ioldexh < 0) this.newsList.push(getnews);
            else {
              this.newsList = this.newsList.sort(
                (a, b) =>
                  a.title[this.$i18n.locale] < b.title[this.$i18n.locale]
              );
              this.$set(this.news, ioldexh, getnews);
            }
            if (change.type === "added" || change.type === "removed") {
              this.$set(this.news, ioldexh, getnews);
            }
          });
        });
    },
    searchNow() {
      this.searchResults = this.newsList.filter((news) => {
        if (
          news.name.toLowerCase().indexOf(this.search.toLowerCase()) != "-1"
        ) {
          return news;
        }
      });
    },
  }, // -- end methods -- //
  created() {
    // Binding Collections
    this.$binding(
      "news",
      db
        .collection("organizations")
        .doc(this.$route.params.oid)
        .collection("news")
    ).then((newslist) => {
      this.newsList = newslist.sort(
        (a, b) => a.title[this.$i18n.locale] < b.title[this.$i18n.locale]
      ); // => __ob__: Observer
      //console.log(this.news);
    });
    this.$binding(
      "events",
      db
        .collection("organizations")
        .doc(this.$route.params.oid)
        .collection("events")
    ).then((events) => {
      this.events = events; // => __ob__: Observer
      //   console.log(this.events);
    });

    this.$binding(
      "shopclasses",
      db
        .collection("organizations")
        .doc(this.$route.params.oid)
        .collection("shopclasses")
    ).then((shopclass) => {
      this.shopclasses = shopclass; // => __ob__: Observer
      //   console.log(this.events);
    });
  },
};
</script>
<style scoped>
.img-small-wrap {
  position: relative;
}
.img-small-wrap .delete-img {
  position: absolute;
  top: -14px;
  right: -2px;
  cursor: pointer;
}
.img-small-wrap .move-right-img {
  position: absolute;
  bottom: -14px;
  right: -2px;
  cursor: pointer;
}
.img-small-wrap .move-left-img {
  position: absolute;
  bottom: -14px;
  left: -2px;
  cursor: pointer;
}
</style>
