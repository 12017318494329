<template>
  <div>
    <div
      v-if="!searchMode"
      id="searchGroup"
      class="mx-auto mt-3"
      style="max-width: 400px"
    >
      <div class="row content-justify-between">
        <div class="col-10">
          <b-form-input
            v-model="searchText"
            :id="`type-text`"
            type="text"
          ></b-form-input>
        </div>
        <div class="col-2">
          <a @click="searchOrder('text')" class="fas fa-search fa-2x"> </a>
        </div>
      </div>
      <div class="row content-justify-between">
        <div class="col-10">
          <b-form-input
            v-model="searchText"
            :id="`type-date`"
            type="date"
          ></b-form-input>
        </div>
        <div class="col-2">
          <a @click="searchOrder('date')" class="fas fa-search fa-2x"> </a>
        </div>
      </div>
    </div>
    <div v-if="searchMode" class="overflow-auto">
      <div class="mx-auto m-3" style="max-width: 400px">
        <div class="row m-2 justify-content-between">
          <div>
            <b-pagination
              class="customPagination col-9"
              pills
              v-model="currentPage"
              :total-rows="orders.length"
              :per-page="perPage"
            ></b-pagination>
          </div>
          <div>
            <a @click="searchMode = !searchMode" class="fas fa-search fa-2x">
            </a>
          </div>
          <div>
            <a @click="getLineUpdateShopOrder()" class="fas fa-undo fa-2x"> </a>
          </div>
        </div>
      </div>
    </div>
    <div class="px-1 mx-2" v-if="searchMode">
      <div v-for="order in paginate(orders)" :key="order.id">
        <div
          @click="selectOrder(order.id)"
          class="jpage-card mx-auto"
          style="max-width: 400px"
        >
          <div class="row g-0 px-1">
            <div class="col-md-7 col-sm-7 col-7">
              <div class="pt-1 mx-2 text-left">
                <a>No: {{ order.id }}</a>
              </div>
            </div>
            <div class="col-md-5 col-sm-5 col-5 pt-1 text-right">
              <a> {{ order.createDate }}</a>
            </div>
            <div class="col-md-8 col-sm-8 col-8">
              <div class="pt-1 mx-2 text-left">
                <a> {{ order.process[order.process.length - 1].status }}</a>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-4 pt-1 text-right">
              <a>Price : {{ order.totalPrice }}</a>
            </div>
          </div>
        </div>
        <div
          v-if="selected == order.id"
          class="j-card-detail-page mx-auto mt-2 pb-2 mx-1"
        >
          <div class="register-page">
            <div class="container mx-auto">
              <div class="card-wrapper">
                <form v-on:submit.prevent novalidate="">
                  <label for="name">{{ $t("modal.tabs.register.name") }}</label>
                  <div
                    v-for="(orderitem, index) in order.orderItems"
                    :key="index"
                    class="form-group"
                  >
                    <input
                      id="name"
                      type="text"
                      class="form-control"
                      name="name"
                      v-model="orderitem.name"
                      disabled
                      required
                      autofocus
                    />
                    <div class="row mt-1">
                      <div class="invalid-feedback">
                        {{ $t("modal.tabs.register.name-invalid-1") }}
                      </div>

                      <div class="col-md-4 col-sm-4 col-4 my-auto">
                        <img
                          :src="orderitem.imageURL"
                          width="50"
                          height="50"
                          class="logo-brand d-inline-block align-top"
                          alt=""
                        />
                      </div>
                      <div class="col-md-4 col-sm-4 col-4">
                        <label for="quantity">{{
                          $t("adminPage.purchases.quantity")
                        }}</label>
                        <input
                          id="quantity"
                          type="text"
                          class="form-control"
                          name="quantity"
                          v-model="orderitem.quantity"
                          disabled
                          required
                          data-eye
                        />
                      </div>
                      <div class="col-md-4 col-sm-4 col-4">
                        <label for="price">{{
                          $t("adminPage.purchases.price")
                        }}</label>
                        <input
                          id="price"
                          type="text"
                          class="form-control"
                          name="price"
                          v-model="orderitem.price"
                          disabled
                          required
                          data-eye
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="address">{{
                      $t("adminPage.purchases.delivery-address")
                    }}</label>
                    <textarea
                      id="address"
                      type="text"
                      class="form-control"
                      name="address"
                      v-model="order.deliveryAddress"
                      required
                      disabled
                      data-eye
                    />
                    <div class="invalid-feedback">
                      $t("modal.tabs.register.address-invalid-1")
                    </div>
                  </div>

                  <div class="row p-0 m-0 col-12">
                    <div class="form-group col-6">
                      <label for="payment">{{
                        $t("adminPage.purchases.payment")
                      }}</label>
                      <input
                        id="payment"
                        type="text"
                        class="form-control"
                        name="payment"
                        v-model="order.paymentStatus"
                        disabled
                        required
                        data-eye
                      />
                      <div class="invalid-feedback">
                        $t("modal.tabs.register.company-invalid-1")
                      </div>
                    </div>
                    <div class="form-group col-6">
                      <label for="price">{{
                        $t("adminPage.purchases.price")
                      }}</label>
                      <input
                        id="price"
                        type="text"
                        class="form-control"
                        name="price"
                        v-model="order.totalPrice"
                        required
                        disabled
                        data-eye
                      />
                      <div class="invalid-feedback">
                        $t("modal.tabs.register.company-invalid-1")
                      </div>
                    </div>
                  </div>
                  <b-form-group label="Process Status">
                    <div
                      v-for="(pros, proindex) in order.process"
                      :key="pros.name"
                      class="dropdown col-12"
                      stacked
                    >
                      <a class="col-3 dropbtn"
                        >{{
                          $route.params.lang == "en" ? pros.name : pros.thaiName
                        }}
                      </a>
                      <span class="col-6 dropbtn">
                        <a>
                          {{
                            $i18n.locale == "en" ? "Status : " : "สถานะ : "
                          }}</a
                        >
                        <a class="col-6 text-right">
                          {{ statusResult(pros.status) }}
                        </a>
                        <div class="dropdown-content">
                          <a
                            v-for="(seprocess, spindex) in selectProcess(
                              proindex
                            )"
                            @click="
                              defindPStatus(seprocess, proindex, order.id)
                            "
                            :key="spindex"
                            ><span>
                              {{
                                $route.params.lang == "en"
                                  ? seprocess.name
                                  : seprocess.thaiName
                              }}</span
                            ></a
                          >
                        </div></span
                      >
                    </div>
                  </b-form-group>
                  <div class="form-group">
                    <label for="ordernote">{{
                      $t("adminPage.purchases.order-note")
                    }}</label>
                    <textarea
                      id="ordernote"
                      type="text"
                      class="form-control"
                      name="ordernote"
                      v-model="
                        orders[
                          orders.findIndex(
                            (obj) => obj.orderNumber == order.orderNumber
                          )
                        ].note
                      "
                      data-eye
                    />
                  </div>

                  <div class="form-group m-0">
                    <button
                      @click="updateOrder(order)"
                      class="btn btn-primary btn-block"
                    >
                      {{ $t("adminPage.purchases.btn-update") }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { db } from "@/configs/firebase";
import axios from "axios";
import moment from "moment";
import firebase from "firebase/compat/app";

export default {
  name: "line-admin-order-list",
  data() {
    return {
      searchMode: true,
      searchText: "",
      searchTime: "",
      rows: 100,
      pages: [],
      perPage: 10,
      currentPage: 1,
      orders: [],
      selected: null,
      currentuser: null,
      process: [],
    };
  },
  created() {
    //console.log(this.$route.params);
    if (this.$store.state.currentuser.id != "") {
      this.currentuser = this.$store.state.currentuser;
    }
    // this.$store.dispatch('getUser');
  },
  mounted() {
    //this.getLineShopOrderData();
    this.getLineUpdateShopOrder();
    this.getProcess();
    //this.initOrderData();
  },
  methods: {
    statusResult(status) {
      let statusx = this.$i18n.locale == "en" ? "Undefined" : "ไม่กำหนด";
      return status ?? statusx;
    },
    ltrim(str) {
      if (!str) return str;
      return str.replace(/^\s+/g, "");
    },
    defindPStatus(seprocess, proindex, orderid) {
      //console.log('exx',seprocess,proindex,orderid);
      let objIndex = this.orders.findIndex((obj) => obj.orderNumber == orderid);
      //console.log(seprocess.name);
      if (objIndex > -1) {
        this.orders[objIndex].process[proindex].status =
          this.$route.params.lang == "en" ? seprocess.name : seprocess.thaiName;
        //console.log(this.orders[objIndex]);
        db.collection("organizations")
          .doc(this.$route.params.oid)
          .collection("lineorders")
          .doc(this.orders[objIndex].orderNumber)
          .set(this.orders[objIndex]);
      }
    },
    selectProcess(index) {
      return this.process.filter((pro) => {
        if (pro.process == index) return pro;
      });
    },
    getProcess() {
      if (this.$route.params.oid != null) {
        db.collection("organizations")
          .doc(this.$route.params.oid)
          .collection("process")
          .onSnapshot((processsnap) => {
            var fArray = [];
            processsnap.forEach((doc) => {
              let f = doc.data();
              f.id = doc.id;
              fArray.push(f);
            });
            this.process = fArray;
            //console.log(fArray);
          });
        //console.log(user);
      }
    },
    updateOrder(order) {
      let objIndex = this.orders.findIndex(
        (obj) => obj.orderNumber == order.orderNumber
      );
      if (objIndex > -1) {
        db.collection("organizations")
          .doc(this.$route.params.oid)
          .collection("lineorders")
          .doc(this.orders[objIndex].orderNumber)
          .set(this.orders[objIndex]);
      }
      this.selected = null;
    },
    getLineUpdateShopOrder() {
      if (this.$route.params.oid != null) {
        db.collection("organizations")
          .doc(this.$route.params.oid)
          .collection("lineorders")
          .onSnapshot((ordersnap) => {
            var fArray = [];
            ordersnap.forEach((doc) => {
              let f = doc.data();
              f.createDate = moment(doc.lastUpdatedAt).format(
                "YYYY-MM-DD HH:MM"
              );
              f.id = doc.orderNumber;
              f.deliveryAddress = this.ltrim(
                f.shippingAddress.address +
                  " " +
                  f.shippingAddress.province +
                  " " +
                  f.shippingAddress.country
              );
              if (f.process == null) {
                f.process = [
                  { name: "sale", thaiName: "การขาย", status: null },
                  { name: "design", thaiName: "ออกแบบ", status: null },
                  { name: "production", thaiName: "การผลิต", status: null },
                  { name: "delivery", thaiName: "การส่งสินค้า", status: null },
                ];
              }
              if (f.note == null) {
                f.note = "";
              }
              f.id = doc.id;
              fArray.push(f);
            });
            this.orders = fArray; // => __ob__: Observer
            console.log("snap updated");
          });
        //console.log(user);
      }
    },
    getLineShopOrderData() {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      };
      axios
        .get(
          "https://asia-northeast1-zan-box.cloudfunctions.net/getLINEListOrderStatus",
          {
            //   paymentStatus: "PAID",
          },
          axiosConfig
        )
        .then((response) => {
          //console.log(response);
          var fArray = [];
          this.perPage = response.data.perPage;
          response.data.data.forEach((doc) => {
            let f = doc;
            f.createDate = moment(doc.lastUpdatedAt).format("YYYY-MM-DD HH:MM");
            f.id = doc.orderNumber;
            f.deliveryAddress =
              doc.shippingAddress.address +
              " " +
              doc.shippingAddress.province +
              " " +
              doc.shippingAddress.country;
            f.process = [];
            fArray.push(f);
          });
          this.orders = fArray; // => __ob__: Observer
        })
        .catch((err) => console.log(err));
    },
    initOrderData() {
      if (this.$route.params.oid != null) {
        db.collection("organizations")
          .doc(this.$route.params.oid)
          .collection("orders")
          .onSnapshot((ordersnap) => {
            var fArray = [];
            ordersnap.forEach((doc) => {
              let f = doc.data();
              f.id = doc.id;
              fArray.push(f);
            });
            this.orders = fArray; // => __ob__: Observer
            console.log("snap updated");
          });
        //console.log(user);
      }
    },
    searchOrder(option) {
      switch (option) {
        case "text":
          if (this.searchText != "") {
            let txtSearch = this.searchText;
            this.orders = this.orders.filter(function (e) {
              return e.detail.search(txtSearch) > 0;
            });
          }
          this.searchMode = !this.searchMode;
          break;
        case "date":
          if (this.searchText != "") {
            let txtSearch = this.searchText;
            this.orders = this.orders.filter(function (e) {
              let dateIsSame =
                moment(txtSearch).format("YYYY-MM-DD") ==
                moment(e.createDate).format("YYYY-MM-DD");
              console.log(dateIsSame, txtSearch, e.createDate);
              if (dateIsSame) {
                return true;
              } else {
                return false;
              }
            });
          }
          this.searchMode = !this.searchMode;
          break;
        default:
          this.searchMode = !this.searchMode;
      }
    },
    paginate(orders) {
      let page = this.currentPage;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return orders.slice(from, to);
    },
    selectOrder(orderId) {
      if (this.selected == orderId) {
        this.selected = null;
      } else {
        this.selected = orderId;
      }
    },
    rndStr(len) {
      let text = "";
      let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      let ondate = moment(new Date()).format("YYYYMMDD_HH:MM");
      return ondate + text;
    },
  },
};
</script>
<style scoped>
p span {
  display: block;
}
.jpage-card { 
  font-size: 1em;
  overflow: hidden;
  padding: 5;
  border: none;
  border-radius: 0.28571429rem;
  box-shadow: 0 3px 3px 0 #1818c2, 0 0 0 1px #d4d4d5;
  margin-top: 20px;
}
.card2 {
  font-size: 1em;
  overflow: hidden;
  padding: 4;
  border: none;
  border-radius: 0.28571429rem;
  box-shadow: 0 3px 3px 0 #1a1a9a, 0 0 0 1px #d4d4d5;
  margin-top: 20px;
}

.j-card-page {
  font-size: 14px;
}

.j-card-detail-page {
  font-size: 1em;
  border: none;
  border-radius: 0.28571429rem;
  box-shadow: 0 3px 3px 0 #0a0a5a, 0 0 0 1px #d4d4d5;
  max-width: 400px;
  background-color: rgb(185, 199, 245);
}

.j-card-page .brand {
  width: 90px;
  height: auto;
  overflow: hidden;
  border-radius: 50%;
  margin: 5px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 1;
}

.j-card-page .brand img {
  width: initial;
  height: 100%;
  margin: auto;
  justify-content: center;
  display: block;
}

.j-card-page .card-wrapper {
  height: auto;
  justify-content: center;
  display: block;
}

.j-card-page .jpage-card { 
  border-color: transparent;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.j-card-page .card.fat {
  padding: 10px;
}

.j-card-page .card .card-title {
  margin-bottom: 15px;
}

.j-card-page .form-control {
  border-width: 2.3px;
}

.j-card-page .form-group label {
  width: 100%;
}

.j-card-page .btn.btn-block {
  padding: 12px 10px;
}

.j-card-page .footer {
  margin: 40px 0;
  color: #888;
  text-align: center;
}

@media screen and (max-width: 425px) {
  .j-card-page .card-wrapper {
    width: 90%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 320px) {
  .j-card-page .card.fat {
    padding: 0;
  }

  .j-card-page .card.fat .card-body {
    padding: 15px;
  }
}

.dropbtn {
  padding: 16px;
  font-size: 14px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: block;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 199999;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}
/* .dropdown-content a:focus .dropdown  {
  display: none
} */
.dropdown:hover .dropbtn {
  color: rgb(128, 105, 255);
}

.customPagination > li > a {
  color: rgb(30, 10, 10);
}

.customPagination > li.active > a,
.customPagination > li > a:hover {
  color: rgb(24, 3, 3);
  background-color: rgb(45, 217, 45) !important;
}
</style>
/* 
{
            "orderNumber": "2022080630895056",
            "orderStatus": "FINALIZED",
            "paymentMethod": "BANK",
            "discountAmount": 0,
            "paymentStatus": "PAID",
            "remarkBuyer": "",
            "shipmentDetail": {
                "name": "J&T",
                "description": "จัดส่งทุกวันจันทร์-เสาร์ ตัดรอบเวลา 15.00 น.",
                "type": "STANDARD",
                "trackingNumber": "",
                "isCod": false
            },
            "shipmentPrice": 0,
            "shipmentStatus": "SHIPMENT_READY",
            "shippingAddress": {
                "recipientName": "น.ส.แคทเธอริน บุญหนุน",
                "address": "94/1234 หมู่บ้านเพชรเกษม3 ถ. เพชรเกษม แขวงบางแคเหนือ เขตบางแค กรุงเทพฯ 10160",
                "province": "กรุงเทพฯ",
                "postalCode": "10160",
                "country": "TH",
                "phoneNumber": "0890348838",
                "email": "Kate.alekseeva1211@yandex.ru"
            },
            "subtotalPrice": 3870,
            "totalPrice": 3870,
            "weight": 0,
            "checkoutAt": "2022-08-06T09:15:51.000+0000",
            "lastUpdatedAt": "2022-08-06T09:23:40.000+0000",
            "paidAt": "2022-08-06T09:23:40.000+0000"
        },


///////
{
    "orderNumber": "2022080630904962",
    "orderStatus": "FINALIZED",
    "orderItems": [
        {
            "barcode": "",
            "imageURL": "https://obs.line-scdn.net:443/r/ect/ect/image_164949863578545043219cc1133t0f8d80fb",
            "price": 3461,
            "discountedPrice": 2490,
            "name": "พรีเมี่ยมโฟโต้บุ๊คปกอะคริลิค ขนาด 10x12 นิ้ว กางได้ 180 องศา",
            "quantity": 1,
            "sku": "LFAC-006",
            "weight": 0,
            "productId": 1001019989,
            "variantId": 7993398,
            "variants": [
                {
                    "name": "หน้า",
                    "value": "20หน้า"
                },
                {
                    "name": "ออกแบบ",
                    "value": "ออกแบบพิเศษพร้อมรีทัช"
                }
            ]
        }
    ],
    "paymentMethod": "BANK",
    "discountAmount": 150,
    "paymentStatus": "PAID",
    "remarkBuyer": "",
    "shipmentDetail": {
        "name": "J&T",
        "description": "จัดส่งทุกวันจันทร์-เสาร์ ตัดรอบเวลา 15.00 น.",
        "type": "STANDARD",
        "trackingNumber": "",
        "isCod": false
    },
    "shipmentPrice": 0,
    "shipmentStatus": "SHIPMENT_READY",
    "shippingAddress": {
        "recipientName": "นุสารัตน์ พรมตุ่น",
        "address": "76/217 the passage รามอินทรา - คู้บอน ถนนคู้บอน\nแขวงบางชัน เขตคลองสามวา ",
        "province": "กรุงเทพมหานคร",
        "postalCode": "10510",
        "country": "TH",
        "phoneNumber": "0895909249",
        "email": "no@email.com"
    },
    "subtotalPrice": 2490,
    "totalPrice": 2340,
    "weight": 0,
    "checkoutAt": "2022-08-06T13:38:34.000+0000",
    "lastUpdatedAt": "2022-08-06T13:51:30.000+0000",
    "paidAt": "2022-08-06T13:51:30.000+0000"
}
*/