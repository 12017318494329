<template>
  <div class="container mt-4">
    <base-table
      v-if="accessdevices.length > 0"
      :data="accessdevices"
      :columns="tableColumn"
      thead-classes="text-primary"
    >
      <template v-slot:default="{ row }">
        <td>
          <p class="text-normal title">MAC : {{ row.id }}</p>
          <p class="title">IP : {{ row.IP }}</p>
          <p class="title">
            upTime :{{ String(parseFloat(row.upTime).toFixed(2)) }}
          </p>

          <p class="title">Temperture : {{ row.temperature }}</p>
        </td>

        <td class="hoverable-cell">
          <p class="text-muted">{{ row.name }}</p>
          <p class="text-muted">
            {{ "ระยะเวลา ผู้ปกครองออก : " + row.accessGap + " sec" }}
          </p>
          <p class="text-muted">
            {{ "register id :" + row.students }}
          </p>
          <p class="title">
            <strong>{{ "Updated :" + row.now }}</strong>
          </p>
        </td>
        <td class="td-actions text-center">
          <b-button
            @click="infoRender(row, row.id, $event.target)"
            type="link"
            size="sm"
            class="m-1"
            icon
          >
            <i class="fa-solid fa-pencil"></i>
          </b-button>
          <!-- <b-button type="link" size="sm" class="m-1" icon>
            <i class="fa-solid fa-trash"></i>
          </b-button> -->
        </td>
      </template>
    </base-table>
    <b-modal
      ref="edit"
      :id="infoModal.id"
      :title="infoModal.title"
      ok-only
      @hide="resetInfoModal"
    >
      <div class="jpage-card mx-auto" style="max-width: 540px">
        <div class="row g-0">
          <div class="col-md-12 col-sm-12 col-12">
            <div class="card-body">
              <div>
                <span class="card-text">
                  <span class="row">
                    <span class="col-4 m-1">Access Gap</span>
                    <input
                      class="col-7"
                      v-model="infoModal.item.accessGap" /></span
                  ><span class="row">
                    <span class="col-4 m-1">Name</span>
                    <input class="col-7" v-model="infoModal.item.name"
                  /></span>
                  <span class="row">
                    <span class="col-4 m-1">Database File </span>
                    <input class="col-7" v-model="infoModal.item.databaseFile"
                  /></span>
                  <span class="row">
                    <span class="col-4 m-1">Parent Regist EntryID </span>
                    <input class="col-7" v-model="infoModal.item.students" />
                  </span>
                  <span class="row">
                    <span class="col-4 m-1">MasterIP </span>
                    <input class="col-7" v-model="infoModal.item.masterIP" />
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <pre>{{ infoModal.content }}</pre> -->
      <template #modal-footer>
        <div class="m-2">
          <b-button
            variant="primary"
            size="sm"
            class="float-left m-2"
            @click="saveItem(infoModal.item)"
          >
            Save
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right m-2"
            @click="$refs.edit.hide()"
          >
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import moment from "moment";
import { db } from "@/configs/firebase";
export default {
  components: {
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months prior
    const minDate = new Date(today);
    minDate.setMonth(minDate.getMonth() - 2);
    minDate.setDate(15);
    // 15th in two months
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth() + 24);
    maxDate.setDate(15);
    return {
      windowWidth: window.innerWidth,
      mobileWidth: 700,
      accessdevices: [],
      infoModal: {
        id: "info-modal",
        title: "",
        item: {
          IP: "",
          custom1: "",
          accessGap: "",
          officers: "",
          timestamp: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
          databaseFile: "students",
          oid: this.$route.params.oid,
          webhookUrl: "",
          custom2: "",
          remoteDataUrl: "",
          name: "",
          masterIP: "",
          students: "",
        },
      },
    };
  },
  computed: {
    tableColumn() {
      if (this.windowWidth > this.mobileWidth)
        return ["id", "name", "แก้ไข/รายละเอียด"];
      else return ["id", "name", "แก้ไข/รายละเอียด"];
    },
    tableData() {
      console.log(this.accessdevices);
      return this.accessdevices;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    // Assuming you have already initialized Firestore and have a reference to it.
    const organizationsRef = db
      .collection("organizations")
      .doc(this.$route.params.oid)
      .collection("accessdevices");

    organizationsRef.onSnapshot((querySnapshot) => {
      if (!querySnapshot.empty) {
        this.accessdevices = querySnapshot.docs.map((doc) =>
          Object.assign(doc.data(), { id: doc.id })
        );
        this.accessdevices.sort((a, b) => (a.name > b.name ? 1 : -1));
        // console.log(this.accessdevices);
      } else {
        console.log(querySnapshot);
      }
    });
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    infoRender(item, index, button) {
      this.infoModal.title = `${item.id}`;
      this.infoModal.item = item;
      //this.$ref.edit.show();
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    saveItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to save this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, save it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var savedItem = item;
          savedItem.timestamp = moment(new Date()).format(
            "YYYY-MM-DD hh:mm:ss"
          );
          db.collection("organizations")
            .doc(this.$route.params.oid)
            .collection("accessdevices")
            .doc(String(item.id))
            .update(savedItem, { merge: true });
          this.$refs.edit.hide();

          Swal.fire({
            title: "Save successfully",
          });
        }
      });
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>
