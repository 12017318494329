<template>
  <div class="jpage-card col-12 m-0 py-1 px-0">
    <b-row class="m-1 mt-1 d-flex justify-content-between">
      <div class="row col-lg-6 col-sm-12 justify-content-between">
        <div>
          <h4 v-if="mode === 'new'" class="p-0">
            {{ $t("adminPage.events.add-event.new-title") }}
          </h4>
          <h4 v-if="mode === 'edit'" class="p-0">
            {{ $t("adminPage.events.add-event.edit-title") }}
          </h4>
        </div>
        <div class="row">
          <div class="m-1 p-0" style="background-color: white; border: none">
            {{ $i18n.locale }}
          </div>
          <b-dropdown size="sm" tag="li" variant="link" no-caret>
            <b-dropdown-toggle tag="a" class="m-0 p-0" navLink waves-fixed
              ><strong>{{ $i18n.locale }}</strong></b-dropdown-toggle
            >
            <template #button-content>
              <span class="material-icons"> language </span>
            </template>
            <b-dropdown-item
              @click.prevent="$setLocale(lang)"
              v-for="(lang, i) in $i18n.availableLocales"
              :key="`Lang${i}`"
              :value="lang"
              >{{ $i18n.locale }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
      <div class="row col-lg-6 col-sm-12 justify-content-end pr-1">
        <div class="input-group-prepend">
          <div class="row">
            <label class="mt-1" for="name" style="color: red">{{
              $t("adminPage.events.add-event.master-event")
            }}</label>
            <div class="m-1 p-0 mx-2">
              {{
                event.master
                  ? event.master.title[$i18n.locale]
                  : $t("adminPage.events.add-event.no-master-event")
              }}
            </div>
          </div>
          <b-dropdown size="sm" tag="li" variant="link" no-caret>
            <template #button-content>
              <span class="material-icons md-light md-14"> assignment </span>
            </template>
            <b-dropdown-item
              @click.prevent="setMasterEvent(msevnt)"
              v-for="(msevnt, i) in events"
              :key="i"
              :value="msevnt"
              ><span v-if="event.id != msevnt.id">
                {{ msevnt.title[$i18n.locale] }}</span
              >
              <span v-else
                >{{ $t("adminPage.events.add-event.no-master-event") }}
              </span></b-dropdown-item
            >
          </b-dropdown>
        </div>
        <div class="input-group-prepend">
          <div>
            <div class="m-1 p-0">
              {{ event.payment[$i18n.locale] }}
            </div>
          </div>
          <b-dropdown size="sm" tag="li" variant="link" no-caret>
            <b-dropdown-toggle tag="a" navLink waves-fixed
              ><strong>{{
                event.payment[$i18n.locale]
              }}</strong></b-dropdown-toggle
            >
            <template #button-content>
              <span class="material-icons md-light md-14"> paid </span>
            </template>
            <b-dropdown-item
              @click.prevent="setEventPaymentType(type)"
              v-for="(type, i) in payTypeOption"
              :key="i"
              :value="type"
              >{{ type[$i18n.locale] }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
        <div class="input-group-prepend">
          <div class="m-1 p-0">
            {{ event.type[$i18n.locale] }}
          </div>
          <b-dropdown size="sm" tag="li" variant="link" no-caret>
            <b-dropdown-toggle tag="a" navLink waves-fixed
              ><strong>{{
                event.type[$i18n.locale]
              }}</strong></b-dropdown-toggle
            >
            <template #button-content>
              <span class="material-icons md-14"> event </span>
            </template>
            <b-dropdown-item
              @click.prevent="setEventType(type)"
              v-for="(type, i) in eventTypeOption"
              :key="i"
              :value="type"
              >{{ type[$i18n.locale] }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </b-row>
    <b-row class="row g-0 m-0 p-0">
      <b-row class="col-lg-6 col-sm-12 m-0 p-0">
        <b-col class="mx-auto">
          <!-- <label for="name">{{
            $t("adminPage.events.add-event.event-name")
          }}</label>
          <b-input type="text" v-model="event.name" /> -->
          <div>
            <label class="mt-1" for="name">{{
              $t("adminPage.events.add-event.event-name")
            }}</label>
            <b-input type="text" v-model="event.title[$i18n.locale]" />
          </div>

          <label class="mt-1" for="name">{{
            $t("adminPage.events.add-event.start-time")
          }}</label>
          <div class="input-group">
            <div class="row col-12 mx-1">
              <b-form-datepicker
                class="col-6"
                initial-date="YYYY-MM-DD"
                v-model="startEventDate"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                :min="min"
                :max="max"
                locale="en-US"
              ></b-form-datepicker>
              <b-form-timepicker
                class="col-6"
                v-model="startEventTime"
                :hour12="true"
                :min="min"
                :max="max"
                locale="en-US"
              ></b-form-timepicker>
            </div>
          </div>
          <label class="mt-1" for="name">{{
            $t("adminPage.events.add-event.end-time")
          }}</label>
          <div class="input-group">
            <div class="row col-12 mx-1">
              <b-form-datepicker
                class="col-6"
                v-model="endEventDate"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                :min="min"
                :max="max"
                locale="en"
              ></b-form-datepicker>
              <b-form-timepicker
                class="col-6"
                v-model="endEventTime"
                :hour12="true"
                :min="min"
                :max="max"
                locale="en-US"
              ></b-form-timepicker>
            </div>
          </div>
          <label class="mt-1" for="name">{{
            $t("adminPage.events.add-event.event-liffid")
          }}</label>
          <b-input type="text" v-model="event.liffid" />
          <b-row class="col-12 mt-2">
            <span class="mr-2">
              <b-button
                size="sm"
                @click="addQuestion()"
                class="pt-1 pb-1 pr-2 pl-2"
                ><i class="fas fa-plus"></i>
              </b-button>
            </span>
            <label class="mt-1 mb-0">{{
              $t("adminPage.events.add-event.questions")
            }}</label>
          </b-row>
          <div
            v-for="(quiz, qindex) in event.questions"
            :key="qindex"
            class="row mx-0 p-1 mt-1 mb-2 question"
          >
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ $t("adminPage.events.add-event.question") }}
                </div>
              </div>
              <b-input
                type="text"
                class="form-control"
                v-model="event.questions[qindex].title[$i18n.locale]"
              />
              <div class="input-group-append">
                <div
                  v-if="mode == 'edit'"
                  @click="gotoEditQuestion(event.id, qindex)"
                  class="input-group-text"
                >
                  <i class="fas fa fa-list"></i>
                </div>
                <div @click="removeQuestion(qindex)" class="input-group-text">
                  <i class="fas fa-trash"></i>
                </div>
              </div>
            </div>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ $t("adminPage.events.add-event.info") }}
                </div>
              </div>
              <b-textarea
                type="text"
                class="form-control"
                v-model="event.questions[qindex].info[$i18n.locale]"
              />
            </div>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ $t("adminPage.events.add-event.min-select") }}
                </div>
              </div>
              <b-input
                type="text"
                class="form-control"
                v-model="event.questions[qindex].minSelect"
              />
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ $t("adminPage.events.add-event.max-select") }}
                </div>
              </div>
              <b-input
                type="text"
                class="form-control"
                v-model="event.questions[qindex].maxSelect"
              />
            </div>
            <div class="input-group">
              <div class="input-group-text">
                {{ "ประเภท คำถาม" }}
              </div>
              <div class="input-group-prepend">
                <div
                  class="input-group-text m-0"
                  style="background-color: white"
                >
                  {{ event.questions[qindex].type[$i18n.locale] }}
                </div>
              </div>
              <b-dropdown size="sm" tag="li" variant="link" no-caret>
                <template #button-content>
                  <span class="material-icons"> arrow_drop_down </span>
                </template>
                <b-dropdown-item
                  @click.prevent="setQuestionType(type, qindex)"
                  v-for="(type, i) in qTypeOption"
                  :key="i"
                  :value="type"
                  >{{ type[$i18n.locale] }}</b-dropdown-item
                >
              </b-dropdown>
              <div class="input-group-text">
                <input
                  type="checkbox"
                  aria-checked="event.questions[qindex].isScheduled"
                  v-model="event.questions[qindex].isScheduled"
                  @change="convertToEndDate(qindex)"
                />
                <label class="m-1" for="isScheduled">{{
                  " ตั้งกำหนดเวลา "
                }}</label>
              </div>
              <div
                v-if="event.questions[qindex].isScheduled"
                class="input-group-text input-group-append"
              >
                {{ "ระยะเวลา (นาที)" }}
              </div>
              <div class="input-group-text px-1">
                <b-input
                  v-if="event.questions[qindex].isScheduled"
                  type="number"
                  style="width: 70px"
                  class="px-1"
                  @change="convertToEndDate(qindex)"
                  v-model="event.questions[qindex].minduration"
                />
              </div>
            </div>

            <label
              v-if="event.questions[qindex].isScheduled"
              class="mt-1 mx-auto"
              for="name"
              >{{ $t("adminPage.events.add-event.start-time") }}</label
            >
            <div v-if="event.questions[qindex].isScheduled" class="input-group">
              <div class="row col-12">
                <b-form-datepicker
                  format="YYYY-MM-DD"
                  @input="convertToEndDate(qindex)"
                  class="col-6"
                  v-model="event.questions[qindex].sdate"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  :min="min"
                  :max="max"
                  locale="en"
                ></b-form-datepicker>
                <b-form-timepicker
                  class="col-6"
                  @input="convertToEndDate(qindex)"
                  v-model="event.questions[qindex].stime"
                  :hour12="true"
                  :min="min"
                  :max="max"
                  locale="en-US"
                ></b-form-timepicker>
              </div>
            </div>
          </div>
          <label class="mt-1" for="name">{{
            $t("adminPage.events.add-event.event-details")
          }}</label>
          <div class="form-group" style="width: 100%">
            <vue-editor
              style="width: 100%"
              class="text-normal"
              v-model="event.info[$i18n.locale]"
              :editor-toolbar="customToolbar"
            ></vue-editor>
          </div>
        </b-col>
      </b-row>
      <b-row class="col-lg-6 col-sm-12 m-0 p-0">
        <b-col class="mx-auto">
          <div class="row mx-0 p-1 justify-content-between">
            <div class="row mx-2">
              <div
                class="p-0 mr-5"
                style="background-color: white; border: none"
              >
                {{
                  registerRequiredOptions[selectRegisterOptionId].value.label[
                    $i18n.locale
                  ]
                }}
              </div>
              <b-dropdown size="sm" tag="li" variant="link" no-caret>
                <template #button-content>
                  <span class="material-icons">
                    keyboard_double_arrow_down
                  </span>
                </template>
                <b-dropdown-item
                  @click="selectRegisterOptionId = i"
                  v-for="(regist, i) in registerRequiredOptions"
                  :key="i"
                  :value="regist"
                  >{{ regist.value.label[$i18n.locale] }}</b-dropdown-item
                >
              </b-dropdown>
            </div>
            <div class="row mx-2">
              <span class="m-1">
                <b-button
                  size="sm"
                  @click.prevent="addRegisterRequired(selectRegisterOptionId)"
                  class="pt-1 pb-1 pr-2 pl-2"
                  ><i class="fas fa-plus"></i>
                </b-button>
              </span>
              <label class="mt-1 mb-0">{{
                $t("adminPage.events.add-event.add-form-required")
              }}</label>
            </div>
          </div>
          <div
            v-for="(regist, rindex) in event.registerRequired"
            :key="rindex"
            class="row col-12 mx-0 p-1 mt-1 mb-2 question"
          >
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ "label" }}
                </div>
              </div>
              <b-input
                type="text"
                class="form-control"
                v-model="event.registerRequired[rindex].label[$i18n.locale]"
              />
              <div class="input-group-append">
                <div class="input-group-text">Required</div>
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    v-model="event.registerRequired[rindex].isRequired"
                  />
                </div>
              </div>
              <div class="input-group-append">
                <div
                  @click.prevent="removeRegisterRequired(rindex)"
                  class="input-group-text"
                >
                  <i class="fas fa-trash"></i>
                </div>
              </div>
            </div>
          </div>
          <div v-if="event.payment.en != 'Free'" class="form-group">
            <div>
              <label class="mt-1" for="name">{{
                $t("adminPage.events.add-event.payment-price")
              }}</label>
              <b-input type="text" v-model="event.payment.price" />
            </div>
            <div>
              <label class="mt-1" for="name">{{
                $t("adminPage.events.add-event.payment-term")
              }}</label>
              <b-textarea
                type="text"
                v-model="event.payment.term[$i18n.locale]"
              />
            </div>
          </div>
          <b-row class="col-12 my-2">
            <span class="mr-2">
              <b-button
                size="sm"
                @click="addMenus()"
                class="pt-1 pb-1 pr-2 pl-2"
                ><i class="fas fa-plus"></i>
              </b-button>
            </span>
            <label class="mt-1 mb-0">{{
              $t("adminPage.events.add-event.add-form-menu")
            }}</label>
          </b-row>
          <div
            v-for="(menu, mix) in event.menus"
            :key="mix"
            class="row col-12 mx-0 p-1 mt-1 mb-2 question"
          >
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ "title" }}
                </div>
              </div>
              <b-input
                type="text"
                class="form-control"
                v-model="event.menus[mix].title[$i18n.locale]"
              />
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ "url" }}
                </div>
              </div>
              <b-input
                type="text"
                class="form-control text-normal"
                v-model="event.menus[mix].url"
              />
              <div class="input-group-append">
                <div @click.prevent="removeMenu(mix)" class="input-group-text">
                  <i class="fas fa-trash"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="input-group">
            <b-form-file
              @change="uploadImage"
              class="mb-3"
              accept="image/*"
              multiple
              :placeholder="$t('adminPage.events.add-event.choose')"
              drop-placeholder="Drop file here..."
            >
              <template v-slot:file-name="{ names }">
                <b-badge variant="dark">{{ names[0] }}</b-badge>
                <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
                  + {{ names.length - 1 }} More files
                </b-badge>
              </template>
            </b-form-file>
          </div>
          <div class="form-group d-flex pt-2">
            <div
              class="p-1"
              v-for="(image, index) in event.images"
              :key="index"
            >
              <div class="img-small-wrap">
                <img
                  v-if="image"
                  :src="image"
                  :alt="event.title[$i18n.locale]"
                  width="80px"
                />
                <span class="delete-img" @click="deleteImage(image, index)"
                  >X</span
                >
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-row>
    <b-row class="row col-12" align-h="end">
      <b-button
        pill
        size="sm"
        variant="success"
        @click="addEvent"
        class="mx-2"
        v-if="mode == 'new'"
        >{{ $t("adminPage.events.add-event.create") }}</b-button
      >
      <b-button
        pill
        size="sm"
        variant="success"
        @click="saveEvent()"
        v-if="mode == 'edit'"
        class="mx-2"
        >{{ $t("adminPage.events.add-event.save") }}</b-button
      >
      <b-button
        pill
        size="sm"
        variant="danger"
        class="mx-2"
        @click="changeMode(null)"
        >{{ $t("adminPage.events.add-event.cancel") }}</b-button
      >
    </b-row>
  </div>
</template>
<script>
//let intervalTimer;
import { VueEditor } from "vue2-editor";
import { fbAuth, db } from "@/configs/firebase";
import firebase from "firebase/compat/app";
import moment from "moment";

export default {
  props: {
    eventX: {
      type: Object,
      required: true,
    },
    events: {
      type: Array,
    },
    changeMode: {
      type: Function,
    },
    updateEvent: {
      type: Function,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  components: {
    VueEditor,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months prior
    const minDate = new Date(today);
    minDate.setMonth(minDate.getMonth() - 2);
    minDate.setDate(15);
    // 15th in two months
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth() + 24);
    maxDate.setDate(15);
    return {
      selectRegisterOptionId: 0,
      registerRequiredOptions: [
        {
          id: 0,
          value: {
            field: "name",
            id: 0,
            isRequired: true,
            label: { en: "Name", th: "ชื่อ" },
          },
        },
        {
          id: 1,
          value: {
            field: "email",
            id: 1,
            isRequired: true,
            label: { en: "email", th: "อีเมล์" },
          },
        },
        {
          id: 2,
          value: {
            field: "idcard",
            id: 2,
            isRequired: true,
            label: { en: "id card", th: "บัตรประชาชน" },
          },
        },
        {
          id: 3,
          value: {
            field: "telephone",
            id: 3,
            isRequired: true,
            label: { en: "telephone", th: "เบอร์โทร" },
          },
        },
        {
          id: 4,
          value: {
            field: "address",
            id: 4,
            isRequired: true,
            label: { en: "address", th: "ที่อยู่" },
          },
        },
        {
          id: 5,
          value: {
            field: "birthday",
            id: 5,
            isRequired: true,
            label: { en: "birthday", th: "วันเกิด" },
          },
        },
        {
          id: 6,
          value: {
            field: "age",
            id: 6,
            isRequired: true,
            label: { en: "age", th: "อายุ" },
          },
        },
        {
          id: 7,
          value: {
            field: "gender",
            id: 7,
            isRequired: true,
            label: { en: "gender", th: "เพศ" },
          },
        },
        {
          id: 8,
          value: {
            field: "zipconde",
            id: 8,
            isRequired: true,
            label: { en: "zipcode", th: "รหัสไปรยณีย์" },
          },
        },
        {
          id: 9,
          value: {
            field: "company",
            id: 9,
            isRequired: true,
            label: { en: "company", th: "หน่วยงาน" },
          },
        },
        {
          id: 10,
          value: {
            field: "ig",
            id: 10,
            isRequired: true,
            label: { en: "ig", th: "อินสตาแกรม" },
          },
        },
        {
          id: 11,
          value: {
            field: "lineid",
            id: 11,
            isRequired: true,
            label: { en: "line id", th: "ไอดี ไลน์" },
          },
        },
        {
          id: 12,
          value: {
            field: "facebook",
            id: 12,
            isRequired: true,
            label: { en: "facebook", th: "เฟสบุค" },
          },
        },
        {
          id: 13,
          value: {
            field: "grade",
            id: 13,
            isRequired: true,
            label: { en: "Grade", th: "ระดับชั้น" },
          },
        },
        {
          id: 14,
          value: {
            field: "class",
            id: 14,
            isRequired: true,
            label: { en: "class", th: "ห้อง" },
          },
        },
        {
          id: 15,
          value: {
            field: "memberid",
            id: 15,
            isRequired: true,
            label: { en: "Member Id", th: "เลขสมาชิก" },
          },
        },
        {
          id: 16,
          value: {
            field: "secretcode",
            id: 16,
            isRequired: true,
            label: { en: "Secret Code", th: "รหัสผ่าน" },
          },
        },
      ],
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
      payTypeOption: [
        {
          en: "Free",
          th: "ไม่เสียค่าใช้จ่าย",
          price: 0,
          term: { en: "", th: "" },
        },
        {
          en: "Register Fee",
          th: "มีค่าใช้จ่าย",
          price: 0,
          term: { en: "", th: "" },
        },
      ],
      eventTypeOption: [
        {
          en: "trade show",
          th: "งานแสดงสินค้า",
          disable: [],
          enable: [],
        },
        {
          en: "quiz practice",
          th: "ฝึกทำข้อสอบ",
          disable: [],
          enable: [],
        },
        {
          en: "quiz exam",
          th: "สอบแข่งขัน",
          disable: [],
          enable: [],
        },
        {
          en: "vote",
          th: "เลือกตั้ง",
          disable: [],
          enable: [],
        },
        {
          en: "seminar",
          th: "งานสัมนา",
          disable: [],
          enable: [],
        },
        {
          en: "training group",
          th: "ฝึกอบรม",
          disable: [],
          enable: [],
        },
        {
          en: "trip",
          th: "ท่องเทียว",
          disable: [],
          enable: [],
        },
        {
          en: "sport",
          th: "งานกีฬา",
          disable: [],
          enable: [],
        },
      ],
      qTypeOption: [
        { en: "test", th: "ฝึกทำโจทย์", limitedTime: false },
        { en: "vote", th: "โหวต", limitedTime: true },
        { en: "quiz", th: "คำถาม", limitedTime: true },
        { en: "competition quiz", th: "คำถาม แข่งขัน", limitedTime: true },
      ],
      min: minDate,
      max: maxDate,
      uploadProgress: 0.0,
      startEventDate: moment(new Date()).format("YYYY-MM-DD"),
      startEventTime: moment(new Date()).format("HH:mm:ss"),
      endEventDate: moment(new Date()).format("YYYY-MM-DD"),
      endEventTime: moment(new Date()).format("HH:mm:ss"),
      event: this.eventX,
    };
  },
  created() {
    if (this.mode == "edit") {
      let sDate = new Date(this.event.starttime.seconds * 1000);
      let stimeoffset = sDate.getTimezoneOffset();
      sDate = new Date(sDate.getTime() - stimeoffset * 60 * 1000);
      this.startEventDate = sDate.toISOString().split("T")[0];
      this.startEventTime = sDate.toISOString().split("T")[1].slice(0, 8);
      let eDate = new Date(this.event.endtime.seconds * 1000);
      let etimeoffset = eDate.getTimezoneOffset();
      eDate = new Date(eDate.getTime() - etimeoffset * 60 * 1000);
      this.endEventDate = eDate.toISOString().split("T")[0];
      this.endEventTime = eDate.toISOString().split("T")[1].slice(0, 8);
    } else {
      this.startEventDate = moment(new Date()).format("YYYY-MM-DD");
      this.startEventTime = moment(new Date()).format("HH:mm:ss");
      this.endEventDate = moment(new Date()).format("YYYY-MM-DD");
      this.endEventTime = moment(new Date()).format("HH:mm:ss");
    }
    this.event = this.eventX;
    //console.log(this.event.starttime);
  },
  methods: {
    rndStr(len) {
      let text = "";
      let chars =
        "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      let ondate = moment(new Date()).format("YYYYMMDD");
      return ondate + text;
    },
    
    
    convertToEndDate(qindex) {
      // start time
      var sDate = new Date(
        this.event.questions[qindex].sdate +
          "T" +
          this.event.questions[qindex].stime
      );
      //let stimeoffset = sDate.getTimezoneOffset();
      //sDate = new Date(sDate.getTime() + stimeoffset * 60 * 1000);

      var seconds =
        Math.floor(sDate.getTime() / 1000) +
        this.event.questions[qindex].minduration * 60;
      var eDate = new Date(seconds * 1000);

      this.event.questions[qindex].starttime =
        firebase.firestore.Timestamp.fromDate(sDate);
      this.event.questions[qindex].endtime =
        firebase.firestore.Timestamp.fromDate(eDate);
      //console.log("s date", sDate, " end date", eDate, "current", new Date());
    },
    gotoEditQuestion(eventId, qId) {
      this.$router.push(
        `/${this.$i18n.locale}/${this.$route.params.oid}/admin/events/${eventId}/questions/${qId}`
      );
    },
    setQuestionType(type, index) {
      this.event.questions[index].type = type;
      this.updateEvent(this.event);
    },
    setEventType(type) {
      this.event.type = type;
      this.updateEvent(this.event);
    },
    setMasterEvent(msevent) {
      if (msevent.id == this.event.id) {
        this.event.master = null;
      } else {
        this.event.master = {
          id: msevent.id,
          title: { en: msevent.title.en, th: msevent.title.th },
          images: msevent.images,
        };
      }
      this.updateEvent(this.event);
    },
    setEventPaymentType(type) {
      this.event.payment = type;
      this.updateEvent(this.event);
    },
    addQuestion() {
      this.event.questions = this.event.questions || [];
      this.event.questions.push({
        name: "",
        title: { en: "", th: "" },
        info: { en: "", th: "" },
        sdate: moment(new Date()).format("YYYY-MM-DD"),
        stime: moment(new Date()).format("HH:mm"),
        minduration: 60,
        starttime: null,
        endtime: null,
        isCompetition: true,
        author: { id: "", name: "", timestamp: "" },
        type: { en: "vote", th: "โหวต", limitedTime: true },
        minSelect: 1,
        maxSelect: 1,
      });
      this.updateEvent(this.event);
    },
    addMenus() {
      this.event.menus = this.event.menus || [];
      this.event.menus.push({ title: { en: "", th: "" }, url: "" });
      this.updateEvent(this.event);
    },
    addRegisterRequired(optionId) {
      //console.log(this.registerRequiredOptions[optionId]);
      this.event.registerRequired = this.event.registerRequired || [];
      this.event.registerRequired.push(
        this.registerRequiredOptions[optionId].value
      );
      //console.log(this.event.registerRequired);
      //this.updateRegistForm(this.event, this.event.registerRequired);
      this.updateEvent(this.event);
    },
    removeQuestion(index) {
      this.event.questions.splice(index, 1);
      this.updateEvent(this.event);
    },
    removeRegisterRequired(index) {
      this.event.registerRequired.splice(index, 1);
      this.updateEvent(this.event);
    },
    removeMenu(index) {
      this.event.menus.splice(index, 1);
      this.updateEvent(this.event);
    },
    addEvent() {
      this.event.flowNo = this.events.length;
       db.collection("organizations")
.doc(this.$route.params.oid)
.collection("events").add(this.event);
      this.updateEvent(this.event);
      this.changeMode(null);
      //this.$refs.edit.hide();
      // .catch(function(error) {
      //     console.error("Error adding document: ", error);
      // });
    },
    saveEvent() {
      this.event.timestamp = firebase.firestore.FieldValue.serverTimestamp();
      // start time
      var sDate = new Date(this.startEventDate + "T" + this.startEventTime);
      this.event.starttime = firebase.firestore.Timestamp.fromDate(sDate);
      /// end time
      var eDate = new Date(this.endEventDate + "T" + this.endEventTime);
      this.event.endtime = firebase.firestore.Timestamp.fromDate(eDate);

       db.collection("organizations")
.doc(this.$route.params.oid)
.collection("events")
        .doc(this.event.id)
        .update(this.event)
        .then(() => {
          //this.$refs.edit.hide();
          this.changeMode(null);
          this.updateEvent(this.event);
          toast.fire({
            title: "Updated successfully",
          });
        });
    },
    handleChange(v) {
      this.files = v;
    },
    uploadImage(e) {
      const oid = this.$route.params.oid;
      const eventid = this.mode === "edit" ? this.event.id : "new-event";

      if (e.target.files) {
        for (let i = 0; i < e.target.files.length; i++) {
          let file = e.target.files[i];
          this.multiUpload(file, oid, eventid);
        }
      }
    },

    multiUpload(file, oid, eventid) {
      const filename = this.rndStr(4);
      const storageRef = fbAuth
        .storage()
        .ref(`organizations/${oid}/events/${eventid}/${filename}`);
      const uploadTask = storageRef.put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            let images = this.event.images || [];
            images.push(downloadURL); // Add the new download URL
            this.event.images = images; // Update the images array
          });
        }
      );
    },
    deleteImage(img, index) {
      let image = fbAuth.storage().refFromURL(img);
      // console.log(image);
      this.event.images.splice(index, 1);
      this.updateEvent(this.event);
      image
        .delete()
        .then(() => {
          console.log("Image deleted");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
