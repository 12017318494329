<template>
  <div class="users">
    <div class="container h-100">
      <div class="intro h-100">
        <!-- top-intro -->
        <div class="row h-100 align-items-center">
          <div class="col-md-8">
            <h1>Users Management</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat
              quae quisquam quos necessitatibus, nisi, mollitia temporibus
              expedita aliquam itaque exercitationem qui fugit facere?
              Temporibus enim aperiam soluta ratione maxime beatae!
            </p>
          </div>
          <div class="col-md-4">
            <img
              src="@/assets/img/orders.svg"
              class="img-fluid"
              alt="overview image"
            />
          </div>
        </div>
        <!-- ./top-intro -->
      </div>

      <!-- body-title -->
      <div class="row my-2 justify-content-between">
        <h2 class="col-auto">Users List</h2>
        <button class="btn btn-success col-auto" disabled @click="createNew">
          Create User
        </button>
      </div>
      <!-- ./body-title -->

      <!-- row -->
      <div class="row">
        <!-- col-1 -->
        <div class="col-md-4">
          <!-- search -->
          <div class="form-group">
            <label for="filterInput">Filter</label>
            <div class="input-group">
              <input
                v-model="filter"
                type="search"
                class="form-control"
                id="filterInput"
                placeholder="Search by email"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
          <!-- ./search -->
        </div>
        <!-- ./col-1 -->

        <!-- col-2 -->
        <div class="col-md-4">
          <h2>
            Total Users
            <span class="badge badge-primary">
              {{ totalRows }}
              <clip-loader
                :loading="loading"
                :color="`#fff`"
                :size="25"
              ></clip-loader>
            </span>
          </h2>
        </div>
        <!-- ./col-2 -->
      </div>
      <!-- ./row -->

      <!-- pagination -->
      <nav>
        <ul class="pagination justify-content-center my-2">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" @click="currentPage = 1">First</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" @click="currentPage--">Prev</a>
          </li>
          <li
            class="page-item"
            v-for="page in totalPages"
            :key="page"
            :class="{ active: currentPage === page }"
          >
            <a class="page-link" @click="currentPage = page">{{ page }}</a>
          </li>
          <li
            class="page-item"
            :class="{ disabled: currentPage === totalPages }"
          >
            <a class="page-link" @click="currentPage++">Next</a>
          </li>
          <li
            class="page-item"
            :class="{ disabled: currentPage === totalPages }"
          >
            <a class="page-link" @click="currentPage = totalPages">Last</a>
          </li>
        </ul>
      </nav>
      <!-- ./pagination -->

      <!-- table -->
      <table class="table table-striped table-bordered text-center">
        <thead class="thead-dark">
          <tr>
            <th>Email</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.uid">
            <td>
              <h5>
                <span
                  class="badge"
                  :class="user.emailVerified ? 'badge-success' : 'badge-danger'"
                >
                  {{ user.emailVerified ? "Verified Account" : "Not Verified" }}
                </span>
              </h5>
            </td>
            <td>
              <h5>
                <span
                  class="badge"
                  :class="user.disabled ? 'badge-danger' : 'badge-success'"
                >
                  {{ user.disabled ? "Disabled" : "Enabled" }}
                </span>
              </h5>
            </td>
            <td>
              <button class="btn btn-primary" disabled @click="editUser(user)">
                Edit
              </button>
              <button
                class="btn btn-success"
                v-if="!user.customClaims && user.emailVerified"
                @click="addAdmin(user.email)"
              >
                Make Admin
              </button>
              <button
                class="btn btn-warning"
                v-if="user.disabled"
                @click="enableAccount(user.uid)"
              >
                Enable
              </button>
              <button
                class="btn btn-danger"
                v-if="!user.disabled && !user.customClaims"
                @click="disableAccount(user.uid)"
              >
                Disable
              </button>
              <button
                class="btn btn-danger"
                @click="deleteUser(user.uid)"
                v-if="!user.customClaims"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- ./table -->

      <!-- pagination -->
      <nav>
        <ul class="pagination justify-content-center my-3">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" @click="currentPage = 1">First</a>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <a class="page-link" @click="currentPage--">Prev</a>
          </li>
          <li
            class="page-item"
            v-for="page in totalPages"
            :key="page"
            :class="{ active: currentPage === page }"
          >
            <a class="page-link" @click="currentPage = page">{{ page }}</a>
          </li>
          <li
            class="page-item"
            :class="{ disabled: currentPage === totalPages }"
          >
            <a class="page-link" @click="currentPage++">Next</a>
          </li>
          <li
            class="page-item"
            :class="{ disabled: currentPage === totalPages }"
          >
            <a class="page-link" @click="currentPage = totalPages">Last</a>
          </li>
        </ul>
      </nav>
      <!-- ./pagination -->

      <b-modal centered ref="modal" id="create">
        <div>
          <h3 v-if="modal === 'new'">Create User</h3>
          <h3 v-if="modal === 'edit'">Edit User</h3>
        </div>

        <form @submit.prevent="newUser">
          <div class="form-group">
            <label for="email-form">Email</label>
            <input
              id="email-form"
              type="email"
              class="form-control"
              v-model="$v.email.$model"
              :class="{ 'is-invalid': $v.email.$dirty && $v.email.$error }"
            />
            <div
              class="invalid-feedback"
              v-if="$v.email.$dirty && $v.email.$error"
            >
              <span v-if="$v.email.email">This field is required</span>
              <span v-if="$v.email.required">Should be a valid email</span>
            </div>
            <div
              class="valid-feedback"
              v-if="$v.email.$dirty && !$v.email.$error"
            >
              Looks good
            </div>
          </div>

          <div class="form-group">
            <label for="displayName-form">Display Name</label>
            <input
              id="displayName-form"
              type="text"
              class="form-control"
              v-model="$v.displayName.$model"
              :class="{
                'is-invalid': $v.displayName.$dirty && $v.displayName.$error,
              }"
            />
            <div
              class="invalid-feedback"
              v-if="$v.displayName.$dirty && $v.displayName.$error"
            >
              <span v-if="$v.displayName.minLength"
                >This is a required field</span
              >
              <span v-if="$v.displayName.required">
                Name should be at least
                {{ $v.displayName.$params.minLength.min }} chars
              </span>
            </div>
            <div
              class="valid-feedback"
              v-if="$v.displayName.$dirty && !$v.displayName.$error"
            >
              Looks good
            </div>
          </div>

          <div class="form-group text-center">
            <button class="btn btn-success" type="submit">Create</button>
          </div>
        </form>

        <p v-if="boo">{{ boo }}</p>

        <template v-slot:modal-footer="{ cancel }">
          <span>Designs by JINN SOLUTION Co., LTD</span>
          <button class="btn btn-primary btn-sm float-right" @click="cancel()">
            Cancel
          </button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { email } from "vuelidate/lib/validators";
import axios from "axios";
import { functions } from "@/configs/firebase";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  name: "AminUsers",
  components: {},
  data() {
    return {
      users: [],
      perPage: 10,
      currentPage: 1,
      filter: null,
      totalRows: null,
      fields: [
        {
          key: "email",
          sortable: true,
        },
        {
          key: "emailVerified",
          label: "email verified",
          sortable: true,
        },
        {
          key: "userRole",
          label: "user role",
        },
        {
          key: "metadata.creationTime",
          label: "created at",
          sortable: true,
        },
        {
          key: "status",
          label: "account status",
        },
        {
          key: "metadata.lastSignInTime",
          label: "last signin",
          sortable: true,
        },
        {
          key: "providerData[0].providerId",
          label: "signed via",
          sortable: false,
        },
        {
          key: "actions",
          sortable: false,
        },
      ],
      loading: false, // for the loading components
      busy: false,
      modal: null,
      // form
      email: null,
      phoneNumber: null,
      password: null,
      displayName: null,
      // error reporting
      boo: "",
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
    },
    displayName: {
      required,
      minLength: minLength(4),
    },
  },
  methods: {
    listAllUsers() {
      this.busy = true;
      this.loading = true;
      axios
        .get("https://us-central1-jinnsolution.cloudfunctions.net/listUsers")
        .then((response) => {
          this.users = response.data.users;
          this.loading = false;
          this.busy = false;
          return (this.totalRows = this.users.length);
        })
        .catch((err) => console.log(err));
    },
    addAdmin(value) {
      console.log(value);
      const makeAdmin = functions.httpsCallable("addAdminRole");
      makeAdmin({
        email: value,
      })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    createNew() {
      this.modal = "new";
      // this.reset()
      this.$refs.modal.show();
    },
    newUser() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      } else if (!this.$v.$anyError) {
        axios
          .post(
            "https://us-central1-jinnsolution.cloudfunctions.net/makeNewUser",
            {
              email: this.email,
              password: 12345,
              firstName: this.displayName,
            }
          )
          .then((r) => {
            toast.fire({
              title: "created new user successfully",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    disableAccount(x) {
      // .. callable function
      const disable = functions.httpsCallable("disableAccount");
      this.$Progress.start();
      disable({
        uid: x,
      })
        .then((x) => {
          this.$bvToast.toast(`disabled user successfully`, {
            title: "status update",
            variant: "success",
            autoHideDelay: 10000,
          });
          this.$Progress.finish();
        })
        .catch((err) => {
          this.$Progress.fail();
          this.$bvToast.toast(`${err}`, {
            title: "error updating",
            variant: "danger",
            autoHideDelay: 10000,
          });
        });
    },
    enableAccount(x) {
      // launch
      const enable = functions.httpsCallable("enableAccount");
      this.$Progress.start();
      enable({
        uid: x,
      })
        .then((x) => {
          this.$bvToast.toast(`enabled user successfully`, {
            title: "status update",
            variant: "success",
            autoHideDelay: 5000,
          });
          this.$Progress.finish();
        })
        .catch((err) => {
          this.$Progress.fail();
          this.$bvToast.toast(`${err}`, {
            title: "status update",
            autoHideDelay: 5000,
          });
        });
    },
    deleteUser(x) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          const deleting = functions.httpsCallable("deleteUser");
          deleting({
            uid: x,
          })
            .then((result) => {
              toast.fire({
                title: "deleted user successfully",
              });

              this.$Progress.finish();
            })
            .catch((err) => {
              this.$Progress.fail();
              console.log(err);
            });
        }
      });
    },
  },
  // if using vuex instead
  // computed:
  //     mapState({ users: state => state.users }),

  created() {
    this.listAllUsers();

    // if wanted to use vuex instead
    // this.loading = true
    // this.$store.dispatch('listAllUsers').then(() => {
    //     this.loading = false
    //     return this.totalRows = this.users.length;
    // })
  },
  mounted() {
    this.listAllUsers();
  },
};
</script>
