<template>
  <div
    class="calendar-day"
    :class="{
      'calendar-day--not-current': !day.isCurrentMonth,
      'calendar-day--today': isToday && !isSelectThis,
      'calendar-day--select': isSelectThis,
      'calendar-day--subject-class': isSubjectClass,
      'calendar-day--holiday': isHoliday,
      'calendar-day--partial--holiday': isPartialHoliday,
    }"
  >
    <span class="day-label">{{ label }}</span>
    <p v-for="label in labelActivity" :key="label" class="activity-label">
      {{ label }}
    </p>
    <!-- <p v-if="isSubjectClass" class="period-label">
      {{ classPeriodName }}
    </p> -->
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "CalendarMonthDayItem",

  props: {
    day: {
      type: Object,
      required: true,
    },
    isSelectThis: {
      type: Boolean,
      required: true,
    },
    isCurrentMonth: {
      type: Boolean,
      default: false,
    },

    isToday: {
      type: Boolean,
      default: false,
    },
    labelActivity: {
      type: Array,
      default: () => [],
    },
    isSubjectClass: {
      type: Boolean,
      default: false,
    },
    isHoliday: {
      type: Boolean,
      default: false,
    },
    isPartialHoliday: {
      type: Boolean,
      default: false,
    },
    classPeriodName: {
      type: String,
      default: "",
    },
  },

  computed: {
    label() {
      return moment(this.day.date).format("D");
    },
  },
};
</script>

<style scoped>
.calendar-day {
  position: relative;
  min-height: 50px;
  font-size: 16px;
  background-color: #fff;
  color: var(--grey-800);
  padding: 5px;
}

.calendar-day .day-label {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 2px;
  width: var(--day-label-size);
  height: var(--day-label-size);
}

.calendar-day .activity-label {
  display: relative;
  justify-content: left;
  align-items: left;
  left: 2px;
  font-size: 8px;
  color: blue;
  margin: 0;
  width: var(--day-label-size);
  height: var(--day-label-size);
}

p .period-label {
  display: flex;
  font-size: 6px !important;
  color: blue;
  justify-content: center;
  align-items: baseline;
  width: var(--day-label-size);
  height: var(--day-label-size);
}

.calendar-day--subject-class {
  background-color: cyan;
  color: black;
}

.calendar-day--not-current {
  background-color: var(--grey-100);
  color: var(--grey-300);
}

.calendar-day--select {
  padding-top: 4px;
  background-color: rgb(232, 195, 147);
  color: rgb(86, 64, 199);
}

.calendar-day--today {
  padding-top: 4px;
  background-color: rgb(243, 198, 140);
  color: rgb(233, 247, 183);
}

.calendar-day--today > span {
  color: #7d3a19;
  border-radius: 9999px;
  background-color: var(--grey-800);
}
.calendar-day--holiday {
  background-color: rgb(246, 227, 227);
  color: #a2b0f0;
}
.calendar-day--select.calendar-day--holiday {
  padding-top: 4px;
  background-color: rgb(154, 224, 247) !important;
  color: #04176e;
  font-style: italic;
  font-weight: bold;
}
.calendar-day--partial--holiday {
  background-color: rgb(232, 213, 251) !important;
  color: #a2b0f0;
}

.calendar-day--select.calendar-day--partial--holiday {
  padding-top: 4px;
  background-color: rgb(194, 233, 246) !important;
  color: #04176e;
  font-style: italic;
  font-weight: bold;
}
</style>
