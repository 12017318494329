<template>
  <div class="fullscreen">
    <div
      v-if="$route.query.state && !logged"
      class="loading"
      style="background-color: black"
    >
      <div class="loader"></div>
    </div>
    <div v-else class="col-12 text-center my-5">
      <section class="mt-4">
        <div class="row gx-5 justify-content-center">
          <div class="col-lg-8 col-12">
            <h1 class="display-5 fw-bolder text-white mb-2">
              {{ $t("main.header") }}
            </h1>
            <p class="lead text-white-50 mb-4">
              {{ $t("main.description") }}
            </p>
            <div
              class="d-grid d-sm-flex col-lg-6 col-md-10 col-12 mx-auto mt-5 justify-content-sm-center"
            >
              <a
                v-if="
                  this.user != null && this.user.email == 'attawit.k@gmail.com'
                "
                class="btn btn-primary btn-lg col-lg-6 col-md-6 col-12 my-1 px-4 me-sm-3"
                :href="`/${$i18n.locale}/org/org-create`"
                >{{ $t("main.btn-start") }}
              </a>
              <a
                v-else
                href="#contact"
                class="btn btn-outline-light btn-lg col-lg-6 col-md-6 col-12 my-1 px-4 me-sm-3"
                >{{ $t("main.btn-start") }}
              </a>
              <a
                class="btn btn-outline-light btn-lg col-lg-6 col-md-6 col-12 my-1 px-4"
                href="#features"
                >{{ $t("main.btn-learn") }}</a
              >
            </div>
          </div>
        </div>
      </section>
      <section class="py-5 border-bottom" id="features">
        <div class="container px-5 my-5">
          <div class="row gx-5">
            <div class="col-lg-4 mb-5 mb-lg-0">
              <div
                class="feature bg-primary bg-gradient text-white rounded-3 mb-3"
              >
                <span class="material-symbols-outlined"> festival </span>
              </div>
              <h2 class="h4 fw-bolder">{{ $t("main.feature-1.title") }}</h2>
              <p>
                {{ $t("main.feature-1.detail") }}
              </p>
              <!-- <a class="text-decoration-none" href="#!">
              Call to action
              <i class="bi bi-arrow-right"></i>
            </a> -->
            </div>
            <div class="col-lg-4 mb-5 mb-lg-0">
              <div
                class="feature bg-primary bg-gradient text-white rounded-3 mb-3"
              >
                <span class="material-symbols-outlined"> history_edu </span>
              </div>
              <h2 class="h4 fw-bolder">{{ $t("main.feature-2.title") }}</h2>
              <p>
                {{ $t("main.feature-2.detail") }}
              </p>
              <!-- <a class="text-decoration-none" href="#!">
              Call to action
              <i class="bi bi-arrow-right"></i>
            </a> -->
            </div>
            <div class="col-lg-4">
              <div
                class="feature bg-primary bg-gradient text-white rounded-3 mb-3"
              >
                <span class="material-symbols-outlined"> inventory </span>
              </div>
              <h2 class="h4 fw-bolder">{{ $t("main.feature-3.title") }}</h2>
              <p>
                {{ $t("main.feature-3.detail") }}
              </p>
              <!-- <a class="text-decoration-none" href="#!">
              Call to action
              <i class="bi bi-arrow-right"></i>
            </a> -->
            </div>
          </div>
        </div>
      </section>
      <div no-gutters class="row template-one">
        <div
          class="mx-auto bg-black m-5 col-sm-6 col-lg-4 col-xs-12 flex-column align-items-center"
        >
          <div class="row my-5">
            <div
              class="d-flex flex-row imgcard mx-auto"
              style="padding: 5px; margin: 10px"
              v-for="(org, oindex) in ownerorglist"
              :key="oindex"
            >
              <div class="col">
                <img
                  class="row align-items-center"
                  src="@/assets/img/organizational.png"
                  style="width: 100px"
                  @click="goAdminQrg(org)"
                />
                <div class="row align-items-center">
                  <span
                    style="color: black; padding: 2px"
                    class="mx-auto text-center"
                    >{{ org.company }}</span
                  >
                </div>
                <div class="row">
                  <span
                    style="color: black; padding: 5px; margin: 5px"
                    class="mx-auto"
                  >
                    {{ org.orgid }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row justify-content-center mb-3">
              <h4>
                <p>
                  <span class="themecolor" style="color: blue">J</span
                  ><span class="themecolor" style="color: red">I</span
                  ><span class="themecolor" style="color: blue">NN</span>
                </p>
              </h4>
            </div>
            <div id="contact" class="row justify-content-center mb-3">
              <div class="disclaimer font-weight-light">
                <h4>
                  <p>
                    <span class="telephone" style="color: blue"
                      >+66(0)818085651</span
                    >
                  </p>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainNav from "./Nav.vue";

import { fbAuth, db } from "@/configs/firebase";
export default {
  name: "TemplateOne",
  components: {},
  data() {
    return {
      ownerorglist: [],
      logged: false,
      user: null,
      logo: "https://picsum.photos/seed/picsum/50",
      backgroundImage: "https://picsum.photos/seed/picsum/900/600",
      title: "JINN PROJECT",
      disclaimer: ["disclaim 1", "disclaim 2"],
      facebook: "facebook",
      instagram: "instragram",
      youtube: "",
      linkedin: "linkedin",
    };
  },
  created() {
    console.log("Main created");
  },
  mounted() {
    //this.routeToFocus();

    this.checkFirebaseLoginState();
  },
  methods: {
    checkFirebaseLoginState() {
      fbAuth.auth().onAuthStateChanged((user) => {
        if (user) {
          this.logged = true;
          this.verified = user.emailVerified;
          this.user = user;
          this.session = true;
          this.checkOrganizationTeamRole();
        } else {
          this.logged = false;
          this.verified = false;
          this.session = false;
        }
      });
    },
    goAdminQrg(org) {
      this.$router.push({
        path: `/${this.$i18n.locale}/${org.id}/admin/overview`,
      });
    },
    openModalLoginOnly() {
      console.log("modal login-only");
      this.$root.$emit("bv::show::modal", "modal-login", "#btnShow");
      this.$store.commit("saveStateBfLogin", {
        oid: this.$route.params.oid ?? null,
        id: this.$route.params.eventid ?? null,
        path: this.$route.fullPath ?? null,
      });
    },
    checkOrganizationTeamRole() {
      if (this.user != null && this.user.email != "") {
        db.collection("organizations")
          .where("email", "==", this.user.email)
          .get()
          .then((snapshot) => {
            if (snapshot.empty) {
              console.log("No matching documents.");
              //console.log(this.user);
            } else {
              this.ownerorglist = [];
              snapshot.forEach((doc) => {
                // console.log(doc.id, "=>", doc.data());
                let Org = doc.data();
                Org.id = doc.id;
                Org.orgid = doc.id;
                this.ownerorglist.push(Org);
              });
            }
          });
      } else {
        Swal.fire({
          title: " Please login",
        });
      }
    },
    getTitle(oid) {
      if (oid != null && this.$store.state.title.oid != oid) {
        db.collection("organizations")
          .doc(oid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              if (doc.data().title) {
                this.$store.commit("saveTitle", {
                  oid: oid,
                  name: doc.data().title,
                });
                //console.log(doc.data().title);
                document.title = doc.data().title;
              } else {
                document.title = "JProject";
              }
              //console.log("Document data:", doc.data());
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      } else {
        document.title = this.$store.state.title.name;
      }
    },
    routeToFocus() {
      console.log("route ", this.$route);
      //console.log("store registerState ", this.$store.state.registerState);
      if (
        this.$route.query != null &&
        this.$route.query.callback_login != null &&
        this.$route.query.state != null
      ) {
        if (
          this.$store.state.registerState.path != null &&
          this.$store.state.registerState.oid != ""
        ) {
          this.getTitle(this.$store.state.registerState.oid);
          this.$router.push({
            query: this.$route.query,
            path: this.$store.state.registerState.path,
          });
        } else {
          //
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  text-transform: capitalize;
}

.telephone {
  font-size: 1rem;
}
.sideimg {
  background-repeat: no-repeat;
}
.imgcard {
  background-color: white;
  color: black;
  padding: 2px;
  border-radius: 0.28571429rem;
  box-shadow: 0 3px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  margin-top: 20px;
}

.normtext {
  text-transform: none !important;
}
.fullscreen {
  font-family: "Nunito", sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background-color: black;
  color: white;
}
a > span {
  display: block; /*  with current markup, this is needed to break line  */
  padding-bottom: 5px;
}

.template-one {
  position: relative;
  width: 100%;
  height: 100%;
  .title {
    font-size: 3rem;
  }
  .disclaimer {
    font-size: 1.5rem;
  }
  .background-container {
    background-size: cover;
    box-shadow: inset 0rem 0rem 5rem rgba(0, 0, 0, 0.2) !important;
  }
  .social-media-container {
    a {
      transition: all 1s;
      &:hover {
        color: gray !important;
        text-decoration: none;
      }
    }
  }
}

/**** */

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus,
.btn-outline-light:active:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light:disabled,
.btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus,
.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
  color: #212529;
  background-color: transparent;
}
.me-sm-3 {
  margin-right: 1rem !important;
}
</style>
