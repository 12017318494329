<template>
  <div class="banners">
    <div>
      <div>
        <div class="row col-12 align-items-center">
          <div class="col-9">
            <h4>{{ $t("adminPage.banners.title") }}</h4>
            <p>{{ $t("adminPage.banners.text") }}</p>
          </div>
          <div class="col-3">
            <img
              src="@/assets/img/banners.svg"
              class="img-fluid"
              alt="overview image"
            />
          </div>
        </div>

        <div class="row col-12 justify-content-end">
          <div class="form-group col-12 col-lg-10 col-md-10 mb-0">
            <label for="filterInput">{{ $t("search.label") }}</label>
            <div class="input-group">
              <input
                type="search"
                class="form-control"
                id="filterInput"
                v-model="search"
                @input="searchNow()"
                :placeholder="$t('search.input')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  :disabled="!search"
                  @click="search = ''"
                >
                  {{ $t("search.clear") }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row col-12 mt-2 justify-content-between mx-2">
          <div class="row col-9 col-sm-6 col-lg-6">
            <div class="row justify-content-between" style="max-width: 400px">
              <div>
                <pagination
                  class="customPagination col-sm-5"
                  v-model="currentPage"
                  :total-rows="banners.length"
                  :per-page="perPage"
                />
              </div>
              <div>
                <a @click="refreshTable()" class="fas fa-undo fa-2x"></a>
              </div>
            </div>
          </div>
          <div>
            <button class="btn btn-success" @click="createNew">
              {{ $t("adminPage.banners.list-button") }}
            </button>
          </div>
        </div>
      </div>

      <div class="row col-lg-12 m-1">
        <div
          v-for="(banner, bi) in !search ? banners : searchResults"
          :key="bi"
          class="jpage-card col-lg-4 p-0"
          style="margin: 5px"
        >
          <div class="row p-0">
            <div class="col-3 col-lg-3 mx-1">
              <img
                v-for="image in banner.images"
                :key="image.id"
                :src="image"
                alt="banner images"
                class="img-fluid"
                style="width: 80px"
              />
            </div>
            <div class="col-3 col-lg-3">
              <span class="row m-0">{{ banner.title[$i18n.locale] }}</span>
            </div>
            <div class="col-4 col-lg-2 m-0 p-1">
              <div class="row mx-auto m-1">
                <button
                  class="btn btn-primary btn-sm m-1"
                  @click="editBanner(banner)"
                >
                  <i class="fas fa-edit"></i>
                </button>
                <button
                  class="btn btn-danger btn-sm m-1"
                  @click="deleteBanner(banner)"
                >
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </div>
            <div class="col-2 col-lg-2 m-0">
              <div class="row m-1 justify-content-center">
                <input
                  type="checkbox"
                  v-model="banner.status"
                  @change="updateStatus(banner)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <!-- Modal -->
        <div
          class="modal fade"
          id="banner"
          tabindex="-1"
          role="dialog"
          aria-labelledby="bannerModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="bannerModalLabel">
                  {{
                    mode === "new"
                      ? $t("adminPage.banners.add-banner.new-title")
                      : $t("adminPage.banners.add-banner.edit-title")
                  }}
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-lg-8">
                    <label>{{
                      $t("adminPage.banners.add-banner.banner-name")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="banner.title[$i18n.locale]"
                    />
                    <label>{{
                      $t("adminPage.banners.add-banner.banner-link")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="banner.link"
                    />
                    <label class="mt-1">{{ "ข้อมูล" }}</label>
                    <vue-editor
                      style="width: 100%"
                      class="text-normal"
                      v-model="banner.info[$i18n.locale]"
                      :editor-toolbar="customToolbar"
                    ></vue-editor>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-group">
                      <input
                        type="file"
                        class="form-control-file"
                        @change="uploadImage"
                        multiple
                        accept="image/*"
                      />
                    </div>
                    <div class="d-flex pt-2">
                      <div
                        v-for="(image, index) in banner.images"
                        :key="index"
                        class="p-1"
                      >
                        <div class="img-small-wrap">
                          <img
                            v-if="image"
                            :src="image"
                            :alt="banner.title[$i18n.locale]"
                            width="80px"
                          />
                          <span
                            class="delete-img"
                            @click="deleteImage(image, index)"
                            >X</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  class="btn btn-success"
                  @click="addBanner"
                  v-if="modal == 'new'"
                >
                  {{ $t("adminPage.banners.add-banner.create") }}
                </button>
                <button
                  class="btn btn-success"
                  @click="updateBanner()"
                  v-if="modal == 'edit'"
                >
                  {{ $t("adminPage.banners.add-banner.save") }}
                </button>
                <button class="btn btn-danger" @click="cancel()">
                  {{ $t("adminPage.banners.add-banner.cancel") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Advanced Use - Hook into Quill's API for Custom Functionality

import { VueEditor } from "vue2-editor";
import { fbAuth, db } from "@/configs/firebase";
import firebase from "firebase/compat/app";
export default {
  name: "AdminBanners",
  props: {},
  components: {
    VueEditor,
  },
  data() {
    return {
      page: 1,
      perPage: 9,
      currentPage: 1,
      mode: "new",
      banners: [], //banners
      banner: {
        //single banner
        name: "",
        title: { en: "", th: "" },
        info: { en: "", th: "" },
        link: "",
        images: [],
        flowNo: 0,
        status: 0,
      },
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
      activeItem: null, //active selected item
      files: [],
      modal: null,
      loading: false,
      search: "",
      searchResults: [],
    };
  },
  methods: {
    
    
    uploadImage(e) {
      if (e.target.files) {
        for (let i = 0; i < e.target.files.length; i++) {
          let file = e.target.files[i];
          this.multiUpload(file);
        }
      }
    },

    multiUpload(file) {
      const oid = this.$route.params.oid;
      const storageRef = fbAuth
        .storage()
        .ref(`organizations/${oid}/banners/${file.name}`);
      const uploadTask = storageRef.put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            let images = this.banner.images || [];
            images.push(downloadURL); // Add the new download URL
            this.banner.images = images; // Update the images array
          });
        }
      );
    },
    deleteImage(img, index) {
      let image = fbAuth.storage().refFromURL(img);
      // console.log(image);
      this.banner.images.splice(index, 1);
      image
        .delete()
        .then(() => {
          console.log("Image deleted");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleChange(v) {
      this.files = v;
    },
    reset() {
      this.banner = {
        name: "",
        title: { en: "", th: "" },
        info: { en: "", th: "" },
        link: "",
        images: [],
        flowNo: 0,
        status: 0,
      };
    },
    createNew() {
      this.modal = "new";
      this.mode = "new";
      this.reset();
      this.$refs.edit.show();
    },
    addBanner() {
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("banners")
        .add(this.banner);
      this.$refs.edit.hide();
      // .catch(function(error) {
      //     console.error("Error adding document: ", error);
      // });
    },
    editBanner(banner) {
      this.modal = "edit";
      this.mode = "edit";
      this.banner = banner;
      this.activeItem = JSON.stringify(banner);
      this.$refs.edit.show();
    },
    updateSeq(banner, index, move) {
      if (index + move < this.banners.length && index + move > -1) {
        banner.flowNo = index + move;
        if (move > 0) {
          if (index + 1 < this.banners.length) {
            this.banners[index].flowNo = index + 1;
            this.banners[index + 1].flowNo = index;
            db.collection("organizations")
              .doc(this.$route.params.oid)
              .collection("banners")
              .doc(banner.id)
              .update(banner);
          }
        } else {
          if (index - 1 > -1) {
            this.banners[index - 1].flowNo = index;
            this.banners[index].flowNo = index - 1;
            db.collection("organizations")
              .doc(this.$route.params.oid)
              .collection("banners")
              .doc(banner.id)
              .update(banner);
          }
        }
        this.refreshTable();
      }
    },
    updateStatus(banner) {
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("banners")
        .doc(banner.id)
        .update(banner)
        .then(() => {
          this.$refs.edit.hide();
          toast.fire({
            title: "Update status successfully",
          });
        });
    },
    refreshTable() {
      this.$binding(
        "banners",
        db
          .collection("organizations")
          .doc(this.$route.params.oid)
          .collection("banners")
      ).then((banners) => {
        this.banners = banners.sort((a, b) => a.flowNo - b.flowNo); // => __ob__: Observer
        // console.log(this.banners);
      });
    },
    updateBanner() {
      this.banner.timestamp = firebase.firestore.FieldValue.serverTimestamp();
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("banners")
        .doc(this.banner.id)
        .update(this.banner)
        .then(() => {
          this.$refs.edit.hide();
          toast.fire({
            title: "Updated successfully",
          });
        });
    },
    deleteBanner(doc) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          db.collection("organizations")
            .doc(this.$route.params.oid)
            .collection("banners")
            .doc(doc.id)
            .delete();

          for (var img in doc.images) {
            fbAuth
              .storage()
              .refFromURL(doc.images[img])
              .delete()
              .then(() => {
                console.log("image deleted");
              })
              .catch((err) => {
                console.log(err);
              });
            // console.log(doc.images[img])
          }

          toast.fire({
            title: "Deleted successfully",
          });
        }
      });
    },
    searchNow() {
      this.searchResults = this.banners.filter((banner) => {
        if (
          banner.title[this.$i18n.locale]
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) != "-1"
        ) {
          return banner;
        }
      });
    },
  }, // -- end methods -- //
  created() {
    // Binding Collections
    this.$binding(
      "banners",
      db
        .collection("organizations")
        .doc(this.$route.params.oid)
        .collection("banners")
    ).then((banners) => {
      this.banners = banners; // => __ob__: Observer
      //console.log(this.banners);
    });
  },
};
</script>
<style scoped>
.text-normal {
  text-transform: none;
}
.img-small-wrap {
  position: relative;
}
.img-small-wrap .delete-img {
  position: absolute;
  top: -14px;
  right: -2px;
  cursor: pointer;
}
</style>
