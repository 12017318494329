<template>
  <div class="Categories">
    <div>
      <div class="row mx-0">
        <div class="row col-12 align-items-center">
          <div class="col-9">
            <h2>{{ $t("adminPage.categories.title") }}</h2>
            <p>{{ $t("adminPage.categories.text") }}</p>
          </div>
          <div class="col-3">
            <img
              src="@/assets/img/categories.svg"
              class="img-fluid"
              alt="overview image"
            />
          </div>
        </div>

        <div class="row col-12 justify-content-end">
          <label :for="filterInput" class="col-form-label">{{
            $t("search.label")
          }}</label>
          <div class="col-12 col-lg-10">
            <div class="input-group">
              <input
                @input="searchNow()"
                v-model="search"
                class="form-control"
                type="search"
                id="filterInput"
                :placeholder="$t('search.input')"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  :disabled="!search"
                  @click="search = ''"
                >
                  {{ $t("search.clear") }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row mx-auto col-12 mt-2 justify-content-between">
          <div>
            <div class="row justify-content-between" style="max-width: 400px">
              <div>
                <b-pagination
                  class="customPagination col-9"
                  pills
                  v-model="currentPage"
                  :total-rows="categories.length"
                  :per-page="perPage"
                ></b-pagination>
              </div>
              <div class="mx-2">
                <a class="fas fa-undo fa-2x"> </a>
              </div>
            </div>
          </div>
          <div class="ml-1">
            <button class="btn btn-success" @click="createNew">
              {{ $t("adminPage.categories.list-button") }}
            </button>
          </div>
        </div>

        <div class="row col-lg-12 p-0 m-1">
          <div
            v-for="(category, bi) in !search ? categories : searchResults"
            :key="bi"
            class="col-lg-6 col-md-6 col-12 mx-auto"
          >
            <div class="card m-2 row p-2">
              <div class="col-3 mx-auto">
                <img
                  v-if="category.images.length > 0"
                  :src="category.images[0]"
                  alt="category images"
                  class="img-fluid mx-auto"
                  style="width: 80px"
                />
                <div class="row mx-auto">
                  {{
                    category.timestamp
                      ? new Date(category.timestamp.seconds * 1000)
                          .toISOString()
                          .split("T")[0]
                      : ""
                  }}
                </div>
              </div>
              <div class="col-3">
                <span class="row m-0"> {{ category.title[$i18n.locale] }}</span>
              </div>
              <div class="col-4 m-0 p-1">
                <div class="row mx-auto m-1">
                  <button
                    class="btn btn-primary mx-1"
                    @click="editCategory(category)"
                  >
                    <i class="fas p-1 fa-edit"></i>
                  </button>
                  <button
                    class="btn btn-danger mx-1"
                    @click="deleteCategory(category)"
                  >
                    <i class="fas p-1 fa-trash"></i>
                  </button>
                </div>
              </div>
              <div class="col-2 m-0">
                <div class="row m-1 justify-content-center">
                  <input
                    type="checkbox"
                    v-model="category.status"
                    @change="updateStatus(category)"
                    class="form-check-input"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <b-modal ref="edit" centered id="category" size="xl">
          <template #modal-title>
            <div class="d-flex flex-row justify-content-between">
              <div v-if="modal === 'new'" class="pt-2">
                {{ $t("adminPage.categories.add-category.new-title") }}
              </div>
              <div v-if="modal === 'edit'" class="pt-2">
                {{ $t("adminPage.categories.add-category.edit-title") }}
              </div>
              <div class="ml-3">
                <b-dropdown tag="li" class="nav-item">
                  <b-dropdown-toggle tag="a" navLink waves-fixed>
                    <strong>{{ $i18n.locale }}</strong>
                  </b-dropdown-toggle>
                  <b-dropdown-item
                    @click.prevent="$setLocale(lang)"
                    v-for="(lang, i) in $i18n.availableLocales"
                    :key="`Lang${i}`"
                    :value="lang"
                  >
                    {{ $i18n.locale }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>

            <b-row>
              <div class="col-lg-8">
                <input
                  class="form-control"
                  label="Identical - Name"
                  type="text"
                  v-model="category.name"
                />
                <input
                  class="form-control"
                  :label="$t('adminPage.categories.add-category.category-name')"
                  type="text"
                  v-model="category.title[$i18n.locale]"
                />
                <div class="input-group">
                  <div class="input-group-prepend mb-4">
                    <span class="input-group-text" id="basic-addon">
                      <i class="fas fa-pencil-alt prefix"></i>
                    </span>
                    <vue-editor
                      class="w-100 h-75 text-normal"
                      v-model="category.info[$i18n.locale]"
                    ></vue-editor>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="form-group"></div>
                <div class="input-group">
                  <b-form-file
                    @change="uploadImage"
                    class="mb-3"
                    accept="image/*"
                    multiple
                    :placeholder="
                      $t('adminPage.categories.add-category.choose')
                    "
                    drop-placeholder="Drop file here..."
                  >
                    <template v-slot:file-name="{ names }">
                      <b-badge variant="dark">{{ names[0] }}</b-badge>
                      <b-badge
                        v-if="names.length > 1"
                        variant="dark"
                        class="ml-1"
                      >
                        + {{ names.length - 1 }} More files
                      </b-badge>
                    </template>
                  </b-form-file>
                </div>
                <div class="form-group d-flex pt-2">
                  <div
                    class="p-1"
                    v-for="(image, index) in category.images"
                    :key="index"
                  >
                    <div class="img-small-wrap">
                      <img
                        v-if="image"
                        :src="image"
                        :alt="category.title[$i18n.locale]"
                        width="80px"
                      />
                      <span
                        class="delete-img"
                        @click="deleteImage(image, index)"
                        >X</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </b-row>
          </template>
          <template v-slot:modal-footer="{ cancel }">
            <button
              class="btn btn-success"
              @click="addCategory"
              v-if="modal == 'new'"
            >
              {{ $t("adminPage.categories.add-category.create") }}
            </button>
            <button
              class="btn btn-success"
              @click="updateCategory()"
              v-if="modal == 'edit'"
            >
              {{ $t("adminPage.categories.add-category.save") }}
            </button>
            <button class="btn btn-danger" @click="cancel()">
              {{ $t("adminPage.categories.add-category.cancel") }}
            </button>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { db, fbAuth } from "@/configs/firebase";
import firebase from "firebase/compat/app";

export default {
  name: "AdminCategories",
  components: {
    VueEditor,
  },
  data() {
    return {
      page: 1,
      perPage: 9,
      currentPage: 1,
      categories: [],
      category: {
        name: "",
        title: { en: "", th: "" },
        info: { en: "", th: "" },
        images: [],
        flowNo: 0,
        status: 0,
      },
      modal: null,
      loading: false,
      search: "",
      searchResults: [],
    };
  },
  methods: {
    enableRole() {
      let moduleindex = this.$store.state.currentRole.enableModules.findIndex(
        (x) => x.name.toLowerCase() == "products"
      );
      let isActive =
        moduleindex > -1
          ? this.$store.state.currentRole.enableModules[moduleindex].isActive
          : false;
      if (
        this.$store.state.currentRole != null &&
        moduleindex > -1 &&
        isActive
      ) {
        return true;
      }
      return false;
    },
    
    
    uploadImage(e) {
      if (e.target.files) {
        for (let i = 0; i < e.target.files.length; i++) {
          let file = e.target.files[i];
          this.multiUpload(file);
        }
      }
    },

    multiUpload(file) {
      const oid = this.$route.params.oid;
      const storageRef = fbAuth
        .storage()
        .ref(`organizations/${oid}/categories/${file.name}`);
      const uploadTask = storageRef.put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            let images = this.category.images || [];
            images.push(downloadURL); // Add the new download URL
            this.category.images = images; // Update the images array
          });
        }
      );
    },
    deleteImage(img, index) {
      let image = fbAuth.storage().refFromURL(img);
      // console.log(image);
      this.category.images.splice(index, 1);
      image
        .delete()
        .then(() => {
          console.log("Image deleted");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleChange(v) {
      this.files = v;
    },
    reset() {
      this.category = {
        name: "",
        title: { en: "", th: "" },
        info: { en: "", th: "" },
        images: [],
        flowNo: 0,
        status: 0,
      };
    },
    createNew() {
      this.modal = "new";
      this.reset();
      this.$refs.edit.show();
    },
    addCategory() {
      this.category.timestamp = firebase.firestore.FieldValue.serverTimestamp();
      this.category.flowNo = this.categories.length;
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("categories")
        .add(this.category);
      this.$refs.edit.hide();
      // .catch(function(error) {
      //     console.error("Error adding document: ", error);
      // });
    },
    editCategory(category) {
      this.modal = "edit";
      this.category = category;
      this.activeItem = JSON.stringify(category);
      this.$refs.edit.show();
    },
    updateStatus(category) {
      category.timestamp = firebase.firestore.FieldValue.serverTimestamp();
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("categories")
        .doc(category.id)
        .update(category)
        .then(() => {
          this.$refs.edit.hide();
          toast.fire({
            title: "Update status successfully",
          });
        });
    },
    updateSeq(category, index, move) {
      if (index + move < this.categories.length && index + move > -1) {
        category.flowNo = index + move;
        if (move > 0) {
          if (index + 1 < this.categories.length) {
            this.categories.find((p) => p.id == category.id).flowNo = index + 1;
            this.categories[index + 1].flowNo = index;
            db.collection("organizations")
              .doc(this.$route.params.oid)
              .collection("categories")
              .doc(category.id)
              .update(category);
          }
        } else {
          if (index - 1 > -1) {
            this.categories[index - 1].flowNo = index;
            this.categories.find((p) => p.id == category.id).flowNo = index - 1;
            db.collection("organizations")
              .doc(this.$route.params.oid)
              .collection("categories")
              .doc(category.id)
              .update(category);
          }
        }
        this.refreshTable();
      }
      console.log(this.categories);
    },
    refreshTable() {
      this.$binding(
        "categories",
        db
          .collection("organizations")
          .doc(this.$route.params.oid)
          .collection("categories")
      ).then((categories) => {
        this.categories = categories.sort((a, b) => a.flowNo - b.flowNo); // => __ob__: Observer
        //console.log(this.categories);
      });
    },
    updateCategory() {
      // Update function has issues so I have to apply this work-around
      // this.$firestore.categories.doc(this.category['.key']).set(this.category).then(() => {
      //   this.$firestore.categories.doc(this.activeItem).delete()}).then(()=>{
      // this.$refs.edit.hide()
      // toast.fire({
      //     type: 'success',
      //     title: 'Updated successfully'
      // });
      //   });

      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("categories")
        .doc(this.category.id)
        .update(this.category)
        .then(() => {
          this.$refs.edit.hide();
          toast.fire({
            title: "Updated successfully",
          });
        });
    },
    deleteCategory(doc) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          db.collection("organizations")
            .doc(this.$route.params.oid)
            .collection("categories")
            .doc(doc.id)
            .delete();

          for (var img in doc.images) {
            fbAuth
              .storage()
              .refFromURL(doc.images[img])
              .delete()
              .then(() => {
                console.log("image deleted");
              })
              .catch((err) => {
                console.log(err);
              });
            // console.log(doc.images[img])
          }

          toast.fire({
            title: "Deleted successfully",
          });
        }
      });
    },
    searchNow() {
      this.searchResults = this.categories.filter((category) => {
        if (
          category.name.toLowerCase().indexOf(this.search.toLowerCase()) != "-1"
        ) {
          return category;
        }
      });
    },
  }, // -- end methods -- //
  created() {
    // Binding Collections
    this.$binding(
      "categories",
      db
        .collection("organizations")
        .doc(this.$route.params.oid)
        .collection("categories")
    ).then((categories) => {
      this.categories = categories.sort((a, b) => a.flowNo - b.flowNo); // => __ob__: Observer
      console.log(this.categories);
    });
  },
};
</script>
<style scoped>
.img-small-wrap {
  position: relative;
}
.img-small-wrap .delete-img {
  position: absolute;
  top: -14px;
  right: -2px;
  cursor: pointer;
}
</style>
