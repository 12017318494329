import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth"; // Import getAuth
import "firebase/storage"; // If you're using Firebase Storage

const firebaseConfig = {
  apiKey: "AIzaSyAr6RxZ4KArushcP4UVIplLcjrulKvTidQ",
  authDomain: "zjinncloud.firebaseapp.com",
  projectId: "zjinncloud",
  storageBucket: "zjinncloud.appspot.com",
  messagingSenderId: "410923979422",
  appId: "1:410923979422:web:0913184c691f123037218f",
  measurementId: "G-PEKW75Q5W4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const db = getFirestore(app);
const functions = getFunctions(app);
const fbAuth = getAuth(app); // Initialize Firebase Auth

export { fbAuth, db, functions };
