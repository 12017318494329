<template>
  <div class="jpage-card col-12 m-0 py-1 px-0">
    <b-row class="m-1 mt-1 d-flex justify-content-between">
      <div class="row col-lg-6 col-sm-12 justify-content-between">
        <div>
          <h4 v-if="mode === 'new'" class="p-0">
            {{ $t("adminPage.entries.add-entry.new-title") }}
          </h4>
          <h4 v-if="mode === 'edit'" class="p-0">
            {{ $t("adminPage.entries.add-entry.edit-title") }}: {{ entry.id }}
          </h4>
        </div>
        <div class="row">
          <div class="m-1 p-0" style="background-color: white; border: none">
            {{ $i18n.fullLang }}
          </div>
          <b-dropdown size="sm" tag="li" variant="link" no-caret>
            <b-dropdown-toggle tag="a" class="m-0 p-0" navLink waves-fixed
              ><strong>{{ $i18n.fullLang }}</strong></b-dropdown-toggle
            >
            <template #button-content>
              <span class="material-icons"> language </span>
            </template>
            <b-dropdown-item
              @click.prevent="$setLocale(lang)"
              v-for="(lang, lgi) in $i18n.availableLocales"
              :key="`Lang${lgi}`"
              :value="lang"
              >{{ $i18n.locale }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>

      <div class="row col-lg-6 col-sm-12 justify-content-end pr-1">
        <div class="input-group-prepend">
          <div v-if="!isEntryModule" class="row">
            <label class="mt-1" for="name" style="color: red">{{
              $t("adminPage.entries.add-entry.master-entry")
            }}</label>
            <div class="m-1 p-0 mx-2">
              {{
                entry.master
                  ? entry.master.title[$i18n.locale]
                  : $t("adminPage.entries.add-entry.no-master-entry")
              }}
            </div>
          </div>
          <b-dropdown
            v-if="!isEntryModule"
            size="sm"
            tag="li"
            variant="link"
            no-caret
          >
            <template #button-content>
              <span class="material-icons md-light md-14"> assignment </span>
            </template>
            <b-dropdown-item
              @click.prevent="setMasterEntry(msevnt)"
              v-for="(msevnt, i) in entries"
              :key="'rex' + i"
              :value="msevnt"
              ><span v-if="entry.id != msevnt.id">
                {{ msevnt.title[$i18n.locale] }}</span
              >
              <span v-else
                >{{ $t("adminPage.entries.add-entry.no-master-entry") }}
              </span></b-dropdown-item
            >
          </b-dropdown>
        </div>
        <div class="input-group-prepend">
          <div>
            <div class="m-1 p-0">
              {{ entry.payment[$i18n.locale] }}
            </div>
          </div>
          <b-dropdown size="sm" tag="li" variant="link" no-caret>
            <b-dropdown-toggle tag="a" navLink waves-fixed
              ><strong>{{
                entry.payment[$i18n.locale]
              }}</strong></b-dropdown-toggle
            >
            <template #button-content>
              <span class="material-icons md-light md-14"> paid </span>
            </template>
            <b-dropdown-item
              @click.prevent="setEntryPaymentType(type)"
              v-for="(type, i) in payTypeOption"
              :key="'rix' + i"
              :value="type"
              >{{ type[$i18n.locale] }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
        <div class="input-group-prepend">
          <div class="m-1 p-0">
            {{ entry.type[$i18n.locale] }}
          </div>
          <b-dropdown size="sm" tag="li" variant="link" no-caret>
            <b-dropdown-toggle tag="a" navLink waves-fixed
              ><strong>{{
                entry.type[$i18n.locale]
              }}</strong></b-dropdown-toggle
            >
            <template #button-content>
              <span class="material-icons md-14"> entry </span>
            </template>
            <b-dropdown-item
              @click.prevent="setEntryType(type)"
              v-for="(type, i) in entryTypeOption"
              :key="'rtx' + i"
              :value="type"
              >{{ type[$i18n.locale] }}</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </b-row>
    <b-row class="row g-0 m-0 p-0">
      <b-row class="col-lg-6 col-sm-12 m-0 p-0">
        <b-col class="mx-auto">
          <div>
            <label class="mt-1" for="name">{{
              $t("adminPage.entries.add-entry.entry-name")
            }}</label>
            <b-input type="text" v-model="entry.title[$i18n.locale]" />
          </div>
          <label class="mt-1" for="name">{{
            $t("adminPage.entries.add-entry.database-file")
          }}</label>
          <div>
            <b-form-select
              v-model="entry.databaseFile"
              :options="databaseFileOptions"
            ></b-form-select>
          </div>
          <div class="input-group">
            <b-input
              class="input-group-prepend"
              disabled
              type="text"
              v-model="entry.databaseFile"
            />
            <div class="input-group-append">
              <i
                @click="gotoEditDatabase(entry.databaseFile, entry.id)"
                class="input-group-text fas fa-edit"
              ></i>
            </div>
          </div>
          <b-row class="col-12 mt-2">
            <span class="mr-2">
              <b-button
                size="sm"
                @click="addMenus()"
                class="pt-1 pb-1 pr-2 pl-2"
                ><i class="fas fa-plus"></i>
              </b-button>
            </span>
          </b-row>
          <!-- <label class="mt-1 mb-0">{{
            $t("adminPage.add-entry.n-menu")
          }}</label>
          <div v-for="(menu, mindex) in entry.menus" :key="mindex">
            <b-input
              type="text"
              v-model="entry.menu[mindex].title[$i18n.locale]"
            /> 
          </div>-->
          <div v-if="!isEntryModule">
            <label class="mt-1" for="name">{{
              $t("adminPage.events.add-event.start-time")
            }}</label>
            <div class="input-group">
              <div class="row col-12 mx-1">
                <b-form-datepicker
                  class="col-6"
                  initial-date="YYYY-MM-DD"
                  v-model="startEntryDate"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  :min="min"
                  :max="max"
                  locale="en-US"
                ></b-form-datepicker>
                <b-form-timepicker
                  class="col-6"
                  v-model="startEntryTime"
                  :hour12="true"
                  :min="min"
                  :max="max"
                  locale="en-US"
                ></b-form-timepicker>
              </div>
            </div>
            <label class="mt-1" for="name">{{
              $t("adminPage.events.add-event.end-time")
            }}</label>
            <div class="input-group">
              <div class="row col-12 mx-1">
                <b-form-datepicker
                  class="col-6"
                  v-model="endEntryDate"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  :min="min"
                  :max="max"
                  locale="en"
                ></b-form-datepicker>
                <b-form-timepicker
                  class="col-6"
                  v-model="endEntryTime"
                  :hour12="true"
                  :min="min"
                  :max="max"
                  locale="en-US"
                ></b-form-timepicker>
              </div>
            </div>
            <div>
              <label class="mt-1" for="name">{{
                $t("adminPage.entries.add-entry.entry-name")
              }}</label>
              <b-input type="text" v-model="entry.downloadUrl" />
            </div>

            <b-row class="col-12 mt-2">
              <span class="mr-2">
                <b-button
                  size="sm"
                  @click="addQuestion()"
                  class="pt-1 pb-1 pr-2 pl-2"
                  ><i class="fas fa-plus"></i>
                </b-button>
              </span>
              <label class="mt-1 mb-0">{{
                $t("adminPage.entries.add-entry.questions")
              }}</label>
            </b-row>
            <div
              v-for="(quiz, qindex) in entry.questions"
              :key="'qxx' + qindex"
              class="row mx-0 p-1 mt-1 mb-2 question"
            >
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    {{ $t("adminPage.entries.add-entry.question") }}
                  </div>
                </div>
                <b-input
                  type="text"
                  class="form-control"
                  v-model="entry.questions[qindex].title[$i18n.locale]"
                />
                <div class="input-group-append">
                  <div
                    v-if="mode == 'edit'"
                    @click="gotoEditQuestion(entry.id, qindex)"
                    class="input-group-text"
                  >
                    <i class="fas fa fa-id-card"></i>
                  </div>
                  <div @click="removeQuestion(qindex)" class="input-group-text">
                    <i class="fas fa-trash"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ $t("adminPage.entries.add-entry.info") }}
                </div>
              </div>
              <b-textarea
                type="text"
                class="form-control"
                v-model="entry.questions[qindex].info[$i18n.locale]"
              />
            </div>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ $t("adminPage.entries.add-entry.min-select") }}
                </div>
              </div>
              <b-input
                type="text"
                class="form-control"
                v-model="entry.questions[qindex].minSelect"
              />
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ $t("adminPage.entries.add-entry.max-select") }}
                </div>
              </div>
              <b-input
                type="text"
                class="form-control"
                v-model="entry.questions[qindex].maxSelect"
              />
            </div>
            <div class="input-group">
              <div class="input-group-text">
                {{ "ประเภท คำถาม" }}
              </div>
              <div class="input-group-prepend">
                <div
                  class="input-group-text m-0"
                  style="background-color: white"
                >
                  {{ entry.questions[qindex].type[$i18n.locale] }}
                </div>
              </div>
              <b-dropdown size="sm" tag="li" variant="link" no-caret>
                <template #button-content>
                  <span class="material-icons"> arrow_drop_down </span>
                </template>
                <b-dropdown-item
                  @click.prevent="setQuestionType(type, qindex)"
                  v-for="(type, i) in qTypeOption"
                  :key="'qx' + i"
                  :value="type"
                  >{{ type[$i18n.locale] }}</b-dropdown-item
                >
              </b-dropdown>
              <div class="input-group-text">
                <input
                  type="checkbox"
                  :aria-checked="entry.questions[qindex].isScheduled"
                  v-model="entry.questions[qindex].isScheduled"
                />
                <label class="m-1" for="isScheduled">{{
                  " ตั้งกำหนดเวลา "
                }}</label>
              </div>
              <div
                v-if="entry.questions[qindex].isScheduled"
                class="input-group-text input-group-append"
              >
                {{ "ระยะเวลา (นาที)" }}
              </div>
              <div class="input-group-text px-1">
                <b-input
                  v-if="entry.questions[qindex].isScheduled"
                  type="number"
                  style="width: 70px"
                  class="px-1"
                  @change="convertToEndDate(qindex)"
                  v-model="entry.questions[qindex].minduration"
                />
              </div>
            </div>

            <label
              v-if="entry.questions[qindex].isScheduled"
              class="mt-1 mx-auto"
              for="name"
              >{{ "เวลาเริ่ม " }}</label
            >
            <div v-if="entry.questions[qindex].isScheduled" class="input-group">
              <div class="row col-12">
                <b-form-datepicker
                  format="YYYY-MM-DD"
                  @input="convertToEndDate(qindex)"
                  class="col-6"
                  v-model="entry.questions[qindex].sdate"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  :min="min"
                  :max="max"
                  locale="en"
                ></b-form-datepicker>
                <b-form-timepicker
                  class="col-6"
                  @input="convertToEndDate(qindex)"
                  v-model="entry.questions[qindex].stime"
                  :hour12="true"
                  :min="min"
                  :max="max"
                  locale="en-US"
                ></b-form-timepicker>
              </div>
            </div>
          </div>
          <label class="mt-1" for="name">{{
            $t("adminPage.entries.add-entry.entry-details")
          }}</label>
          <div class="form-group" style="width: 100%">
            <vue-editor
              style="width: 100%; text-transform: none"
              class="text-normal"
              v-model="entry.info[$i18n.locale]"
              :editor-toolbar="customToolbar"
            ></vue-editor>
          </div>
        </b-col>
      </b-row>
      <b-row class="col-lg-6 col-sm-12 m-0 p-0">
        <b-col class="mx-auto">
          <div class="row mx-0 p-1 justify-content-between">
            <div class="row mx-2">
              <div
                class="p-0 mr-5"
                style="background-color: white; border: none"
              >
                {{
                  registerRequiredOptions[selectRegisterOptionId].value.label[
                    $i18n.locale
                  ]
                }}
              </div>
              <b-dropdown size="sm" tag="li" variant="link" no-caret>
                <template #button-content>
                  <span class="material-icons">
                    keyboard_double_arrow_down
                  </span>
                </template>
                <b-dropdown-item
                  @click="selectRegisterOptionId = i"
                  v-for="(regist, i) in registerRequiredOptions"
                  :key="'rx' + i"
                  :value="regist"
                  >{{ regist.value.label[$i18n.locale] }}</b-dropdown-item
                >
              </b-dropdown>
            </div>
            <div class="row mx-2">
              <span class="m-1">
                <b-button
                  size="sm"
                  @click.prevent="addRegisterRequired(selectRegisterOptionId)"
                  class="pt-1 pb-1 pr-2 pl-2"
                  ><i class="fas fa-plus"></i>
                </b-button>
              </span>
              <label class="mt-1 mb-0">{{
                $t("adminPage.entries.add-entry.add-form-required")
              }}</label>
            </div>
          </div>
          <div
            v-for="(regist, rindex) in entry.registerRequired"
            :key="'rqui' + rindex"
            class="row col-12 mx-0 p-1 mt-1 mb-2 question"
          >
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ "label" }}
                </div>
              </div>
              <b-input
                type="text"
                class="form-control"
                v-model="entry.registerRequired[rindex].label[$i18n.locale]"
              />
              <div class="input-group-append">
                <div class="input-group-text">Prefered</div>
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    v-model="entry.registerRequired[rindex].isPrefered"
                  />
                </div>
              </div>
              <div class="input-group-append">
                <div class="input-group-text">Required</div>
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    @input="
                      entry.registerRequired[rindex].isRequired
                        ? (entry.registerRequired[rindex].isPrefered = true)
                        : (entry.registerRequired[rindex].isRequired =
                            entry.registerRequired[rindex].isRequired)
                    "
                    v-model="entry.registerRequired[rindex].isRequired"
                  />
                </div>
              </div>
              <div class="input-group-append">
                <div
                  @click.prevent="removeRegisterRequired(rindex)"
                  class="input-group-text"
                >
                  <i class="fas fa-trash"></i>
                </div>
              </div>
            </div>
          </div>
          <div v-if="entry.payment.en != 'Free'" class="form-group">
            <div>
              <label class="mt-1" for="name">{{
                $t("adminPage.entries.add-entry.payment-price")
              }}</label>
              <b-input type="text" v-model="entry.payment.price" />
            </div>
            <div>
              <label class="mt-1" for="name">{{
                $t("adminPage.entries.add-entry.payment-term")
              }}</label>
              <b-textarea
                type="text"
                v-model="entry.payment.term[$i18n.locale]"
              />
            </div>
          </div>
          <b-row class="col-12 my-2">
            <span class="mr-2">
              <b-button
                size="sm"
                @click="addMenus()"
                class="pt-1 pb-1 pr-2 pl-2"
                ><i class="fas fa-plus"></i>
              </b-button>
            </span>
            <label class="mt-1 mb-0">{{
              $t("adminPage.entries.add-entry.add-form-menu")
            }}</label>
          </b-row>

          <div
            v-for="(menu, mix) in entry.menus"
            :key="'mx' + mix"
            class="row col-12 mx-0 p-1 mt-1 mb-2 question"
          >
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ "title" }}
                </div>
              </div>
              <b-input
                type="text"
                class="form-control"
                v-model="entry.menus[mix].title[$i18n.locale]"
              />
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ "url" }}
                </div>
              </div>
              <b-input
                type="text"
                class="form-control text-normal"
                v-model="entry.menus[mix].url"
              />
              <div class="input-group-append">
                <div @click.prevent="removeMenu(mix)" class="input-group-text">
                  <i class="fas fa-trash"></i>
                </div>
              </div>
            </div>
          </div>
          <b-row class="col-12 my-2">
            <span class="mr-2">
              <b-button
                size="sm"
                @click="addColorTheme()"
                class="pt-1 pb-1 pr-2 pl-2"
                ><i class="fas fa-plus"></i>
              </b-button>
            </span>
            <label class="mt-1 mb-0">{{
              $t("adminPage.entries.add-entry.add-color")
            }}</label>
          </b-row>
          <div
            v-for="(color, cix) in entry.colorThemes"
            :key="'ccol' + cix"
            class="row col-12 mx-0 p-1 mt-1 mb-2 question"
          >
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ "title" }}
                </div>
              </div>
              <b-input
                type="text"
                class="form-control"
                v-model="entry.colorThemes[cix].title"
              />
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ "Color" }}
                </div>
              </div>
              <b-input
                type="text"
                class="form-control text-normal"
                v-model="entry.colorThemes[cix].colorcode"
              />
            </div>
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ entry.colorThemes[cix].type }}
                </div>
              </div>

              <b-dropdown size="sm" tag="li" variant="link" no-caret>
                <template #button-content>
                  <span class="material-icons">
                    keyboard_double_arrow_down
                  </span>
                </template>
                <b-dropdown-item
                  @click.prevent="setColorThemeType(cix, xtype)"
                  v-for="(xtype, ci) in typeColorField"
                  :key="'tci' + ci"
                  :value="xtype"
                  >{{ xtype }}</b-dropdown-item
                >
              </b-dropdown>
              <!--  condition -->
              <div class="input-group-prepend">
                <div class="input-group-text">
                  {{ entry.colorThemes[cix].condition }}
                </div>
              </div>

              <b-dropdown size="sm" tag="li" variant="link" no-caret>
                <template #button-content>
                  <span class="material-icons">
                    keyboard_double_arrow_down
                  </span>
                </template>
                <b-dropdown-item
                  @click.prevent="setConditionColorTheme(cix, condition)"
                  v-for="(condition, ci) in conditionColorField"
                  :key="'cotyp' + ci"
                  :value="condition"
                  >{{ condition }}</b-dropdown-item
                >
              </b-dropdown>
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <b-button @click="entry.colorThemes[cix].options.push('')">
                    add</b-button
                  >
                </div>
              </div>

              <div
                v-for="(tyeof, tindex) in entry.colorThemes[cix].options"
                :key="tindex"
                class="input-group-append"
              >
                <div class="input-group-text">{{ tindex + 1 }}</div>
                <div
                  @click="removeColorOptions(cix, tindex)"
                  class="input-group-text"
                >
                  X
                </div>
                <div class="input-group-text" style="width: 120px">
                  <input
                    type="text"
                    style="width: 100px"
                    v-model="entry.colorThemes[cix].options[tindex]"
                  />
                </div>
              </div>

              <div class="input-group-append">
                <div
                  @click.prevent="removeColorTheme(cix)"
                  class="input-group-text"
                >
                  <i class="fas fa-trash"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="input-group">
            <b-form-file
              @change="uploadImage"
              class="mb-3"
              accept="image/*"
              multiple
              :placeholder="$t('adminPage.entries.add-entry.choose')"
              drop-placeholder="Drop file here..."
            >
              <template v-slot:file-name="{ names }">
                <b-badge variant="dark">{{ names[0] }}</b-badge>
                <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
                  + {{ names.length - 1 }} More files
                </b-badge>
              </template>
            </b-form-file>
          </div>
          <div class="form-group d-flex pt-2">
            <div
              class="p-1"
              v-for="(image, index) in entry.images"
              :key="index"
            >
              <div class="img-small-wrap">
                <img
                  v-if="image"
                  :src="image"
                  :alt="entry.title[$i18n.locale]"
                  width="80px"
                />
                <span class="delete-img" @click="deleteImage(image, index)"
                  >X</span
                >
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-row>
    <b-row class="row col-12" align-h="end">
      <b-button
        pill
        size="sm"
        variant="success"
        @click="addEntry"
        class="mx-2"
        v-if="mode == 'new'"
        >{{ $t("adminPage.entries.add-entry.create") }}</b-button
      >
      <b-button
        pill
        size="sm"
        variant="success"
        @click="saveEntry()"
        v-if="mode == 'edit'"
        class="mx-2"
        >{{ $t("adminPage.entries.add-entry.save") }}</b-button
      >
      <b-button
        pill
        size="sm"
        variant="danger"
        class="mx-2"
        @click="changeMode(null)"
        >{{ $t("adminPage.entries.add-entry.cancel") }}</b-button
      >
    </b-row>
  </div>
</template>
<script>
//let intervalTimer;
import { VueEditor } from "vue2-editor";
import { fbAuth, db } from "@/configs/firebase";
import firebase from "firebase/compat/app";
import moment from "moment";

export default {
  props: {
    entryX: {
      type: Object,
      required: true,
    },
    entries: {
      type: Array,
    },
    changeMode: {
      type: Function,
    },
    updateEntry: {
      type: Function,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  components: {
    VueEditor,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months prior
    const minDate = new Date(today);
    minDate.setMonth(minDate.getMonth() - 2);
    minDate.setDate(15);
    // 15th in two months
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth() + 24);
    maxDate.setDate(15);
    return {
      isEntryModule: true,
      selectRegisterOptionId: 0,
      databaseFileOptions: ["students", "officers", "members"],
      typeColorField: ["time", "number"],
      conditionColorField: ["more than", "less than", "between", "equal"],
      registerRequiredOptions: [
        {
          id: 0,
          value: {
            field: "name",
            id: 0,
            isRequired: true,
            isPrefered: true,
            label: { en: "Name", th: "ชื่อ" },
          },
        },
        {
          id: 1,
          value: {
            field: "email",
            id: 1,
            isRequired: true,
            isPrefered: true,
            label: { en: "email", th: "อีเมล์" },
          },
        },
        {
          id: 2,
          value: {
            field: "idcard",
            id: 2,
            isRequired: true,
            isPrefered: true,
            label: { en: "id card", th: "บัตรประชาชน" },
          },
        },
        {
          id: 3,
          value: {
            field: "telephone",
            id: 3,
            isRequired: true,
            isPrefered: true,
            label: { en: "telephone", th: "เบอร์โทร" },
          },
        },
        {
          id: 4,
          value: {
            field: "address",
            id: 4,
            isRequired: true,
            isPrefered: true,
            label: { en: "address", th: "ที่อยู่" },
          },
        },
        {
          id: 5,
          value: {
            field: "birthday",
            id: 5,
            isRequired: true,
            isPrefered: true,
            label: { en: "birthday", th: "วันเกิด" },
          },
        },
        {
          id: 6,
          value: {
            field: "age",
            id: 6,
            isRequired: true,
            isPrefered: true,
            label: { en: "age", th: "อายุ" },
          },
        },
        {
          id: 7,
          value: {
            field: "gender",
            id: 7,
            isRequired: true,
            isPrefered: true,
            label: { en: "gender", th: "เพศ" },
          },
        },
        {
          id: 8,
          value: {
            field: "zipconde",
            id: 8,
            isRequired: true,
            isPrefered: true,
            label: { en: "zipcode", th: "รหัสไปรยณีย์" },
          },
        },
        {
          id: 9,
          value: {
            field: "company",
            id: 9,
            isRequired: true,
            isPrefered: true,
            label: { en: "company", th: "หน่วยงาน" },
          },
        },
        {
          id: 10,
          value: {
            field: "ig",
            id: 10,
            isRequired: true,
            isPrefered: true,
            label: { en: "ig", th: "อินสตาแกรม" },
          },
        },
        {
          id: 11,
          value: {
            field: "lineid",
            id: 11,
            isRequired: true,
            isPrefered: true,
            label: { en: "line id", th: "ไอดี ไลน์" },
          },
        },
        {
          id: 12,
          value: {
            field: "facebook",
            id: 12,
            isRequired: true,
            isPrefered: true,
            label: { en: "facebook", th: "เฟสบุค" },
          },
        },
        {
          id: 13,
          value: {
            field: "grade",
            id: 13,
            isRequired: true,
            isPrefered: true,
            label: { en: "Grade", th: "ระดับชั้น" },
          },
        },
        {
          id: 14,
          value: {
            field: "class",
            id: 14,
            isRequired: true,
            isPrefered: true,
            label: { en: "class", th: "ห้อง" },
          },
        },
        {
          id: 15,
          value: {
            field: "memberid",
            id: 15,
            isRequired: true,
            isPrefered: true,
            label: { en: "Member Id", th: "เลขสมาชิก" },
          },
        },
        {
          id: 16,
          value: {
            field: "secretcode",
            id: 16,
            isRequired: true,
            isPrefered: true,
            label: { en: "Secret Code", th: "รหัสผ่าน" },
          },
        },
        {
          id: 999,
          value: {
            field: "consent",
            id: 999,
            isRequired: true,
            isPrefered: true,
            label: { en: "Consent", th: "การยอมรับ" },
            url: "",
          },
        },
      ],
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
      payTypeOption: [
        {
          en: "Free",
          th: "ไม่เสียค่าใช้จ่าย",
          price: 0,
          term: { en: "", th: "" },
        },
        {
          en: "Register Fee",
          th: "มีค่าใช้จ่าย",
          price: 0,
          term: { en: "", th: "" },
        },
      ],
      entryTypeOption: [
        {
          en: "student",
          th: "นักเรียน",
          disable: [],
          enable: [],
        },
        {
          en: "teacher",
          th: "ครู",
          disable: [],
          enable: [],
        },
        {
          en: "staff",
          th: "ทีมงาน",
          disable: [],
          enable: [],
        },
        {
          en: "officer",
          th: "พนักงาน",
          disable: [],
          enable: [],
        },
        {
          en: "member",
          th: "ระบบสมาชิก",
          disable: [],
          enable: [],
        },
      ],
      qTypeOption: [
        { en: "test", th: "ฝึกทำโจทย์", limitedTime: false },
        { en: "vote", th: "โหวต", limitedTime: true },
        { en: "quiz", th: "คำถาม", limitedTime: true },
        { en: "competition quiz", th: "คำถาม แข่งขัน", limitedTime: true },
      ],
      min: minDate,
      max: maxDate,
      currenetuser: null,
      uploadProgress: 0.0,
      startEntryDate: moment(new Date()).format("YYYY-MM-DD"),
      startEntryTime: moment(new Date()).format("HH:mm:ss"),
      endEntryDate: moment(new Date()).format("YYYY-MM-DD"),
      endEntryTime: moment(new Date()).format("HH:mm:ss"),
      entry: this.entryX,
    };
  },
  created() {
    if (this.mode == "edit") {
      let sDate = new Date(this.entry.starttime.seconds * 1000);
      let stimeoffset = sDate.getTimezoneOffset();
      sDate = new Date(sDate.getTime() - stimeoffset * 60 * 1000);
      this.startEntryDate = sDate.toISOString().split("T")[0];
      this.startEntryTime = sDate.toISOString().split("T")[1].slice(0, 8);
      let eDate = new Date(this.entry.endtime.seconds * 1000);
      let etimeoffset = eDate.getTimezoneOffset();
      eDate = new Date(eDate.getTime() - etimeoffset * 60 * 1000);
      this.endEntryDate = eDate.toISOString().split("T")[0];
      this.endEntryTime = eDate.toISOString().split("T")[1].slice(0, 8);
    } else {
      this.startEntryDate = moment(new Date()).format("YYYY-MM-DD");
      this.startEntryTime = moment(new Date()).format("HH:mm:ss");
      this.endEntryDate = moment(new Date()).format("YYYY-MM-DD");
      this.endEntryTime = moment(new Date()).format("HH:mm:ss");
    }
    //console.log(this.entry.starttime);
    this.checkFirebaseLoginState();
  },
  methods: {
    checkFirebaseLoginState() {
      fbAuth.auth().onAuthStateChanged((user) => {
        if (user) {
          this.currenetuser = user;
          console.log(user);
        } else {
          this.logged = false;
        }
      });
    },
    rndStr(len) {
      let text = "";
      let chars =
        "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      let ondate = moment(new Date()).format("YYYYMMDD");
      return ondate + text;
    },
    
    
    convertToEndDate(qindex) {
      // start time
      var sDate = new Date(
        this.entry.questions[qindex].sdate +
          "T" +
          this.entry.questions[qindex].stime
      );
      //let stimeoffset = sDate.getTimezoneOffset();
      //sDate = new Date(sDate.getTime() + stimeoffset * 60 * 1000);

      var seconds =
        Math.floor(sDate.getTime() / 1000) +
        this.entry.questions[qindex].minduration * 60;
      var eDate = new Date(seconds * 1000);

      this.entry.questions[qindex].starttime =
        firebase.firestore.Timestamp.fromDate(sDate);
      this.entry.questions[qindex].endtime =
        firebase.firestore.Timestamp.fromDate(eDate);
      //console.log("s date", sDate, " end date", eDate, "current", new Date());
    },
    gotoEditQuestion(entryId, qId) {
      this.$router.push(
        `/${this.$i18n.locale}/${this.$route.params.oid}/admin/entries/${entryId}/questions/${qId}`
      );
    },
    setQuestionType(type, index) {
      this.entry.questions[index].type = type;
      this.updateEntry(this.entry);
    },
    setColorThemeType(index, type) {
      this.entry.colorThemes[index].type = type;
      this.updateEntry(this.entry);
    },
    setConditionColorThemeType(index, condition) {
      this.entry.colorThemes[index].condition = condition;
      this.updateEntry(this.entry);
    },
    setEntryType(type) {
      this.entry.type = type;
      this.updateEntry(this.entry);
    },
    setMasterEntry(msentry) {
      if (msentry.id == this.entry.id) {
        this.entry.master = null;
      } else {
        this.entry.master = {
          id: msentry.id,
          title: { en: msentry.title.en, th: msentry.title.th },
          images: msentry.images,
        };
      }
      this.updateEntry(this.entry);
    },
    setEntryPaymentType(type) {
      this.entry.payment = type;
      this.updateEntry(this.entry);
    },
    addColorTheme() {
      this.entry.colorThemes = this.entry.colorThemes || [];
      this.entry.colorThemes.push({
        title: "",
        type: "time",
        condition: "between",
        options: [],
        colorcode: "red",
      });
      this.updateEntry(this.entry);
    },
    removeColorTheme(index) {
      this.entry.colorThemes.splice(index, 1);
      this.updateEntry(this.entry);
    },
    addMenus() {
      this.entry.menus = this.entry.menus || [];
      this.entry.menus.push({ title: { en: "", th: "" }, url: "" });
      this.updateEntry(this.entry);
    },
    addQuestion() {
      this.entry.questions = this.entry.questions || [];
      this.entry.questions.push({
        name: "",
        title: { en: "", th: "" },
        info: { en: "", th: "" },
        sdate: moment(new Date()).format("YYYY-MM-DD"),
        stime: moment(new Date()).format("HH:mm"),
        minduration: 60,
        starttime: null,
        endtime: null,
        isCompetition: true,
        author: { id: "", name: "", timestamp: "" },
        type: { en: "vote", th: "โหวต", limitedTime: true },
        minSelect: 1,
        maxSelect: 1,
      });
      this.updateEntry(this.entry);
    },
    addRegisterRequired(optionId) {
      //console.log(this.registerRequiredOptions[optionId]);
      this.entry.registerRequired = this.entry.registerRequired || [];
      this.entry.registerRequired.push(
        this.registerRequiredOptions[optionId].value
      );
      //console.log(this.entry.registerRequired);
      //this.updateRegistForm(this.entry, this.entry.registerRequired);
      this.updateEntry(this.entry);
    },
    removeQuestion(index) {
      this.entry.questions.splice(index, 1);
      this.updateEntry(this.entry);
    },
    removeRegisterRequired(index) {
      this.entry.registerRequired.splice(index, 1);
      this.updateEntry(this.entry);
    },
    removeMenu(index) {
      this.entry.menus.splice(index, 1);
      this.updateEntry(this.entry);
    },
    addEntry() {
      this.entry.flowNo = this.entries.length;
      if (this.entry.id == "") delete this.entry.id;
      console.log(this.$route.params.oid);
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("entries")
        .add(this.entry)
        .then((docRef) => {
          console.log(`Entry added successfully with ID: ${docRef.id}`);
          this.updateEntry(this.entry);
          this.changeMode(null);
        })
        .catch((error) => {
          console.error("Error updating entry: ", error);
          console.log("Error code:", error.code);
          console.log("Error message:", error.message);
          // If available, log the server response
          if (error.details) {
            console.log("Error details:", error.details);
          }
        });
    },
    saveEntry() {
      this.entry.timestamp = firebase.firestore.FieldValue.serverTimestamp();
      // start time
      var sDate = new Date(this.startEntryDate + "T" + this.startEntryTime);
      this.entry.starttime = firebase.firestore.Timestamp.fromDate(sDate);
      /// end time
      var eDate = new Date(this.endEntryDate + "T" + this.endEntryTime);
      this.entry.endtime = firebase.firestore.Timestamp.fromDate(eDate);

      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("entries")
        .doc(this.entry.id)
        .update(this.entry)
        .then(() => {
          //this.$refs.edit.hide();
          this.changeMode(null);
          this.updateEntry(this.entry);
          toast.fire({
            title: "Updated successfully",
          });
        });
    },
    handleChange(v) {
      this.files = v;
    },
    uploadImage(e) {
      const oid = this.$route.params.oid;
      let entryid = this.mode === "edit" ? this.entry.id : "new-entry";

      if (e.target.files) {
        for (let i = 0; i < e.target.files.length; i++) {
          let file = e.target.files[i];
          this.multiUpload(file, oid, entryid);
        }
      }
    },
    multiUpload(file, oid, entryid) {
      const filename = this.rndStr(4);
      const storageRef = fbAuth
        .storage()
        .ref(`organizations/${oid}/entries/${entryid}/${filename}`);
      const uploadTask = storageRef.put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
        },
        () => {
          // Handle successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            let images = this.entry.images || [];
            images.push(downloadURL); // Add the new download URL
            this.entry.images = images; // Update the images array
          });
        }
      );
    },
    deleteImage(img, index) {
      let image = fbAuth.storage().refFromURL(img);
      // console.log(image);
      this.entry.images.splice(index, 1);
      this.updateEntry(this.entry);
      image
        .delete()
        .then(() => {
          console.log("Image deleted");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    gotoEditDatabase(databaseFile, entryId) {
      this.$router.push(
        `/${this.$i18n.locale}/${this.$route.params.oid}/admin/database/${databaseFile}?entryId=${entryId}`
      );
    },
  },
};
</script>
<style scoped>
.jpage-card {
  font-size: 1em;
  overflow: hidden;
  padding: 5;
  border: none;
  border-radius: 0.28571429rem;
  box-shadow: 0 3px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  margin-top: 20px;
  margin-bottom: 20px;
}
.question {
  background-color: #dbdbec;
}
</style>
