<template>
  <div class="questions">
    <div v-if="!mode">
      <div class="row mx-0">
        <div class="row col-12 align-items-center">
          <div class="col-9 col-sm-9 col-md-9 col-lg-9">
            <h1>{{ $t("adminPage.questions.title") }}</h1>
            <p>{{ $t("adminPage.questions.text") }}</p>
          </div>
          <div class="col-3 col-sm-3 col-md-3 col-lg-3">
            <img
              src="@/assets/img/events.svg"
              class="img-fluid"
              alt="overview image"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="row mt-1 mx-0 justify-content-end">
            <i class="fas fa-list m-1" @click="gotoQuestionList()"
              ><span class="m-1">{{ "List" }}</span></i
            >
          </div>
        </div>
        <!-- body -->
        <div class="row col-12 justify-content-end">
          <b-form-group
            :label="$t('search.label')"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0 col-12 col-lg-10 col-md-10"
          >
            <b-input-group size="sm">
              <b-form-input
                @input="searchNow()"
                v-model="search"
                class="col-12"
                type="search"
                id="filterInput"
                :placeholder="$t('search.input')"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button :disabled="!search" @click="search = ''">{{
                  $t("search.clear")
                }}</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
        <div class="row mx-auto col-12 mt-2 justify-content-between">
          <div>
            <div class="row justify-content-between" style="max-width: 400px">
              <div>
                <b-pagination
                  class="customPagination col-9"
                  pills
                  v-model="currentPage"
                  :total-rows="questions.length"
                  :per-page="perPage"
                ></b-pagination>
              </div>
              <div></div>
              <div class="mx-2">
                <a class="fas fa-undo fa-2x"> </a>
              </div>
            </div>
          </div>
          <div class="ml-1">
            <b-button variant="success" @click="createNew">{{
              $t("adminPage.questions.add-question.create")
            }}</b-button>
          </div>
        </div>

        <!-- TABLE  -->
        <div class="col-lg-12 col-md-12 col-12 p-0 m-1">
          <div class="row m-1 mx-auto p-0">
            <div
              v-for="(quiz, ei) in !search ? questions : searchResults"
              :key="ei"
              class="jpage-card col-lg-5 col-md-5 col-12 mx-auto m-1"
              style="margin: 5px"
            >
              <div class="row p-2">
                <div class="col-3 col-lg-3">
                  <div class="col p-0">
                    <div v-if="quiz.images.length > 0">
                      <div v-for="(image, idImg) in quiz.images" :key="idImg">
                        <img
                          v-if="idImg == 0"
                          :src="image"
                          alt="quiz images"
                          class="img-fluid mx-auto"
                          style="width: 80px; height: 80px"
                        />
                      </div>
                    </div>
                    <div v-else style="height: 50px"></div>
                    <div class="row p-0 mx-auto">
                      {{ quiz.title }}
                    </div>
                  </div>
                </div>
                <div class="col-5 col-lg-6 col-md-6 p-0">
                  <p class="row m-0" v-html="quiz.question.substr(0, 100)"></p>
                  <!-- <span class="row text-right m-0">
                Info :{{ quiz.info[$i18n.locale] }}
              </span> -->
                </div>
                <div class="col-2 col-lg-2 col-md-2 m-0 p-0">
                  <b-button
                    pill
                    size="sm"
                    class="m-1"
                    variant="primary"
                    @click="gotoQuiz(quiz)"
                    ><i class="fas p-1 fa-home"></i
                  ></b-button>
                  <div v-if="enableRole()" class="row mx-auto m-1">
                    <b-button
                      pill
                      size="sm"
                      class="m-1"
                      variant="primary"
                      @click="editQuiz(quiz)"
                      ><i class="fas p-1 fa-edit"></i
                    ></b-button>
                    <b-button
                      pill
                      size="sm"
                      class="m-1"
                      variant="danger"
                      @click="deleteQuiz(quiz)"
                      ><i class="fas p-1 fa-trash"></i
                    ></b-button>
                  </div>
                </div>
                <div class="col-2 col-lg-1 col-md-1 m-0 my-auto">
                  <div
                    v-if="enableRole()"
                    class="row m-1 justify-content-center"
                  >
                    <b-form-checkbox
                      v-model="quiz.status"
                      @change="updateStatus(quiz)"
                      name="checkbox-1"
                      value="1"
                      class="m-1"
                      unchecked-value="0"
                    >
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>

            <!-- mode -->
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mx-1">
      <widget-admin-event-question-edit
        v-if="quiz && mode"
        :quizX="quiz"
        :questions="questions"
        :changeMode="changeMode"
        :updateQuiz="updateQuiz"
        :size="size"
        :mode="mode"
      ></widget-admin-event-question-edit>
      <div style="background-color: white"></div>
    </div>
  </div>
</template>
<script>
// Advanced Use - Hook into Quill's API for Custom Functionality

import WidgetAdminEventQuestionEdit from "../widgets/WidgetAdminEventQuestionEdit.vue";
import { fbAuth, db } from "@/configs/firebase";

import firebase from "firebase/compat/app";
import moment from "moment";

export default {
  name: "event-questions",
  props: {},
  components: {
    WidgetAdminEventQuestionEdit,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months prior
    const minDate = new Date(today);
    minDate.setMonth(minDate.getMonth() - 2);
    minDate.setDate(15);
    // 15th in two months
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth() + 2);
    maxDate.setDate(15);
    return {
      questions: [],
      perPage: 20,
      currentPage: 1,
      min: minDate,
      max: maxDate,
      size: 0,
      quiz: {
        id: "",
        lang: "th",
        title: "",
        author: { name: "", timestamp: null },
        question: "",
        solution: "",
        images: [],
        answers: [
          {
            isRight: false,
            answer: "",
            score: 1,
          },
        ],
      },
      activeItem: null, //active selected item
      files: [],
      mode: null,
      loading: false,
      search: "",
      searchResults: [],
    };
  },
  created() {
    //console.log(this.$route.params);
    this.refreshTable();
  },
  methods: {
    enableRole() {
      let moduleindex = this.$store.state.currentRole.enableModules.findIndex(
        (x) => x.name.toLowerCase() == "events"
      );
      let isActive =
        moduleindex > -1
          ? this.$store.state.currentRole.enableModules[moduleindex].isActive
          : false;
      if (
        this.$store.state.currentRole != null &&
        moduleindex > -1 &&
        isActive
      ) {
        return true;
      }
      return false;
    },
    formatDate(datetime) {
      return moment(datetime).format("YYYY-MM-DD");
    },
    rndStr(len) {
      let text = "";
      let chars =
        "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      let ondate = moment(new Date()).format("YYYYMMDD");
      return ondate + text;
    },
    
    
    updateQuiz(quiz) {
      this.quiz = quiz;
    },
    addAnswer() {
      this.quiz.answers = this.quiz.answers || [];
      this.quiz.answers.push({
        answer: "",
        isRight: false,
        score: 1,
      });
    },
    removeAnswer(index) {
      this.quiz.answers.splice(index, 1);
    },
    changeMode(mode) {
      this.mode = mode;
      if (mode == null) {
        this.refreshTable();
      }
    },
    gotoQuestionList() {
      this.$router.push({
        path: `/${this.$i18n.locale}/${this.$route.params.oid}/events/${
          this.$route.params.eventid
        }/questions/${this.$route.params.qid}/${this.rndStr(3)}`,
      });
    },
    gotoQuiz() {
      this.$router.push({
        path: `/${this.$i18n.locale}/${this.$route.params.oid}/events/${
          this.$route.params.eventid
        }/questions/${this.$route.params.qid}/${this.rndStr(3)}`,
      });
    },
    uploadImage(e) {
      let that = this; // Preserve context
      if (e.target.files) {
        const files = e.target.files; // Get files from the event
        for (let i = 0; i < files.length; i++) {
          let file = files[i];
          this.multiUpload(file); // Call the external function
        }
      }
    },

    multiUpload(file) {
      let filename = this.rndStr(4);
      var storageRef = fbAuth
        .storage()
        .ref(
          "organizations/" +
            this.$route.params.oid +
            "/events/quizes/" +
            filename
        );
      var uploadTask = storageRef.put(file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          // Handle unsuccessful uploads
          console.error(error);
        },
        () => {
          // Handle successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            let images = this.quiz.images || [];
            images.push(downloadURL); // Add the new URL to the images array
            this.quiz.images = images; // Update the quiz images
          });
        }
      );
    },
    deleteImage(img, index) {
      let image = fbAuth.storage().refFromURL(img);
      // console.log(image);
      this.quiz.images.splice(index, 1);
      image
        .delete()
        .then(() => {
          console.log("Image deleted");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleChange(v) {
      this.files = v;
    },
    reset() {
      this.quiz = {
        id: "",
        lang: "th",
        title: "",
        question: "",
        solution: "",
        images: [],
        answers: [
          {
            isRight: false,
            answer: "",
          },
        ],
      };
    },
    createNew() {
      this.mode = "new";
      this.reset();
      //this.$refs.edit.show();
    },
    addQuestion() {
       db.collection("organizations")
.doc(this.$route.params.oid)
.collection("events")
        .doc(this.$route.params.eventid)
        .collection("questions")
        .doc(this.$route.params.qid)
        .collection("quizes")
        .get()
        .then((snap) => {
          this.size = snap.size; // will return the collection size
          this.quiz.id = this.size.toString();
          //console.log(this.size);
           db.collection("organizations")
.doc(this.$route.params.oid)
.collection("events")
            .doc(this.$route.params.eventid)
            .collection("questions")
            .doc(this.$route.params.qid)
            .collection("quizes")
            .add(this.quiz);
        });
      this.$refs.edit.hide();
    },
    editQuiz(quiz) {
      this.mode = "edit";
      this.quiz = quiz;
      this.activeItem = JSON.stringify(quiz);
      //this.$refs.edit.show();
    },
    updateStatus(quiz) {
      quiz.timestamp = firebase.firestore.FieldValue.serverTimestamp();
       db.collection("organizations")
.doc(this.$route.params.oid)
.collection("events")
        .doc(this.$route.params.eventid)
        .collection("questions")
        .doc(this.$route.params.qid)
        .collection("quizes")
        .doc(quiz.id)
        .update(quiz)
        .then(() => {
          // this.$refs.edit.hide();
          toast.fire({
            title: "Update status successfully",
          });
        });
    },
    updateSeq(quiz, index, move) {
      if (index + move < this.questions.length && index + move > -1) {
        quiz.order_no = index + move;
        if (move > 0) {
          if (index + 1 < this.questions.length) {
            this.questions.find((p) => p.id == quiz.id).order_no = index + 1;
            this.questions[index + 1].order_no = index;
             db.collection("organizations")
.doc(this.$route.params.oid)
.collection("events")
              .doc(quiz.id)
              .update(quiz);
          }
        } else {
          if (index - 1 > -1) {
            this.questions[index - 1].order_no = index;
            this.questions.find((p) => p.id == quiz.id).order_no = index - 1;
             db.collection("organizations")
.doc(this.$route.params.oid)
.collection("events")
              .doc(quiz.id)
              .update(quiz);
          }
        }
        this.refreshTable();
      }
      console.log(this.questions);
    },
    paticipates(event) {
      this.$router.push(
        `/${this.$i18n.locale}/${this.$route.params.oid}/event/${event.id}/paticipants`
      );
    },
    refreshTable() {
       db.collection("organizations")
.doc(this.$route.params.oid)
.collection("events")
        .doc(this.$route.params.eventid)
        .collection("questions")
        .doc(this.$route.params.qid)
        .collection("quizes")
        .onSnapshot((snapShot) => {
          var f = [];
          snapShot.forEach((doc) => {
            var pro = doc.data();
            pro.id = doc.id;
            f.push(pro);
            //console.log(f);
          });
          this.questions = f.sort((a, b) => {
            const nameA = a.title.toUpperCase(); // ignore upper and lowercase
            const nameB = b.title.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          });
        });
    },

    deleteQuiz(quiz) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
           db.collection("organizations")
.doc(this.$route.params.oid)
.collection("events")
            .doc(this.$route.params.eventid)
            .collection("questions")
            .doc(this.$route.params.qid)
            .collection("quizes")
            .doc(quiz.id)
            .delete();

          for (var img in quiz.images) {
            fbAuth
              .storage()
              .refFromURL(quiz.images[img])
              .delete()
              .then(() => {
                console.log("image deleted");
              })
              .catch((err) => {
                console.log(err);
              });
            // console.log(doc.images[img])
          }

          toast.fire({
            title: "Deleted successfully",
          });
          this.refreshTable();
        }
      });
    },
    searchNow() {
      this.searchResults = this.events.filter((quiz) => {
        if (
          quiz.title.toLowerCase().indexOf(this.search.toLowerCase()) != "-1"
        ) {
          return quiz;
        }
      });
    },
  }, // -- end methods -- //
};
</script>
<style scoped>
.img-small-wrap {
  position: relative;
}
.img-small-wrap .delete-img {
  position: absolute;
  top: -14px;
  right: -2px;
  cursor: pointer;
}
</style>
