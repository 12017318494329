<template>
  <div>
    <div class="container pt-0 pb-0">
      <div class="row">
        <div class="col" cols="12">
          <div class="set-padding">
            <div class="text-center mt-10">
              <img
                class="mx-auto"
                src="@/assets/img/dashboard.svg"
                alt=""
                width="200px"
              />
            </div>
            <h2 class="text-center text-title mt-2">Select Role</h2>
            <div class="col-12 col-lg-4 col-md-6 mx-auto">
              <div>
                <b-form-select
                  v-model="role"
                  :options="roleOptions"
                  :disabled="invitedCode != ''"
                ></b-form-select>
              </div>
            </div>
            <h2 class="text-center text-title my-3">Allow Module</h2>
            <div class="text-center">
              <b-form-checkbox-group
                v-model="enableModules"
                :options="moduleOptions"
                class="mb-3"
                value-field="value"
                text-field="text"
                :disabled="invitedCode != ''"
                disabled-field="notEnabled"
              ></b-form-checkbox-group>
            </div>
          </div>
          <div class="col-12 col-lg-8 col-md-8 mx-auto">
            <div class="mt-3">
              URL Link for Role:
              <strong>{{ role }} , Module : {{ enableModules }}</strong>
            </div>
            <div class="input-group">
              <div class="form-control p-0 m-0 justify-content-between">
                <input
                  class="col-11 p-1 m-0"
                  id="testing-code"
                  style="text-transform: none !important"
                  :value="invitedCode"
                  disabled="true"
                />
                <i @click="copyURL(invitedCode)" class="fas fa-copy pl-1"> </i>
              </div>
            </div>
          </div>
          <div class="row mt-3 p-2">
            <div class="col-12 mx-auto text-center">
              <b-button
                v-if="invitedCode == ''"
                pill
                class="text-center primary col-5 mx-2"
                @click="createInvite()"
              >
                Create Invitation
              </b-button>
              <b-button
                v-if="invitedCode == ''"
                pill
                class="text-center secondary col-5 mx-2"
                variant="primary"
                @click="close"
              >
                Close
              </b-button>
              <b-button
                v-if="invitedCode != ''"
                pill
                class="text-center secondary col-4 mx-2"
                variant="primary"
                @click="copyURL(invitedCode)"
              >
                Copy Code
              </b-button>
              <b-button
                v-if="invitedCode != ''"
                pill
                class="text-center secondary col-4 mx-2"
                variant="primary"
                @click="invitedCode = ''"
              >
                Reset
              </b-button>
              <div class="share" v-if="isSupported">
                <b-button class="button" @click="webShare">Share</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import { fbAuth, db } from "@/configs/firebase";
import axios from "axios";

export default {
  data() {
    return {
      role: null,
      enableModules: [],
      session: false,
      name: this.$store.state.currentuser.name,
      currentuser: this.$store.state.currentuser,
      eventid: null,
      invitedCode: "",
      members: [],
      currentRole: null,
      moduleOptions: [
        { text: "Product Category", value: "Product Category" },
        { text: "Products", value: "Products" },
        { text: "Events", value: "Events" },
        { text: "Exhibitors", value: "Exhibitors" },
        { text: "News", value: "News" },
        { text: "Entries", value: "Entries" },
        { text: "Forms", value: "Forms" },
        { text: "Sale Orders", value: "Sale Orders" },
      ],
      roleOptions: [
        { text: "Admin", value: "Admin" },
        { text: "Operator", value: "Operator" },
        { text: "Sale", value: "Sale" },
      ],
      isSupported: false,
      url: "https://www.360learntocode.com/2022/01/how-to-implement-social-media-share.html",
      title: "Web API Share",
      description: "Web API Sharing",
    };
  },
  mounted() {
    this.eventid = this.$route.params.id;
    this.checkFirebaseLoginState();
    this.getRole();
    // console.log(this.$route);
  },
  methods: {
    getRole() {
      let org = this.$store.state.organization;
      db.collection("organizations")
        .doc(this.$route.params.oid)
        .collection("roles")
        .onSnapshot((snapShot) => {
          this.members = [];
          snapShot.forEach((doc) => {
            var mem = doc.data();
            mem.id = doc.id;
            mem.enableModules = mem.enableModules || [];

            if (
              mem.enableModules != null &&
              org != null &&
              org.enableModules != null &&
              mem.enableModules.length != org.enableModules.length
            ) {
              mem.enableModules = org.enableModules;
              console.log(mem.enableModules);
            }
            if (
              this.$store.state.currentuser.id == mem.id ||
              this.$store.state.currentuser.email == mem.email
            ) {
              this.currentRole = mem;
              if (this.currentRole.role.toLowerCase() != "admin") {
                this.$router.push(
                  `/${this.$i18n.locale}/${this.$route.params.oid}/admin/overview`
                );
              }
              this.$store.commit("saveCurrentRole", this.currentRole);
            }

            this.members.push(mem);
          });
        });
    },
    checkFirebaseLoginState() {
      fbAuth.auth().onAuthStateChanged((user) => {
        if (user) {
          // this.customer = user;
          this.session = true;
        } else {
          this.session = false;

          Swal.fire({
            title: "Plese login",
          }).then(() => {
            this.openModalLoginOnly();
            this.$router.push(
              `/${this.$i18n.locale}/${this.$route.params.oid}/home`
            );
          });
        }
      });
    },
    openModalLoginOnly() {
      console.log("modal login-only");
      this.$root.$emit("bv::show::modal", "modal-login", "#btnShow");
      this.$store.commit("saveStateBfLogin", {
        oid: this.$route.params.oid ?? null,
        id: this.$route.params.eventid ?? null,
        path: this.$route.fullPath ?? null,
      });
    },
    rndStr(len) {
      let text = "";
      let chars =
        "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return text;
    },
    isSupport() {
      if (navigator.share) {
        this.isSupported = true;
      }
    },
    webShare() {
      let self = this;
      navigator.share({
        title: self.title,
        text: self.description,
        url: self.url,
      });
    },
    async copyURL(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        alert("Copied");
      } catch ($e) {
        alert("Cannot copy");
      }
    },
    createOrganizationAdmin() {
      this.statequery = this.$route.query;
      if (this.statequery.session)
        if (this.statequery) {
          if (
            this.statequery.code.startsWith("org_admin") &&
            this.statequery.code != null
          ) {
            // login call back from line call function
            let axiosConfig = {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
              },
            };
            axios
              .post(
                "https://asia-northeast1-jinnsolution.cloudfunctions.net/createLineUser",
                {
                  code: this.statequery.code,
                },
                axiosConfig
              )
              .then((response) => {
                //console.log(response);
                const auth = fbAuth.auth();
                auth
                  .signInWithCustomToken(response.data.firebase_token)
                  .catch(function (error) {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    if (errorCode === "auth/invalid-custom-token") {
                      alert("The token you provided is not valid.");
                    } else {
                      console.error(error);
                    }
                  });
              })
              .catch((err) => console.log(err));
          }
        }
    },
    displayPicture() {
      if (
        this.currentuser != null &&
        this.currentuser.displayUrl != null &&
        this.currentuser.displayUrl != ""
      ) {
        return this.currentuser.displayUrl;
      } else {
        return "https://jinn.page/img/avatar.png";
      }
    },
    edit() {
      this.$router.push({
        path: `/${this.$i18n.locale}/${this.$route.params.oid}/event/${this.$route.params.id}/register/return`,
        query: { edit: true },
      });
    },
    createInvite() {
      if (this.role != null && this.enableModules.length > 0) {
        db.collection("organizations")
          .doc(this.$route.params.oid)
          .collection("invitations")
          .add({
            role: this.role,
            enableModules: this.enableModules,
            usage: 0,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then((docRef) => {
            //        //console.log(docRef);
            this.invitedCode =
              `https://jinn.page/${this.$i18n.locale}/${this.$route.params.oid}/addteam/` +
              docRef.id;
          });
      } else {
        Swal.fire({
          title: "Information not completed!",
        });
      }
    },
    close() {
      this.$router.push({
        path: `/${this.$i18n.locale}/${this.$route.params.oid}/home`,
      });
      //this.$liff.closeWindow();
    },
  },
};
</script>
<style scoped>
.buttonz {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
</style>
