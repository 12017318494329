<template>
  <div id="app">
    <NavView class="mb-1 mt-0" />
    <router-view></router-view>
    <login-only></login-only>
  </div>
</template>

<script>
import NavView from "@/components/shared/Nav.vue";
//import liff from "./configs/liff";
import { db, fbAuth } from "./configs/firebase";
import axios from "axios";
export default {
  name: "App",
  components: {
    NavView,
  },
  created() {
    console.log("App created");
  },
  methods: {},
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
