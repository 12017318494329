import Swal from "sweetalert2";
const toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
// views folder
// HomePage
import HomePage from "../components/shared/HomePage";
// main-homepage
import MainHome from "../components/shared/MainHome";
// admin folder
// registeration
import OrganizationCreate from "../components/admin/OrganizationCreate";
// Admin
//import Admin from '../components/admin/Admin';
// Overview
import Overview from "../components/admin/Overview";
// AdminMobile
import AdminAccessDevices from "../components/admin/AccessDevices";
// AdminMobile
import AdminMobile from "../components/admin/AdminMobile";
// Promotions
import Promotions from "../components/admin/Promotions";
// Products
import AdminCategories from "../components/admin/Categories";
// Products
import AdminProducts from "../components/admin/Products";
// Orders
import AdminOrders from "../components/admin/Orders";
// Admin Orders
import LineOrderList from "../components/admin/LineOrderList";
// Users
import AdminUsers from "../components/admin/Users";
// admin-login
import AdminLogin from "../components/admin/AdminLogin";
// admin-banners
import AdminBanners from "../components/admin/Banners";
// admin-events
import AdminEvents from "../components/admin/Events";
// admin-entries
import AdminEntries from "../components/admin/Entries";
// admin-event-questions
import AdminEventQuestion from "../components/admin/EventQuestion";
// admin-form-questions
import AdminFormQuestion from "../components/admin/FormQuestion";
// admin-exhibitors
import AdminExhibitors from "../components/admin/Exhibitors";
// admin-news
import AdminNews from "../components/admin/News";
// admin-shopclasses
import AdminShopClasses from "../components/admin/ShopClasses";
// invitation to team
import CreateTeamInvitation from "../components/admin/CreateTeamInvitation";
// organization-add-role
import RegisterTeamDone from "../components/admin/RegisterTeamDone";
// organization process
// admin-form-entry
import AdminForms from "../components/admin/Forms";

// entry-view-home
// member-entry
import EntryMemberRegister from "../components/app-views/entry-process/EntryMemberRegister";

// notFound
import NotFound from "../components/shared/NotFound";
import { h } from "vue";
import i18n from "../i18n/i18n";
import { fbAuth, db } from "../configs/firebase";
const routes = [
  {
    path: "/",
    redirect: () => `/${i18n.global.locale.value || "th"}/home`,
  },
  {
    path: "/:lang",
    component: {
      render() {
        return h("router-view");
      },
    },
    children: [
      {
        path: "home",
        name: "home",
        component: MainHome,
      },
      {
        path: ":oid/admin",
        name: "admin",
        //component: Admin,
        component: AdminMobile,
        redirect: ":oid/admin/overview",
        meta: {
          hideNavigation: true,
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          // setting rules to guard the route
          //console.log(from, to);
          const oid = to.params.oid;
          //const eventid = to.params.id;
          //console.log('eventid', eventid);
          const requiresAuth = to.matched.some(
            (record) => record.meta.requiresAuth
          );
          const currentUser = fbAuth.auth().currentUser;
          var isAdmin = false;
          if (currentUser === null || currentUser === undefined) {
            // eslint-disable-next-line callback-return
            next({
              path: `/${i18n.locale}/${oid}/home`,
              query: { redirect: to.fullPath },
            });
          } else {
            //console.log(currentUser);
            isAdmin = currentUser
              .getIdTokenResult()
              .then((idTokenResult) => {
                currentUser.admin = idTokenResult.claims.admin;
                //console.log(currentUser.admin)
              })
              .catch((err) => {
                console.log(err);
              });
          }

          var orgRole = "user";
          if (oid != undefined && currentUser != undefined) {
            db.collection("organizations")
              .doc(oid)
              .collection("roles")
              .where("email", "==", currentUser.email)
              .get()
              .then((snapshot) => {
                if (snapshot.empty) {
                  // eslint-disable-next-line callback-return
                  if (requiresAuth && currentUser.isAdmin != undefined) {
                    // eslint-disable-next-line callback-return
                    next();
                  } else {
                    console.log("No matching documents.", currentUser.email);
                    Swal.fire({
                      title: "You have no right to access admin",
                    });
                    // eslint-disable-next-line callback-return
                    next({
                      path: `/${i18n.locale}/${oid}/home`,
                      query: { redirect: to.fullPath },
                    });
                  }
                } else {
                  snapshot.forEach((doc) => {
                    //console.log(doc.id, '=>', doc.data());
                    const member = doc.data();
                    if (member.role == "admin") {
                      orgRole = "admin";
                    }
                  });
                }
              });

            // conditions
            if (requiresAuth && !currentUser) {
              // eslint-disable-next-line callback-return
              next({
                path: `/${i18n.locale}/${oid}/home`,
                query: { redirect: to.fullPath },
              });
            } else if ((requiresAuth && isAdmin) || orgRole == "admin") {
              // eslint-disable-next-line callback-return
              next();
            } else if (oid != undefined) {
              // eslint-disable-next-line callback-return
              next({
                path: `/${i18n.locale}/${oid}/home`,
              });
            }
          } else {
            // eslint-disable-next-line callback-return
            next({
              path: `/${i18n.locale}/${oid}/home`,
              query: { redirect: to.fullPath },
            });
            Swal.fire({
              title: this.$t("adminlogin.wait-login"),
            });
          }
        },
        // Admin Children [ The guard takes care of them automatically! ]
        children: [
          {
            path: "invite-member",
            name: "invite-member-organization",
            component: CreateTeamInvitation,
          },
          {
            path: "overview",
            name: "admin-overview",
            //redirect: `admin-m-overview`,
            component: Overview,
            meta: { hideNavigation: false, requiresAuth: true },
          },
          {
            path: "admin-m-overview",
            name: "admin-m-overview",
            component: AdminMobile,
            meta: { hideNavigation: true },
          },
          {
            path: "accessdevices",
            name: "admin-access-devices",
            component: AdminAccessDevices,
            meta: { hideNavigation: false },
          },
          {
            path: "banners",
            name: "admin-banners",
            component: AdminBanners,
            meta: { hideNavigation: false },
          },
          {
            path: "events",
            name: "admin-events",
            component: AdminEvents,
            meta: { hideNavigation: false },
          },
          {
            path: "entries",
            name: "admin-entries",
            component: AdminEntries,
            meta: { hideNavigation: false },
          },
          {
            path: "forms",
            name: "admin-forms",
            component: AdminForms,
            meta: { hideNavigation: false },
          },
          {
            path: "forms/:formid/questions/:qid",
            name: "admin-form-question",
            component: AdminFormQuestion,
            meta: { hideNavigation: false },
          },
          {
            path: "events/:eventid/questions/:qid",
            name: "admin-event-question",
            component: AdminEventQuestion,
            meta: { hideNavigation: false },
          },
          {
            path: "exhibitors",
            name: "admin-exhibitors",
            component: AdminExhibitors,
            meta: { hideNavigation: false },
          },
          {
            path: "news",
            name: "admin-news",
            component: AdminNews,
            meta: { hideNavigation: false },
          },
          {
            path: "shopclasses",
            name: "admin-shopclasses",
            component: AdminShopClasses,
            meta: { hideNavigation: false },
          },
          {
            path: "promotions",
            name: "admin-promotions",
            component: Promotions,
            meta: { hideNavigation: false },
          },
          {
            path: "categories",
            name: "admin-categories",
            component: AdminCategories,
            meta: { hideNavigation: false },
          },
          {
            path: "products",
            name: "admin-products",
            component: AdminProducts,
            meta: { hideNavigation: false },
          },
          {
            path: "orders",
            name: "admin-orders",
            component: AdminOrders,
            meta: { hideNavigation: false },
          },
          {
            path: "lineorders",
            name: "admin-lineorders",
            component: LineOrderList,
            meta: { hideNavigation: false },
          },
          {
            path: "admin-users",
            name: "admin-users",
            component: AdminUsers,
            meta: { hideNavigation: true },
          },
        ],
        // ./admin-page
      },
      {
        path: ":oid/admin",
        name: "admin",
        //component: Admin,
        component: AdminMobile,
        redirect: ":oid/admin/overview",
        meta: {
          hideNavigation: true,
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          // setting rules to guard the route
          //console.log(from, to);
          const oid = to.params.oid;
          //const eventid = to.params.id;
          //console.log('eventid', eventid);
          const requiresAuth = to.matched.some(
            (record) => record.meta.requiresAuth
          );
          const currentUser = fbAuth.auth().currentUser;
          var isAdmin = false;
          if (currentUser === null || currentUser === undefined) {
            // eslint-disable-next-line callback-return
            next({
              path: `/${i18n.locale}/${oid}/home`,
              query: { redirect: to.fullPath },
            });
          } else {
            //console.log(currentUser);
            isAdmin = currentUser
              .getIdTokenResult()
              .then((idTokenResult) => {
                currentUser.admin = idTokenResult.claims.admin;
                //console.log(currentUser.admin)
              })
              .catch((err) => {
                console.log(err);
              });
          }

          var orgRole = "user";
          if (oid != undefined && currentUser != undefined) {
            db.collection("organizations")
              .doc(oid)
              .collection("roles")
              .where("email", "==", currentUser.email)
              .get()
              .then((snapshot) => {
                if (snapshot.empty) {
                  // eslint-disable-next-line callback-return
                  if (requiresAuth && currentUser.isAdmin != undefined) {
                    // eslint-disable-next-line callback-return
                    next();
                  } else {
                    console.log("No matching documents.", currentUser.email);
                    Swal.fire({
                      title: "You have no right to access admin",
                    });
                    // eslint-disable-next-line callback-return
                    next({
                      path: `/${i18n.locale}/${oid}/home`,
                      query: { redirect: to.fullPath },
                    });
                  }
                } else {
                  snapshot.forEach((doc) => {
                    //console.log(doc.id, '=>', doc.data());
                    const member = doc.data();
                    if (member.role == "admin") {
                      orgRole = "admin";
                    }
                  });
                }
              });

            // conditions
            if (requiresAuth && !currentUser) {
              // eslint-disable-next-line callback-return
              next({
                path: `/${i18n.locale}/${oid}/home`,
                query: { redirect: to.fullPath },
              });
            } else if ((requiresAuth && isAdmin) || orgRole == "admin") {
              // eslint-disable-next-line callback-return
              next();
            } else if (oid != undefined) {
              // eslint-disable-next-line callback-return
              next({
                path: `/${i18n.locale}/${oid}/home`,
              });
            }
          } else {
            // eslint-disable-next-line callback-return
            next({
              path: `/${i18n.locale}/${oid}/home`,
              query: { redirect: to.fullPath },
            });
            Swal.fire({
              title: this.$t("adminlogin.wait-login"),
            });
          }
        },
        // Admin Children [ The guard takes care of them automatically! ]
        children: [
          {
            path: "invite-member",
            name: "invite-member-organization",
            component: CreateTeamInvitation,
          },
          {
            path: "overview",
            name: "admin-overview",
            //redirect: `admin-m-overview`,
            component: Overview,
            meta: { hideNavigation: false, requiresAuth: true },
          },
          {
            path: "admin-m-overview",
            name: "admin-m-overview",
            component: AdminMobile,
            meta: { hideNavigation: true },
          },
          {
            path: "accessdevices",
            name: "admin-access-devices",
            component: AdminAccessDevices,
            meta: { hideNavigation: false },
          },
          {
            path: "banners",
            name: "admin-banners",
            component: AdminBanners,
            meta: { hideNavigation: false },
          },
          {
            path: "events",
            name: "admin-events",
            component: AdminEvents,
            meta: { hideNavigation: false },
          },
          {
            path: "entries",
            name: "admin-entries",
            component: AdminEntries,
            meta: { hideNavigation: false },
          },
          {
            path: "promotions",
            name: "admin-promotions",
            component: Promotions,
            meta: { hideNavigation: false },
          },
          {
            path: "categories",
            name: "admin-categories",
            component: AdminCategories,
            meta: { hideNavigation: false },
          },
          {
            path: "products",
            name: "admin-products",
            component: AdminProducts,
            meta: { hideNavigation: false },
          },
          {
            path: "orders",
            name: "admin-orders",
            component: AdminOrders,
            meta: { hideNavigation: false },
          },
          {
            path: "lineorders",
            name: "admin-lineorders",
            component: LineOrderList,
            meta: { hideNavigation: false },
          },
          {
            path: "admin-users",
            name: "admin-users",
            component: AdminUsers,
            meta: { hideNavigation: true },
          },
        ],
      },
      {
        path: "*",
        name: "not-found",
        component: NotFound,
        meta: {
          hideNavigation: true,
        },
      },
    ],
  },
];

export default routes;
