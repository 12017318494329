<template>
  <div class="overview mx-auto m-0 p-0">
    <nav
      class="navbar navbar-inner navbar-light d-flex flex-row bg-light p-0 m-0 justify-content-start"
      style="margin: 0px"
    >
      <div v-for="(menu, indx) in enableModules" :key="indx" class="mx-2">
        <div v-if="menu.value != 'orders'" class="m-2">
          <a
            :href="`/${$i18n.locale}/${$route.params.oid}/admin/${menu.value}`"
            >{{ menu.text }}</a
          >
        </div>
      </div>
    </nav>
    <main class="p-0 m-0">
      <!-- toggler -->
      <router-view></router-view>
    </main>
  </div>
</template>
<script>
import { db, fbAuth } from "@/configs/firebase";

export default {
  components: {},
  data() {
    return {
      mobile: window.innerWidth <= 700,
      members: [],
      selected: "overview",
      options: [
        {
          text: "Overview",
          value: "admin-overview",
        },
        {
          text: "Products",
          value: "products",
        },
        {
          text: "Events",
          value: "events",
        },
        {
          text: "Exhibitors",
          value: "exhibitors",
        },
        {
          text: "Line Shop",
          value: "lineshop",
        },
        {
          text: "Orders",
          value: "orders",
        },
      ],
      enableModules: [],
    };
  },
  mounted() {
    if (this.$store.state.organization) {
      this.enableModules.push({
        text: "Overview",
        value: "overview",
      });
      this.enableModules.push({ text: "Banners", value: "banners" });
      let modules = this.$store.state.organization.enableModules || [];
      modules.forEach((doc) => {
        this.enableModules.push({ text: doc.name, value: doc.name });
      });
    }
    window.onresize = () => {
      this.mobile = window.innerWidth <= 700;
    };
    if (this.$store.state.organization != null) {
      this.getRole();
    } else {
      this.getOrganization();
    }
  },
  methods: {
    getOrganization() {
      let oid = this.$route.params.oid;
      if (oid != null && oid != "") {
        db.collection("organizations")
          .doc(oid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              let org = doc.data();
              org.id = doc.id;
              this.$store.commit("saveOrganization", org);
              this.getRole();
              if (doc.data().title != null) document.title = doc.data().title;
              else document.title = doc.data().company;
              console.log("Document organization data:", doc.data());
            } else {
              this.$store.commit("saveOrganization", null);
              this.$router.push({
                path: `/${this.$i18n.locale}/${this.$route.params.oid}/home`,
              });
              Swal.fire({
                title: "please contact JINN SOLUTION Co.,Ltd",
              });
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      } else {
        document.title = this.$store.state.title.name;
      }
    },
    getRole() {
      let org = this.$store.state.organization;
       db.collection("organizations")
.doc(this.$route.params.oid)
.collection("roles").onSnapshot((snapShot) => {
        this.members = [];
        snapShot.forEach((doc) => {
          var mem = doc.data();
          mem.id = doc.id;
          mem.enableModules = mem.enableModules || [];

          if (
            mem.enableModules != null &&
            org != null &&
            org.enableModules != null &&
            mem.enableModules.length != org.enableModules.length
          ) {
            mem.enableModules = org.enableModules;
            //console.log(mem.enableModules);
          }
          if (
            this.$store.state.currentuser.id == mem.id ||
            this.$store.state.currentuser.email == mem.email
          ) {
            this.currentRole = mem;
            this.$store.commit("saveCurrentRole", this.currentRole);
          }

          this.members.push(mem);
        });
      });
    },
  },
};
</script>
<style scoped>
* {
  text-transform: capitalize;
}

.scrollmenu {
  background-color: #333;
  overflow: auto;
  white-space: nowrap;
}

.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

.scrollmenu a:hover {
  background-color: #777;
}
</style>
